// Generic eZ styles
div.content-view-full {

    div.attribute-title h1 {
        margin-bottom: 2.5rem;
    }

    div.attribute-short {
        *:first-child {
            margin-top: 0;
        }

        * {
            font-size: $font-size-text;
            line-height: 1.2;
        }

        p + p {
            margin-top: 10px;
        }

        p + ul {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        p:last-child {
            margin-bottom: 20px;
        }
    }

    div.attribute-long {
        *:first-child {
            margin-top: 0;
        }

        * {
            font-size: $font-size-body-text;
            line-height: 1.4;
        }

        p + p {
            margin-top: 10px;
        }

        p + ul {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        p:last-child {
            margin-bottom: 20px;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-top: 2rem;
            margin-bottom: 0.5rem;
        }
    }

    div.attribute-date-published {
        font-size: 1.4rem;
        font-weight: 300;
    }

    div.content-view-children {

        [class*="content-view-"] + [class*="content-view-"] {
            margin-top: 2rem;
        }

        .content-view-line {
            div.attribute-title {
                h2 {
                    font-size: 2.2rem !important;
                    margin-top: 0;
                    margin-bottom: 0.5rem;

                    a {
                        color: $color-black;
                    }
                }
            }

            div.attribute-date-published {
                font-size: 1.6rem;
            }

            div.attribute-details {
                margin-bottom: 5px;

                span {
                    font-size: 1.6rem;
                    font-weight: 300;

                    &.attribute-class {
                        color: #1F3B72;
                    }
                }
            }

            div.attribute-short {
                * {
                    font-size: 1.6rem;
                    line-height: 1.4;
                }

                p:last-child {
                    margin-bottom: 0;
                }
            }
        }

        // Overrides bootstrap panel styles
        .panel.content-view-panel-line {
            background: initial;
            box-shadow: none;
            border: none;
            border-radius: 0;
            position: relative;

            & > div.arrow-after {

                // padding-right: 30px;
                & .content-view-line [class^="col-"]:last-child {
                    padding-right: 45px;
                }

                & > a::after {
                    content: "\f107";
                    font-family: "Font Awesome 5 Pro";
                    font-size: 16px;
                    font-weight: 300;
                    -webkit-transition: all ease .15s;
                    transition: all ease .15s;
                    right: 15px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    position: absolute;
                }

                & > a:not(.collapsed) {
                    &::after {
                        transform: translateY(-60%) rotate(180deg);
                    }
                }
            }

            div.panel-collapse .content-view-full {
                background: #fff;
            }
        }
    }
}

