.text-ellipsis {
    position: relative;

    /* hide text if it more than N lines  */
    overflow: hidden;

    /* use this value to count block height */
    line-height: 1.4em;

    /* max-height = line-height (1.4) * lines max number (5) */
    max-height: 7em;

    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;

    margin-right: -1em;
    padding-right: 1em;

    &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background-color: $color-background;
    }
}
