ul.navbar-nav {
	li {
		a {
			color: $color-link-light;
			font-size: 18px;
			font-weight: 300;

			margin-bottom: 5px;
			border-bottom: 2px solid transparent;

			&:hover, &:focus {
				background-color: transparent;
				color: $color-hover;
			}
		}
	}

	li.open {
		& > a {
			background-color: transparent;
			color: $color-hover;
		}
	}

	li.active {
		& > a,
		&:hover > a {
			color: #fff;
			font-weight: 500;
			border-color: #fff;
		}
	}
}

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 10px;
}

ul.dropdown-menu {
	margin-top: 8px;
	&.fix-margins-paddings {
		margin-top: 0px;
		padding-bottom: 0px;
		padding-top: 0px;
		li a:hover {
			margin-right: 0px;
			margin-left: 0px;
			padding-left: 21px;
		}
		li a {
			font-size: 20px;
		}
	}
	li a.disabled {
		color: gray;
		pointer-events: none;
	}
}
li .dropdownArrow {
	display: none;
}
li.dropdown .dropdownArrow {
	display: none;
	width: 100%;
	height: 16px;
	position: absolute;
	background-image: url('../images/submenuarrow.png');
	background-position: top center;
	background-repeat: no-repeat;
	z-index: 99999;
}
li.dropdown:hover .dropdownArrow {
	display: block;
}
.navbar-nav > li > .dropdown-menu {
	padding-top: 7px !important;
	border: none !important;
	background: none !important;
	box-shadow: none !important;
	border-radius: none !important;
}

ul.dropdown-menu li {
	background-image: none !important;
	border: 1px solid #cfcfcf;
	border-style: none solid;
	background-color: #ededed;
	text-align: left;
}
ul.dropdown-menu li:first-child,
ul.dropdown-menu li  {
	padding: 0px!important;
}
ul.dropdown-menu li a {
	color: $color-text;
	font-size: 24px;
	margin: 0px 1px;
	border-bottom: 1px solid #cccccc;
	font-weight: 400;
}
ul.dropdown-menu li a:hover,
ul.dropdown-menu li a:focus {
	background-color: #cfcfcf;
	color: $color-text;
	margin: 0px;
	padding: 3px 21px;
}
ul.dropdown-menu li:first-child{
	border-style: solid solid none solid;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-moz-border-radius-topright: 4px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
ul.dropdown-menu li:last-child{
	border-style: none solid solid solid;
	-webkit-border-bottom-right-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-radius-bottomright: 4px;
	-moz-border-radius-bottomleft: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
ul.dropdown-menu li:only-child {
	border-style: solid solid solid solid;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
ul.dropdown-menu li:last-child a,
ul.dropdown-menu li:only-child a {
	border-bottom: 0px solid!important;
}
li.dropdown a.imageLink {
	padding-top: 12px!important;
	padding-bottom: 13px!important;
	margin-top: 0px!important;
	margin-bottom: 0px!important;
}