.campaign-box {
	padding: 10px;
	color: #ffffff;

	h2 {
		font-size: 32px;
		padding: 30px 10px 10px 10px;
	}
	
	p {
		font-size: 20px;
		padding: 0px 10px;
	}

	a {
		color: #ffffff;
	}

	&--black {
		background-color: #000000;
	}

	&--spotify {
		html, body {
			margin: 0px;
			padding: 0px;
		}
	}

	&--wimp {
		background-color: #fff;

		img {
			margin-bottom: 20px;
		}

		h4 {
			font-size: 28px;
			font-family: $f-title;
			font-weight: $f-title-normal;
		}
	}

	&--transport {
		background-color: #286da8;
		padding: 10px 25px;

		.form__block {
			margin-bottom: 10px;

			&--button {
				display: inline-block;

				.search-button {
					margin: 0;
					background-color: #e67036;
					font-size: 16px;
					line-height: 26px;
					padding: 4px 20px 4px 15px;

					i {
						vertical-align: -3px;
					}
				}
			}
		}

		.chosen-single {
			div {
				display: none !important;
			}
		}

		.chosen-container-single .chosen-single {
			background: url('../images/selectarrow.png') no-repeat 98% center #286da8;
			box-shadow: none;
			border: 3px solid #fff;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			height: 36px;
		    line-height: 30px;

			span {
				color: white;
				font-size: 18px;
				font-family: $f-title;
				font-weight: $f-title-normal;
			}
		}

		h2 {
			margin-top: 0px;
			padding: 10px 0;
			font-family: $f-running-text;
		}

		select {
			width: 100%;
		}
	}

	&--grey {
		background-color: #ececec;
		border: 8px solid #c5c5c5;
		color: black;

		a {
			color: #0089bc;

			h2 & {
				color: #000000;
			}
		}

		p {
			font-size: 16px;
			color: #000000;
		}
	}

	&--newsticker {
		padding: 0;

		&-title {
			background-color: #286da8;
			text-align: center;
			padding: 10px;
			margin-bottom: 3px;

			h4 {
				margin: 0;
			}
		}

		&-content {
			height: 320px;
			padding: 10px;
			background-color: #2b85ba;
			overflow: hidden;
		}
	}

	.newsTickerList {
		font-size: 18px;
	}
}
