.livestream {
	&-title {
		margin-bottom: 3px;
		a {
			display: block;
			text-align: center;
			background-color: #286da8;
			color: #fff;
			padding: 10px;

			i {
				vertical-align: -7px;
			}
		}
	}

	&-img {
		position: relative;
		margin-bottom: 1rem !important;
		padding-bottom: 56.25%;
		padding-bottom: 0;

		a {
			display: block;

			&:after {
				position: absolute;
				top: 0; bottom: 0; left: 0; right: 0;
				background-image: url('../images/play.png');
				background-position: center center;
				background-repeat: no-repeat;
				z-index: 100;
				content: "";
				background-color: rgba(0,0,0,0.5);
			}

			&:hover {
				opacity: 0.8;
				cursor: pointer;
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}	
}