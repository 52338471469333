.calendarLine {
	.calendarTitle {
		h4 {
			font-size: 35px;
			a {
				color: black;
				@media ( max-width: 767px) {
					word-break: break-word;
				}
			}
		}

		.calendarDateWrapper {
			margin-right: 15px;

			@media ( max-width: 767px) {
				width: auto;
				padding: 14px 20px; 
			}

			.calendarDate, .calendarMonth {
				text-transform: uppercase;
				@media ( max-width: 767px) {
					font-size: 18px;
					line-height: 20px;
				}
			}
		}
	}	
	.calendarText {
		@media ( max-width: 767px) {
			margin-left: 0;
		}
	}
}