.breadcrumb {
	li {
		a {
			color: #286da8;

			&:hover {
				color: #286da8;
			}
		}
	}
}