
form#editform.employee
{
    div.error-message, div.error
    {
        color: #de0000;
        a
        {
            color: #de0000;
        }

        input,
        textarea,
        select
        {
            border: 1px solid #de0000;
        }
    }

    div.datatype-image
    {
        table.edit-employee-image
        {
            td
            {
                padding: 20px;

                &.image
                {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}