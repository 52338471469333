// 1. Fonts
// Font variables are prefixed with `f-` followed by e.g. the name of the font.
// This is done to make it simpler for new developers to remember the font variable name.

$f-title: 'Oswald', sans-serif;
$f-title-bold: 500;
$f-title-normal: 400;
$f-running-text: 'Alegreya Sans', sans-serif;

// 2. Colors
// Color variables are prefixed with `c-` followed by e.g. the name of the color.
// You may also name the color by its usage e.g. `$c-table-separator`.
// A good practice is to define some base colors, and then define the use case
// specific variables by those, e.g. `$c-table-separator: $c-whitesmoke`.
// The base colors defined is named according to color names supported by modern browsers.
// @see https://www.w3schools.com/colors/colors_names.asp
//
// To get proper base names for colors you can use the tool Name that Color,
// which will tell you the proper name of your color.
// @see http://chir.ag/projects/name-that-color
$c-primary: #286da8;

// Copied from redesign  ---
$color-blue-dark: #1f3b72;
$font-family-font-awesome: 'Font Awesome 5 Pro';
// End Copied from redesign  ---