form {
    label {
        font-weight: $font-weight-title-bold;
        font-size: $font-size-body-text;
        color: $color-text;
    }

    input[type="submit"] {
        border: 0;
        background-color: $color-button-bg;
        color: $color-button-fg;
        font-size: 1.4rem;
        padding: 6px 12px;
        &:hover {
            background-color: #4684B8;
            cursor: pointer;
        }

        &:disabled {
            background-color: transparentize($color: $color-button-bg, $amount: 0.9);
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    .row, .buttonblock, .g-recaptcha {
        margin-bottom: 18px;
    }

}

form {
    .feedback-collector-text,
    .feedback-collector-text p
     {
        margin-top: 1rem;
        font-size: $font-size-h6;
    }
}
