body, html {
	margin: 0px;
	padding: 0px;
	font-family: $f-running-text;
	font-weight: normal;
}

.body {
	&--coursemodule {
		margin-top: 0px!important;
	}

	#toolbar ~ #outer-wrap .jumbotron-header {
		top: 42px;
	}
}

h1, h2, h3, h4, h5, h1 a, h2 a, h3 a, h4 a, h5 a {
	font-family: $f-title;
	font-weight: $f-title-normal;
}

ul li {
	line-height: 1.4em;
}

a {
	color: #0089bc;
}

a.btn-primary {
	color: #ffffff;
}

table {
	width: 100%;

	th {
		font-family: 'Open Sans';
		font-size: 23px;
		color: #000;
		text-align: left;
	}

	tr {
		td {
			font-size: 20px;
			color: #000000;
			font-family: 'Open Sans';
			vertical-align: top;

			&:first-child {
				padding-left: 20px;
			}
		}

		&::nth-child(even) {
			background: #e2ebf8;
		}
	}
}

.jumbotron {
	h1 {
		font-family: $f-title;
		font-weight: 400;
	}
	h2 {
		font-family: $f-title;
		font-weight: $f-title-bold;
	}

	h1 {
		font-size: 65px;
		padding-bottom: 20px;
		line-height: 64px;
		margin-top: -10px;
	}

	h2 {
		font-size: 25px;
	}
}

li.page-item.disabled {
	pointer-events: none;
}

@for $i from 1 through 12 {
	.col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i} {
		margin-bottom: 10px;
	}
}

// Poor man's spacer, based on Bootstrap 4 Spacer
$spacer: 20px;
.m-0 {margin: 0 !important;}
.m-1 {margin: ($spacer * .25) !important;}
.m-2 {margin: ($spacer * .5) !important;}
.m-3 {margin: $spacer !important;}
.m-4 {margin: ($spacer * 1.5) !important;}
.m-5 {margin: ($spacer * 3) !important;}

.mt-0 {margin-top: 0 !important;}
.mt-1 {margin-top: ($spacer * .25) !important;}
.mt-2 {margin-top: ($spacer * .5) !important;}
.mt-3 {margin-top: $spacer !important;}
.mt-4 {margin-top: ($spacer * 1.5) !important;}
.mt-5 {margin-top: ($spacer * 3) !important;}

.mr-0 {margin-right: 0 !important;}
.mr-1 {margin-right: ($spacer * .25) !important;}
.mr-2 {margin-right: ($spacer * .5) !important;}
.mr-3 {margin-right: $spacer !important;}
.mr-4 {margin-right: ($spacer * 1.5) !important;}
.mr-5 {margin-right: ($spacer * 3) !important;}

.mb-0 {margin-bottom: 0 !important;}
.mb-1 {margin-bottom: ($spacer * .25) !important;}
.mb-2 {margin-bottom: ($spacer * .5) !important;}
.mb-3 {margin-bottom: $spacer !important;}
.mb-4 {margin-bottom: ($spacer * 1.5) !important;}
.mb-5 {margin-bottom: ($spacer * 3) !important;}

.ml-0 {margin-left: 0 !important;}
.ml-1 {margin-left: ($spacer * .25) !important;}
.ml-2 {margin-left: ($spacer * .5) !important;}
.ml-3 {margin-left: $spacer !important;}
.ml-4 {margin-left: ($spacer * 1.5) !important;}
.ml-5 {margin-left: ($spacer * 3) !important;}

.p-0 {padding: 0 !important;}
.p-1 {padding: ($spacer * .25) !important;}
.p-2 {padding: ($spacer * .5) !important;}
.p-3 {padding: $spacer !important;}
.p-4 {padding: ($spacer * 1.5) !important;}
.p-5 {padding: ($spacer * 3) !important;}

.pt-0 {padding-top: 0 !important;}
.pt-1 {padding-top: ($spacer * .25) !important;}
.pt-2 {padding-top: ($spacer * .5) !important;}
.pt-3 {padding-top: $spacer !important;}
.pt-4 {padding-top: ($spacer * 1.5) !important;}
.pt-5 {padding-top: ($spacer * 3) !important;}

.pr-0 {padding-right: 0 !important;}
.pr-1 {padding-right: ($spacer * .25) !important;}
.pr-2 {padding-right: ($spacer * .5) !important;}
.pr-3 {padding-right: $spacer !important;}
.pr-4 {padding-right: ($spacer * 1.5) !important;}
.pr-5 {padding-right: ($spacer * 3) !important;}

.pb-0 {padding-bottom: 0 !important;}
.pb-1 {padding-bottom: ($spacer * .25) !important;}
.pb-2 {padding-bottom: ($spacer * .5) !important;}
.pb-3 {padding-bottom: $spacer !important;}
.pb-4 {padding-bottom: ($spacer * 1.5) !important;}
.pb-5 {padding-bottom: ($spacer * 3) !important;}

.pl-0 {padding-left: 0 !important;}
.pl-1 {padding-left: ($spacer * .25) !important;}
.pl-2 {padding-left: ($spacer * .5) !important;}
.pl-3 {padding-left: $spacer !important;}
.pl-4 {padding-left: ($spacer * 1.5) !important;}
.pl-5 {padding-left: ($spacer * 3) !important;}

.hide-in-old-design {
	display: none !important;
}