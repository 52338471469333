div.program-table-wrapper {
    background: #fff;
    padding: 1rem 2rem;

    table.program-table {
        
        tr {
            td {
                font-size: $font-size-table;
                height: 42px;
                padding: 1rem;

                &:first-child {
                    font-weight: 600;
                    padding-left: 0;
                    white-space: nowrap;
                }
            }

        }
    }

}
