.statistics-options {
  margin-bottom: 20px;

  input[type=text] {
    min-width: auto;
  }
  .dates {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 80px 200px;
  }
  .regions {
    margin-top: 10px;
  }
}
table tr.nobg {
  background-color: inherit;
}
th {
  &.tall {
    vertical-align: bottom;
  }
  &.wide {
    text-align: center;
  }
}
