img.is-ipad-logo {
    display: none;
}

.fixed-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: $z-index-header;

    #header.jumbotron {
        background-color: $color-header;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;

        .header-top {
            .row {
                display: flex;
            }
            &-right {
                &-wrapper {
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    #search {
                        float: right;
                        margin-top: 40px;
                        input {
                            float: left;
                            min-width: auto;
                            font-size: 22px;
                            height: 45px;
                            padding: 0 10px;
                        }
                        button {
                            background-color: $color-call-to-action;
                            color: $color-button-text-light;

                            float: left;
                            height: 45px;
                            margin: 0;
                            font-size: 22px;
                            padding: 0 25px;
                        }
                    }
                }
            }
        }
    }

    #sub-header {
        background-color: $color-header-menu;

        .mobile-header {
            padding: 0 30px;
        }

        #navigation {
            width: 100%;

            .nav {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .dropdownForHeaderBasket {
                    right: 0;
                    left: auto;
                }
            }
        }
    }
}

.hackedHeader {
    height: 186px;

    @media (max-width: 767px) {
        height: 59px;
    }
}

div#ezwt ~ div#outer-wrap .hackedHeader {
    height: calc(186px - 42px);
}

#nav-open-btn, #mobilemenu_link {
    display: inline-block;
    position: fixed;
    z-index: $z-index-header;
    right: 15px;
    top: 10px;
    width: 28px;
    height: 24px;

    margin-top: 10px;
    background-color: transparent;
    background-image: url(../images/ico-menu.png);
    background-repeat: no-repeat;
}

#searchMenuDesktop {
    cursor: pointer;
}
input[type="submit"] {
    display: inline;
}
.searchMenuLink.toggled {
    background-image: url('../images/close-white.png');
    background-size: 24px;
}
#searchMenuDesktop:hover .searchMenuLink {
    background-image: url('../images/search-icon-hover.png');
}
#searchMenuDesktop:hover .searchMenuLink.toggled {
    background-image: url('../images/close-blue.png');
}

#course-header {
    display: block;
    background-color: rgb(125, 125, 125);

    .container {
        .topLogoHolder {
            padding: 5px;

            img {
                height: 68px;
                margin-left: -9px;
            }
        }
    }
}

@media (max-width: 767px) {
    img.logoimgmob {
        width: 35px;
        margin: 5px 0px 5px 0px;
    }
}
