.intranet_toolbar {

    margin-top: 5px;

    & > div > div {
        display: inline-block;
    }

    .icon {
        padding: 5px;
        border-radius: 7px;
        background: transparent;
        color: #0089bc;
        cursor: pointer;
        width: 35px;
        height: 35px;
        display: inline-block;
        position: static;
        transition: all 0.5s;

        &:hover {
            background: #0089bc;
            color: white;
        }
    }
}
