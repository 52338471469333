
body {
    font-family: $f-running-text;
    color: #333;
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
}

.body--blog_front_page{
    background: #f5f5f5;
    font-family: $f-running-text;
}

.jumbotron .kmvFullView h3, .articleBody h3 {
    font-size: 23px !important;
    color: #000 !important;
}

.blog-page {
   .container {
       // margin-top: 70px;
        //margin-bottom: 80px;
   }

  .single {
&__back {
    display: inline-block;
    color: #fff;
    line-height: 40px;
    letter-spacing: 1px;
    text-decoration: none;
}
&__frontpage-arrow {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border: 2px solid #ccc;
    border-radius: 200px;
    font-size:17px;
    line-height: 37px;
    color:white;
    text-decoration:none;
    text-align: center;
}
&__text {
        header {
        display: block;
        margin: 20px auto 10px;
        font-size: 34px;
        line-height: 44px;
        font-weight: 700;
    }
}
    &__caption {
        padding-top: 10px;
        padding-bottom: 5px;
        font-size: 16px;
        line-height: 20px;
        font-style: italic;
    }
    &__image {
        width:100%;
    }
    &__header {
        margin-top: 10px;
        font-family: $f-running-text;
        font-size: 50px;
        line-height: 60px;
        font-weight: 400;
    }
    &__ingress {
        margin-bottom: 40px;
        font-size: 18px;
        line-height: 30px;
    }
    &__meta {
        min-height: 50px;
        margin-bottom: 40px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        &--name {
            display: inline-block;
            font-size: 14px;
            font-style: italic;
        }
        &--image {
            display: inline-block;
            overflow: hidden;
            width: 50px;
            height: 50px;
            margin-top: -3px;
            margin-right: 10px;
            float: left;
            border-radius: 200px;
            img {
                max-width: 100%;
                vertical-align: middle;
                display: inline-block;
            }
        }
        &--right {
            float:right;
            margin-left: 16px;
            color: #999;
            text-decoration: none;
            font-size: 14px;
            .material-icons { 
                    font-size:15px;
                    margin-right: 5px;
                    color: #999;
                    text-decoration: none;

               }
               .active {
                    color:#002E86;
               }
        }
        &--text {
            display: inline-block;
            margin-right: 20px;
            margin-left: 20px;
            font-size: 14px;
            font-style: italic;
            letter-spacing: 1px;
            text-transform: none;
            span {
                display: inline-block;
                margin-right: 5px;
                font-size:14px;
            }

        }

    }
    &__author {
        display: inline-block;
        max-width: 400px;
        float: none;
        text-align: left;

    }
    

  }
  .header {
    color: white;
    &__button {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-right: 20px;
        border: 2px solid #ccc;
        border-radius: 200px;
        font-family: 'Material design iconic font', sans-serif;
        text-align: center;
        content: '&#xE317';
    }
    &__image {
        background-position: 0px 0px, 0px 0px;
        background-size: cover;
        background-repeat:  no-repeat;
        min-height: 150px;
        padding-top: 27px;
        padding-bottom: 27px;
        background-color: #333;
    }
  }
  .cover {
      color: white;
      margin-bottom: 80px;

      &__image {
        width: 100%;
        min-height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top:50px;
      }
      &__title {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 56px;
        line-height: 72px;
        margin-bottom: 10px;
      }
      &__ingress {
        font-size: 18px;
        line-height: 32px;
      }
  }
  .content {
    .row {
         -moz-column-width: 18em;
         -webkit-column-width: 18em;
         -moz-column-gap: 1em;
         -webkit-column-gap:1em; 
    }
    .column {
        margin-bottom: 40px;
        display: inline-block;
        padding:  .25rem;
        width:  100%; 
    }
    .article { 
        &__wrapper { 
            background: white;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
            -webkit-transistion: all 200ms ease;
            transition: all 200ms ease;

        }
        &__thumb {
            position: relative;
            display: inline-block;
              overflow: hidden;

            &--image {
                max-width: 100%;
                opacity: 0.8;
                -webkit-transition: all 1000ms ease;
                transition: all 1000ms ease;
                &:hover {
                    opacity: 1;
                    -moz-transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
        }
        &__tag {
            position: absolute;
            left:       10px;
            top: 10px;
            padding: 0 10px;
            border-radius: 200px;
            font-size: 14px;
            color:white;
        }
        &__arrow {
            position: absolute;
            left: 50%;
            bottom: 0px;
            width: 0px;
            height: 0px;
            min-height: 0px;
            min-width: 0px;
            margin-left: -20px;
            border-right: 20px solid transparent;
            border-bottom: 20px solid #fff;
            border-left: 20px solid transparent;
        }
        &__title {
            margin-bottom: 5px;
            font-family:$f-running-text;
            font-weight: 400;
            font-size:24px;
            line-height: 31px;
            a{
                color:#333333;
                text-decoration: none;
            }
        }
        &__divider  {
            height: 1px;
            margin: 15px 0;
            background-image: -webkit-linear-gradient(90deg, #fff, #ccc 50%, #fff);
            background-image: linear-gradient(90deg, #fff, #ccc 50%, #fff);
        }
        &__date {
            font-family: $f-running-text;
            color: #777;
            font-size:15px;
            font-style: italic;
            font-weight: 300;
        }
        &__container {
            padding: 20px;

        }
        &__author {
            &--image {
                display: inline-block;
                overflow: hidden;
                width: 50px;
                height: 50px;
                margin-top: -3px;
                margin-right: 10px;
                float: left;
                border-radius: 200px;
            }
            &--name {
                display: inline-block;
                font-size: 14px;
                font-style: italic;
                width:65%;
            }
        }
        &__meta {
            display:inline-block;
            margin-top: 60px;
            padding-bottom: 35px;
            width:100%;
            &--right {
                float:right;
            }
            &--left {
                float:left;
                max-width: 100%;
                display: block;
                text-align: left;
                width: 100%;
            }
            &--link {
                margin-left: 16px;
                color: #999;
                font-size: 14px;
                text-decoration: none;
                float: right;
                padding:5px 0;
                .material-icons { 
                    font-size:15px;
                    margin-right: 5px;
                    color: #999;
                    text-decoration: none;

               }
               .active {
                    color:#002E86;
               }
            }
        }       
    }
  .pagination {
    &__wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-top: 40px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;      
    }
    &__link {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        text-decoration: none;
    }
    &__active {
        border: 1px solid #0159a2;
        border-radius: 200px;
    }
  }
  
  } 
  .sidebar{
    &__liked {
        margin-bottom: 30px;
        &--post {
            display: -webkit-box; 
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 10px;
            color: black;
            text-decoration: none;
            max-width: 100%;
        }
        &--heading {
            margin-bottom: 20px;
            font-family: $f-running-text;
            font-size: 22px;
            font-weight: 400;
            text-transform: uppercase;
        }
        
        &--thumbnail {
    
        }
        &--text {
            display: block;
            padding-left: 20px;

        }
        &--image {
            width: 70px;
            height: 70px;
            float: left;
                margin-right: 10px;

        }
        &--title {
            margin-top: 10px;
            font-family: $f-running-text;
            font-size: 16px;
            line-height: 21px;
            font-weight: 700;
            //word-wrap: break-word;
            word-break: break-all;
        }
        &--date {
            display: block;
            font-family: $f-running-text;
            color:#777;
            font-size: 15px;
            font-style: italic;
            font-weight: 300;
        }
    }
    &__archive {
        max-width: 200px;
        margin-bottom: 30px;
        &--title {
            margin-bottom: 20px;
            font-family: $f-running-text;
            font-size: 22px;
            font-weight: 400;
            text-transform: uppercase;
        }
        &--month {
            display: block;
            margin-bottom: 5px;
            padding: 3px 0;
            color: #333;
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;
            text-decoration: none !important;
            .amount {
                display: inline-block;
                margin-left:16px;
                padding-right: 12px;
                padding-left: 12px;
                float:right;
                border-radius: 200px;
                background-color: #ddd;
                color:#333;
                font-size: 12px;
            }
        }
    }
    &__tags {
        &--title {
            margin-bottom: 20px;
            font-family: $f-running-text;
            font-size: 22px;
            font-weight: 400;
            text-transform: uppercase;
        }
        &--tag {
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 2px 12px;
            border: 2px solid #ccc;
            border-radius: 200px;
            background-color: transparent;
            color: #666;
            font-size: 14px;
            text-decoration: none;
        }
    }
    }
  }


/*  general */
.no-gutter  {
    padding-right:0;
    padding-left:0;
}

.single__share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    margin-bottom: 70px;
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    &--icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-right: 5px;
        margin-left: 5px;
        border: 1px solid #ddd;
        border-radius: 200px;
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease;
        font-family: 'Material design iconic font', sans-serif;
        color: #333;
        font-size: 20px;
        line-height: 50px;
        text-decoration: none;
    }
    &--facebook {
        border-color: #3b5998;
        background-color: #3b5998;
        color: #fff;    
    }       
    &--google{
        border-color: #dd4b39;
        background-color: #dd4b39;
        color: #fff;    
    }       
    &--twitter {
        border-color: #55acee;
        background-color: #55acee;
        color: #fff;    
    }       
    &--linkedin {
        border-color: #007bb5;
        background-color: #007bb5;
        color: #fff;
    }
    &--mail {
        border-color: #333;
        background-color: #333;
        color: #fff;
    }
}
