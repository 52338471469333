//
// * -------------------------------------------------
// *  CSS from lastebil.css
// * -------------------------------------------------
//
.noproxima { font-family: $f-title !important; font-weight: $f-title-bold }

.is-ipad-logo {
    display: none;
}

@media print
{
    .no-print, .no-print *, #appendixEditBottom, .searchButtonBlock
    {
        display: none !important;
    }
    .only-print {
        display: block !important;
    }
}

.only-print {
    display: none;
}

.logo-print {
    margin-bottom: 50px;
    width: 90px;
    margin-left: -25px;

    img {
        width: 100%;
    }

}
// new
.rotate-icon {
    transition: transform 0.5s ease;

    &.active {
        transform: rotate(90deg);
    }
}


// Previously campaign
.centerContent {
    text-align: center;
}
.rightContent {
    text-align: right;
}
.navbar-nav li {
    color: white;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.navbar-nav li a {
    color: white;
}
.navbar-nav > li > a {
    line-height: 19px;
    font-size: 21px;
    text-transform: uppercase;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
  color: #2b85ba;
}
.topSloganHolder {
    padding-top: 44px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.topSloganHolder .topSlogan {
    display: block;
    color: white;
    font-size: 45px;
    line-height: 47px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.topSloganHolder .topLink a {
    color: white;
    text-decoration: underline;
    font-size: 25px;
    line-height: 24px;
}
.topMemberHolder {
    padding-top: 35px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.topMemberHolder a {
    color: #2b85ba;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    text-decoration: underline;
    background-image: url('../images/membericon.png');
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 38px;
    display: block;
}
.topMemberHolderSmall {
    display: none!important;
}
.smallHeader .topMemberHolderSmall {
    display: block!important;
    float: right;
}
.topMemberHolderSmall a {
    background-image: url('../images/membericon_white.png');
    color: white;
}
.smallHeader #nav {
    float: right;
}
.newsTicker {
    width: 100%;
    overflow: hidden;
}
.newsTickerTruckIcon {
    background-image: url('../images/truck1.png');
    background-repeat: no-repeat;
    background-position: center left;
    display: inline-block;
    padding-left: 55px;
    font-size: 17px;
    line-height: 26px;
    margin-right: 22px;
    color: #000000;
    text-decoration: none;
}
.newsTickerTruckIcon:nth-child(odd) {
    background-image: url('../images/truck2.png');
    padding-left: 67px;
}
.orangeRoundedBG {
    font-family: $f-title;
    font-weight: $f-title-bold;
    background-color: #f39200;
    font-size: 25px;
    line-height: 25px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: block;
    color: white;
    text-align: center;
    padding: 9px 10px;
}

/* NEWS BOXES */
.newsBox h2 {
    font-size: 35px;
}
.newsBox h2 a  {
    color: black;
}
.newsBox p {
    font-size: 17px;
}
.newsBoxBig p {
    font-size: 22px;
    color: black;
}
.newsBoxBig h2 {
    font-size: 52px;
}

/* FULL VIEW */
.articleDate {
    background-color: #2b85ba;
    color: white;
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 23px;
    line-height: 32px;
    display: inline-block;
    float: left;
    padding: 0px 10px;
    margin-right: 16px;
}
.articleFullView h1 {
    font-size: 65px;
}
.articleFullView h2 {
    font-size: 32px;
    color: black;
    line-height: 32px;
}

.articleIntro {
    font-size: 24px;
}
.jumbotron .articleIntro p {
    line-height: 30px;
}
.articleBody {
    font-size: 17px;
    margin-top: 30px;
}
.articleSubHeading {
    margin-bottom: 30px;
}
.moreAboute {
    border-left: 6px solid #2b85ba;
    padding-left: 16px;
}
.moreAboute strong {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    line-height: 22px;
    font-weight: normal;
}
.moreAboute ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.moreAboute ul li a{
    text-decoration: underline;
    font-size: 20px;
}
.moreAboute ul li {
    line-height: 20px;
    padding-bottom: 12px;
}

.subMenu {
    list-style: none;
    padding: 0;
    margin: 0px 0px 0px 0px;
}
.subMenu li {
    border-bottom: 0px solid #c2c2c2;
    padding: 0px 24px 8px 24px;
    line-height: 30px;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.subMenu li.nohover,
.subMenu li.hohover:hover {
    padding: 14px 20px 13px;
    background: #2b85ba;
    color: #fff;
}
.subMenu li:last-child {
    border-bottom: 0px;
    padding-bottom: 12px;
}
.subMenu li.indented {
    padding-top: 11px;
    background: #f1f1f1;
}
.subMenu > li.indented ~ li.indented {
    padding-top: 5px;
}
 a.startItem{
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    color: #f39200;
    border-bottom: 1px solid #c2c2c2;
    display: block;
    padding-bottom: 10px;
}
.subMenu li a {
    color: #000;
}
.subMenu li .material-icons {
    margin-right: 5px;
}
.subMenu .nohover a {
    vertical-align: middle;
    color: #fff;
}
.subMenu .nohover a:hover,
.subMenu .nohover:hover a {
    cursor: pointer;
    color: #f1f1f1;
}
.subMenu li.active a,
.subMenu li a.selected{
    color: #f39200;
    text-decoration: underline;
}
/*.subMenu li.active,
.subMenu li.indented {
    background-color: #e4e4e4;
}*/
.subMenu li.indented.active {
    background: #e4e4e4;
}
.subMenu li.active:first-child {
    background-color: transparent;
}
.subMenu li:hover a {
    color: #f39200;
    text-decoration: none;
}
.subMenu li.active:first-child a {
    text-decoration: none;
}

.subMenu li .membertabs {
    margin-top: 12px;
    padding-left: 0;
    list-style: none;
}

.subMenu li .membertabs.nav-tabs {
    border: 0;
}

.subMenu li .membertabs li {
    width: 100%;
    display: block;
}

.subMenu li .membertabs li.active,
.subMenu li .membertabs li.active a,
.subMenu li .membertabs a:hover {
    border: 0;
    background: #e4e4e4;
    color: #f39200;
}

.subMenu li .membertabs a {
    font-size: 20px;
    text-decoration: none;
    color: #333;
}

li.nohover.givespace {
    padding: 0;
}

li.nohover.givespace a {
    display: block;
    padding: 14px 20px 13px;
}

li.nohover.givespace a:hover,
li.nohover.givespace a:active,
li.nohover.givespace a:focus {
    text-decoration: none;
}

li.nohover.givespace a .material-icons {
    position: relative;
    top: 3px;
}

li.nohover.givespace > strong {
    display: block;
    padding: 14px 20px 13px;
}

/** Calendar **/

.calendarText h4 {
    font-size: 45px;
}
.calendarText {
    margin-left: 120px;
    display: block;
}
.calendarText a {
    color: white;
}

.calendarText p a {
    color: #2b85ba;
}

.calendarText p {
    padding-left: 10px;
    font-size: 17px;
}
.calendarDateWrapper {
    background-color: white;
    color: #2b85ba;
    width: 105px;
    float: left;
    padding: 14px 0px;
    display: block;
    text-align: center;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.calendarDateWrapper div {
    display: block;
    line-height: 26px;
    text-transform: lowercase;
}
.jumbotron-calendar .calendarDateWrapper div.calendarDate {
    font-size: 45px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.jumbotron-calendar .calendarDateWrapper div.calendarMonth {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.jumbotron-calendar .calendarDateWrapper h4 {
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.calendarList {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.calendarList li  {
    margin-bottom: 30px;
}
.footerList {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.footerList li  {
    line-height: 16px;
    padding-bottom: 6px;
}
.footerList li a {
    color: #000000;
    font-size: 17px;
    text-decoration: none;
    line-height: 16px;
}
.contactList {
    list-style: none;
    padding: 0px 0px 0px 0px;
    margin: 0px;
}
.contactList li {
    color: #000000;
    line-height: 17px;
    font-size: 17px;
    padding-bottom: 6px;
}
.contactList li a {
    color: #2b85ba;
    text-decoration: underline;
}

/* OVERRIDES FOR BOOTSTRAP */
.jumbotron h3 {
    color: #2b85ba;
    font-size: 60px;
}
.jumbotron {
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
}
.jumbotron-white {
    background-color: #ffffff;
}
.jumbotron-whitesmoke {
    background-color: #f5f5f5;
}
.jumbotron-blue {
    background-color: #2b85ba;
    color: white;
}
.jumbotron-black {
    background-color: #010100;
}
.jumbotron-newsTicker {
    padding-bottom: 0px;
}

.jumbotron-partners {
    color: #2b85ba;
    background-color: #fff;
}
.jumbotron-partners h3 {
    font-size: 65px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    padding-bottom: 34px;
}
.jumbotron-partners h3 em {
    font-size: 25px;
    margin-left: 40px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    line-height: 28px;
}
.jumbotron-calendar h3 {
    font-size: 65px;
    color: white;
    font-family: $f-title;
    font-weight: $f-title-bold;
    padding-bottom: 40px;
}
.jumbotron-calendar h3 em {
    font-size: 25px;
    display: inline-block;
    padding-left: 20px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.jumbotron-calendar h3 em a {
    color: white;
}
#view_content h3 {
    color: initial;
    font-size: 24px;
}
.btn {
    font-size: 21px;
}
.btn-info {
    background-color: #2b85ba;
    border-color: #2b85ba;
}.breadcrumb {
    background-color: transparent;
    border-radius: 0px;
    margin: 10px 0;
    padding: 0px;
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.breadcrumb > .active {
    color: black;
}
.breadcrumb > li {
    font-size: 21px;
}
.breadcrumb > li + li:before {
    color: black;
    padding: 0px 0px 0px 5px;
}

.smallHeader  .jumbotron-header{
    width: 100%;
    height: 90px;
    z-index: 10;
    padding: 10px 0px!important;
    margin-bottom: 0px!important;
}
.smallHeader  .navbar-nav {
    margin-top: 31px!important
}
#smallHeaderContent {
    display: none;
    margin-left: 10px;
}
.smallHeader  #smallHeaderContent {
    display: block;
}
.smallHeader #bigHeaderContent {
    display: none;
}
.menuRow .col-md-2,
.menuRow .col-md-10 {
    margin-bottom: 0px;
}
.smallHeader #nav {
    bottom: -31px;
}
.smallHeader #header{
    background-image: none!important;
}
.smallHeader .jumbotron-newsTicker {
    padding-top: 150px;
}
.adIndicator {
    width: 6px;
    height: 52px;
    background-image: url('../images/ad.png');
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
}
.ifAddThenRight {
    margin-bottom: 30px;
}
.ifAddThenRight .adBox {
    float: right;
}
.campaignBox-spotify html,
.campaignBox-spotify body {
    margin: 0px;
    padding: 0px;
}
.spotifyLogo {
    margin-bottom: 18px;
}
.roadImage {
    position: relative;
    top: 50%;
}
ul.dropdown-menu {
    margin-top: 8px;
    &.fix-margins-paddings {
        margin-top: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
        li a:hover {
            margin-right: 0px;
            margin-left: 0px;
            padding-left: 21px;
        }
        li a {
            font-size: 20px;
        }
    }
    li a.disabled {
        color: gray;
        pointer-events: none;
    }
}
li .dropdownArrow {
    display: none;
}
li.dropdown .dropdownArrow {
    display: none;
    width: 100%;
    height: 16px;
    position: absolute;
    background-image: url('../images/submenuarrow.png');
    background-position: top center;
    background-repeat: no-repeat;
    z-index: 99999;
}
li.dropdown:hover .dropdownArrow {
    display: block;
}
.navbar-nav > li > .dropdown-menu {
    padding-top: 7px!important;
    border: none!important;
    background: none!important;
    box-shadow: none!important;
    border-radius: none!important;
}

ul.dropdown-menu li {
    background-image: none!important;
    border: 1px solid #cfcfcf;
    border-style: none solid;
    background-color: #ededed;
    text-align: left;
}
ul.dropdown-menu li:first-child,
ul.dropdown-menu li  {
    padding: 0px!important;
}
ul.dropdown-menu li a {
    color: #00003f;
    font-size: 24px;
    margin: 0px 1px;
    border-bottom: 1px solid #cccccc;
}
ul.dropdown-menu li a:hover,
ul.dropdown-menu li a:focus {
    background-color: #cfcfcf;
    color: #00003f;
    margin: 0px;
    padding: 3px 21px;
}
ul.dropdown-menu li:first-child{
    border-style: solid solid none solid;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
ul.dropdown-menu li:last-child{
    border-style: none solid solid solid;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}
ul.dropdown-menu li:only-child {
    border-style: solid solid solid solid;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
ul.dropdown-menu li:last-child a,
ul.dropdown-menu li:only-child a {
    border-bottom: 0px solid!important;
}
li.dropdown a.imageLink {
    padding-top: 12px!important;
    padding-bottom: 13px!important;
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.chosen-container .chosen-results li.active-result {
    color: black;
}
.chosen-container .chosen-results li.highlighted {
    color: white;
}

/* FORMS */
input[type=text],
input[type=number],
input[type=email],
input[type=password],
input[type=time],
select.lastebil-form,
textarea{
    border: 1px solid #cccccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 18px;
    color: black;
    padding: 0px 10px;
    min-width: 100%;
}
input[type=text],
input[type=number],
input[type=email],
input[type=password],
select.lastebil-form,
input[type=time] {
    height: 35px;
}
input[type=submit],
input[type=button],
button[type=button],
button[type=submit],
.button {
    background-color: #2b85ba;
    border: 1px solid #2b85ba;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: white;
    font-size: 24px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    padding: 4px 25px;
    line-height: 36px;
    margin: 10px 5px 0 5px;
}

div.modal-dialog div.modal-header button
{
    margin-top: -10px;
}


html:not(.redesign) a.button {
    color: #ffffff;
    margin: 10px 5px 0 5px;
}

input[type=button]:focus,
input[type=button]:hover,
input[type=submit]:focus,
input[type=submit]:hover {
    background-color: #086283;
    border-color: #086283;
}

/* MESSAGE BOXES */
.msgBox {
    border-radius: 4px;
    padding: 2px 10px 2px 40px;
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    margin: 10px 0px;
    background-color: #e2ebf8;
    background-image:url('../images/info-icon.png');
    background-repeat: no-repeat;
    background-position: 10px center;
}
.msgBox.msgBox-success {
    background-color: #dbfae1;
    background-image:url('../images/success-icon.png');
}
.msgBox.msgBox-error {
    background-color: #f8dfb9;
    background-image:url('../images/error-icon.png');
}
.msgBox a {
    text-decoration: underline;
}
.msgBox strong {
    font-family: $f-title;
    font-weight: $f-title-bold;
}
th select {
    font-size: 16px;
}
.modal-block {
    clear: both;
    margin-bottom: 10px;
}
.modal-block label {
    width: 120px;
    font-size: 14px;
    float: left;
}
.modal-block input[type="text"] {
    min-width: 150px!important;
    width: 410px!important;
    font-size: 14px;
    float: left;
}
.modal-block .input-append.date input[type="text"] {
    min-width: 100px!important;
    width: 100px!important;
}

.modal-block select {
    font-size: 14px;
    float: left;
}
.modal-block textarea {
    border: 1px solid #336EA9;
    border-radius: 3px 3px 3px 3px;
    font-size: 14px;
}
.table-condensed tr th,
.table-condensed tr td {
    font-size: 14px;
}
.ui-datepicker-calendar tr td:first-child {
    padding: 0px!important;
}
.modal-block .glyphicon-calendar {
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
    float: left;
}
@media screen and (min-width: 900px) {
    .modal-dialog {
        width: 800px;
    }
}
.modal-dialog .modal-content h3 {
    font-size: 24px;
    color: initial;
}
.datepicker, .datepicker option{
z-index:99999;
}
.jumbotron-partners .img-responsive {
    display: inline;
}
.calendarText {
    margin-right: 160px;
}
.jumbotron-calendar .container {
    background-image: url('../images/road.png');
    background-position: right center;
    background-repeat: no-repeat;
}

#nav-open-btn,#mobilemenu_link {
    background-color: transparent;
    background-image: url('../images/ico-menu.png');
    background-repeat: no-repeat;
    width: 28px;
    height: 24px;
    display: inline-block;
    z-index: 9999;
    margin-top: 10px;
    position: fixed;
    right: 15px;
    top: 10px;
}
#nav-close-btn {
    display: none;
}
#searchMenuSmall {
    display: none;
}
#userMenuSmall {
    display: none;
}
#sidr-id-searchMenuDesktop,
#sidr-id-userMenuDesktop {
    display: none;
}
input.date {
    width: 120px;
    min-width: 120px;
}
input.time {
    width: 70px;
    min-width: 70px;
}
.row input[type="text"].small-box,
.row input.small-box,
.small-box {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
}
.errorExplanation {
    color: red;
}
td.rubricImage {
    max-width: 120px;
    padding-right: 20px;
}
.rubricList td {
    vertical-align: top;
}
.torgetMenu select {
    width: 80%;
}
.feedback-collector-error span {
    background-color: #D9534F;
    border-radius: 0.25em 0.25em 0.25em 0.25em;
    color: #FFFFFF;
    display: inline;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    padding: 0.2em 0.6em 0.3em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
    margin-left: 10px;
    position: absolute;
    margin-top: 27px;
    left: 140px;
}
.textRight {
    text-align: right;
}
td.rightPadding {
    padding-right: 10px;
}
.torgetImage {
    max-width: 100%;
    height: 190px;
}
.rubricBox {
    min-height: 344px;
    max-height: 344px;
    height: 344px;
    text-align: center;
    border: 1px solid #c4c4c4;
    padding: 20px;
    margin-bottom: 40px;
}
.rubricBoxWithouText {
    min-height: 234px;
    max-height: 234px;
    height: 234px;
}
.rubricBox img {
    margin: 0px auto;
    border: 1px solid #d3d3d3;
}
.rubricBox .text {
    text-align: left;
    line-height: 22px;
    margin-top: 24px;
}
.rubricBox strong a {
    font-size: 25px;
    color: black;
    text-decoration: none;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.rubricBox span {
    color: #e63032;
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    display: block;
    padding-top: 10px;
}
span.regionType,
span.torgetType {
    color: #e63032;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
span.regionType {
    color: #646464;
}
.rubricBox .torgetType-7388,
.torgetType-7388{
    color: #2b85ba;
}
.pagenavigator {
    width: 100%;
    text-align: right;
}
.pagenavigator span {
    background-color: #f1f1f1;
    border: 1px solid #c4c4c4;
    padding: 2px 10px 5px 10px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.pagenavigator span.current {
    color: #f39200;
    text-decoration: underline;
}
.navbar-nav > li > .searchDropdown {
    border-radius: 0px!important;
    padding: 27px 0px 0px 0px!important;
    border: 0px!important;
    margin: 0px!important;
}
.navbar-nav > li > .searchDropdown li {
    padding: 28px;
    border-radius: 0px!important;
    border: 0px!important;
}
.navbar-nav > li > .searchDropdown li .searchBlock input {
    width: 320px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    color: black;
    font-size: 22px;
    background-color: #ededed;
    border-radius: 0px!important;
    border-color: #cfcfcf!important;
    border-style: none solid solid solid;
}
.navbar-nav > li > .dropdownLoginForm {
    border-radius: 0px!important;
    padding: 27px 0px 0px 0px!important;
    border: 0px!important;
    margin: 0px!important;
}
.navbar-nav > li > .dropdownLoginForm li {
    padding: 28px;
    border-radius: 0px!important;
    border: 0px!important;
}
.loginForm .menuBlock input {
    width: 320px;
    border-color: white;
    font-family: $f-title;
    font-weight: $f-title-normal;
    color: black;
    font-size: 22px;
}
.loginForm .menuBlock {
    padding: 28px 28px 0px 28px;
}
.loginForm .menuButtonBlock {
    padding: 28px;
}
.loginForm .menuButtonBlock a  {
    color: #2b85ba;
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    display: inline-block;
    padding-top: 10px;
}
.loginForm .menuButtonBlock a:hover,
.loginForm .menuButtonBlock a:focus {
    background-color: transparent;
}
.torgetFilterForm {
    border-top: 8px solid #f39200;
    margin-top: 40px;
    padding-top: 20px;
}
.torgetFilterForm strong {
    font-size: 25px;
    color: #000;
    font-family: $f-title;
    font-weight: $f-title-bold;
    display: block;
    margin-bottom: 25px;
}
.torgetFilterForm label {
    color: #000000;
    font-size: 17px;
    font-weight: normal;
    max-width: 245px;
    margin-left: 4px;
    cursor: pointer;
}
.torgetFilterForm .innerBlock {
    line-height: 15px;
    margin-bottom: 8px;
}
.torgetMenuTop {
    margin-top: 115px;
}
.torgetMenu {
    margin-top: 20px;
}
.torgetMenu .subMenu {
    padding-top: 15px;
}
.rubric-image img {
    border: 1px solid #d3d3d3;
}
.rubric-image {
    margin-bottom: 30px;
}
.rubricFullView {
    margin-top: 30px;
}
.sellerInfo {
    border-top: 1px solid #2b85ba;
}
.sellerInfo {
    font-size: 17px;
    line-height: 32px;
    padding-top: 20px;
    padding-left: 10px;
    margin-left: 15px;
}
.sellerInfo strong {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.sellerInfo span.seller {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.rubricPrice {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    color: #3c3c3c;
}
.rubricFullView p {
    font-size: 17px;
}
a.button {
    background-color: #2b85ba;
    border: 1px solid #2b85ba;
    border-radius: 3px 3px 3px 3px;
    color: #FFFFFF;
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 24px;
    line-height: 36px;
    padding: 4px 25px;

    &:hover,&:focus{
        text-decoration: none;
        background-color: #086283;
        border-color: #086283;
    }
}
.calendarLine .calendarText  h4,
.calendarLine .calendarText h1{
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    padding: 5px 0px 20px 0px;
    margin: 0px;
    color: black;
}
.calendarLine .calendarText h4 a {
    color: black;
}
.calendarLine .calendarText {
    margin-right: 0px!important
}
.calendarLine .calendarText p{
    padding-left: 0px;
}
.calendarLine {
    line-height: 23px;
    border-top: 1px solid #d2d2d2;
    padding-top: 30px;
    padding-bottom: 20px;
}
.calendarLine .calendarDateWrapper {
    background-color: #2b85ba;
    color: white;
}
.calendarLine-messe .calendarDateWrapper {
    background-color: #00003f;
}
.calendarLine-seminar .calendarDateWrapper {
    background-color: #e63032;
}
.calendarLine .calendarDateWrapper .calendarDate {
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    margin-bottom: 5px;
}
.calendarLine .calendarDateWrapper .calendarMonth {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    text-transform: uppercase;
}
.calendarLine .calendarCategory {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    color: #2b85ba;
}
.calendarLine-messe .calendarCategory {
    color: #00003f;
}
.calendarLine-seminar .calendarCategory {
    color: #e63032;
}
.calendarLine dl {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.calendarLine dl dt {
    font-family: $f-title;
    font-weight: $f-title-bold;
    float: left;
    width: 112px;
}
.calendarLine-noborder {
    border-top: 0px!important;
}
.rightLink  {
    color: #2b85ba;
    font-size: 25px;
    margin-top: 38px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.cirleItem {
    text-align: center;
    padding-bottom: 30px;
}
.cirleItemPurple,
.cirleItemOrange {
    color: #f39200;
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.cirleItemPurple {
    color: #00003f;
}
.kmvBox {
    background-color: #eeeeee;
    padding: 10px 30px 10px 30px;
    margin-bottom: 30px;
    line-height: 32px;
}
.kmvBox h2 {
    padding-bottom: 10px;
}
.kmvBox h2 a {
    color: #2b85ba;
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.kmvBox ul,
.kmvBox ul li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.kmvBox ul { padding-bottom: 20px; }
.kmvBox ul:empty { padding-bottom: 0; }
.kvmSearchBoxFront .searchButtonBlock {
    background-color: #eee;
}
.kvmSearchBoxFront .searchButtonBlock .searchInput {
    min-width: 70%!important;
    max-width: 70%!important;
    border: 0px!important;
    border-radius: 0px!important;
    background: transparent!important;
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 25px;
    color: #0e0e0e;
}
.kvmSearchBoxFront .searchButtonBlock .searchButton  {
    background-image: url('../images/search_blue.png')!important;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -1000000px;
    border: 0px!important;
    margin: 4px 0px 0px 0px;
    border-radius: 0px!important;
    background-color: transparent!important;
}
.jumbotron h1.kmvHeading {
    font-size: 85px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    margin: 0;
    padding: 0;
}
.kmvHeadingHolder {
    margin-bottom: 40px;
}
.kmvOptions {
    margin-top: 44px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;

    .sidebar_options {
        margin-bottom: 25px;
        font-size: 25px;
        font-family: $f-title;
        font-weight: $f-title-normal;
        padding: 0px 5px;
        position: relative;
    }

    .sidebar_options:after {
        content: "";
        display: block;
        border-top: 1px solid #ccc;
        width: 76px;
        height: 0.5em;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

    }

    .options_button {
        width: 100%;
        display: block;
        padding: 0px 0px 0px 35px;
        background-color: #2b85ba;
        border-radius: 3px;
        color: #fff;
        font-size: 17px;
        line-height: 36px;
        margin-bottom: 10px;
        margin-left: 0px;
        text-align: left;
        font-family: $f-running-text;
        position: relative;

        a {
            color: #fff;
        }

        i {
            position: absolute;
            left: 10px;
            color: #fff;
            line-height: 36px;
        }

    }

}
.kmvOptions ul li {
    padding: 0px 0px 10px 0px;
    margin: 0px;
    list-style: none;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    line-height: 22px;
}
.kmvOptions ul li.bookmarkIcon {
    background-image: url('../images/bookmark.png');
    background-repeat: no-repeat;
    background-position: 10px top;
    padding-left: 32px;
}
.kmvOptions ul li.pencilIcon {
    background-image: url('../images/pencil.png');
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 32px;
}
.kmvFullView .articleDate {
    background: none!important;
    color: #646464;
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 17px;
    padding: 0px;
}
#appendixHeading {
    background-image: url('../images/appendixbg.png');
    background-repeat: no-repeat;
    background-position: right 62%;
    background-size: 85% 1px;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    color: #e63032;
    padding: 0px;
    line-height: 25px;
    margin: 0px;
}
.appendixDate {
    background-color: #eae9e6;
    border-radius: 4px;
    color: black;
    font-size: 17px;
    line-height: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    padding: 5px 10px;
    margin: 15px 0px 30px 0px;
    display: inline-block;
    a {
        vertical-align: text-top;
    }
}
.treeview a {
    font-size: 22px!important;
    line-height: 20px!important;
    font-family: $f-title;
    font-weight: $f-title-normal;
    color: black;
}
.kvmMenu {
    margin-top: 10px!important;
}
.kvmMenu li {
    border-bottom: 0px;
}
.kvmMenu li:last-child {
    padding-bottom: 0px;
}
#kvmMenu .subMenu li {
    color: black;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
#kvmMenu .subMenu li a {
    color: black;
}
#kvmMenu .subMenu li a.selected {
    color: #f39200;
}
#kvmMenu .subMenu li.active a {
    color: #f39200;
    text-decoration: none;
}
#kvmMenu .subMenu li strong a {
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 35px;
}
.kvmMenu  ul li {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
#mainArticle {
    padding-bottom: 50px;
}
.eventInfo {
    background-color: #f1f1f1;
    padding: 5px 30px;
}
.eventInfo {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    margin: 30px 0px 50px 0px;
}
.eventInfo dl dt {
    font-family: $f-title;
    font-weight: $f-title-bold;
    float: left;
    width: 100px;
}
.eventTime {
    font-size: 25px;
    color: #646464;
    background-image: url('../images/calendaricongrey.png');
    background-repeat: no-repeat;
    padding-left: 40px;
    line-height: 30px;
    background-position: left center;
    font-family: $f-title;
    font-weight: $f-title-normal;
    margin: 0px 0px 25px 0px;
}
.block {
    clear: both;
    margin-bottom: 15px;
}
.block input[type=text],
.block textarea,
.block input[type=time],
.block input[type=password] {
    border: 0px!important;
    border-radius: 0px!important;
    background-color: #eeeeee;
    min-width: 70%!important;
    width: 70%!important;
}
.block input[readonly=readonly] {
    color: #C2C2C2;
}
.block input[type=text].date, .block input[type=time].time {
    width: 120px!important;
    min-width: 120px!important;
}
.block label {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 22px;
    width: 135px;
    line-height: 24px;
    float: left;
    margin-top: 12px;
    font-weight: normal;
    text-indent: 0px!important;
}
.block input[type="text"].small-box {
    width: 100px!important;
    max-width: 100px!important;
    min-width: 100px!important;
}
.block.invalid-field {
    background: rgba(255, 0, 0, .2);
    border-radius: 4px;
    padding: 5px;
}
    .block.invalid-field label {
        margin-top: 3px;
    }
table.smallTable {
    width: 50%;
    max-width: 50%;
    min-width: 50%;
}
table.smallTable th {
    font-size: 20px;
}
table.smallTable td {
    font-size: 16px;
}
th.numCol, td.numCol {
    text-align: right !important;
    padding-right: 20px;
}
.fyllingRight {
    padding-top: 140px;
}
.fyllingRight a {
    color: #2b85ba;
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 25px;
    text-decoration: underline;
}
dl.summaryBox {
    background-color: #f3f3f3;
    border: 1px solid #e9e9e9;
    padding: 20px 30px;
}
dl.summaryBox dt {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    float: left;
    width: 150px;
}
dl.summaryBox dd {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
#currentAppendix {
    padding-bottom: 25px;
}
.addDeviationForm label {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.addDeviationForm input[type=text] {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 22px;
}
.jubmotron h1.torget {
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 85px;
}

/* USER/LOGIN */
.loginPage {
    padding-bottom: 150px;

    .block-external-link {
        margin-top: 30px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                padding: 5px 0;
            }
        }
    }
}

@media (max-width: 640px) {
    .loginPage .block-external-link {
        ul {

            font-size: 16px;
            a, li {
                font-size: 16px;
            }
        }
    }
}

.loginBox {
    background-color: #f3f3f3;
    padding: 15px 40px;
}
.loginBox h1 {
    font-size: 60px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.loginBox h2 {
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.loginBox ul li {
    font-size: 17px;
}
.loginBox .block input[type=text],
.loginBox .block  input[type=password] {
    border: 0px!important;
    border-radius: 0px!important;
    background-color: white;
    max-width: 100%!important;
    width: 100%!important;
}
.loginBox label {
    display: block;
    clear:both;
    width: 100%;
    float: none;
    clear: both;
}
.loginBox .buttonblock a {
    float: right;
    font-family: '';
}
.calIco {
    background-image: url('../images/paamelding_kurs_ikon.png');
    background-repeat: no-repeat;
    padding-right: 30px;
    background-position: right center;
}
.loginPage h2 {
    font-size: 65px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.loginPage p,
.loginPage ul li {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.loginPage h1 {
    font-size: 65px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.loginPage .buttonblock a {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.loginPage .buttonblock input {
    font-size: 25px;
}
.searchLine h3 {
    font-size: 25px;
    padding: 0px;
    margin: 0px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.searchLine {
    border-top: 1px solid #D2D2D2;
    line-height: 23px;
    padding-bottom: 20px;
    padding-top: 30px;
}
.searchPath {
    padding: 0px;
    margin: 0px;
}
.searchPath li {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 17px;
    list-style: none;
    padding: 0px;
    margin: 0px;
    float: left;
}
.searchPath li:before {
    content : '/';
    padding: 0px 5px;
}
.searchPath li:first-child:before {
    content : '';
    padding: 0px;
}
.searchLine .calendarText {
    padding: 0px;
    margin: 0px;
}
.searchLine .calendarText a {
    color: #2b85ba;
}

/* CUSTOM ALERTS */
.alert {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 22px;
}
.alert a {
    text-decoration: underline;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.alert-success a  {
    color: #468847;
}
.alert-warning a {
    color: #c09853;
}
.alert-danger a {
    color: #b94a48;
}
.forgottenPasswordBox .alert {
    font-size: 17px;
}
#ChangeAppendix .buttonblock #AppendixSave {
    float: left;
    margin-right: 10px;
}
.facebookHolder {
    min-height: 335px;
}
.pagenotfound h1 {
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 40px;
}
.pagenotfound p {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 22px;
    line-height: 20px;
    padding: 0px;
}
.pagenotfound ul {
    list-style: none;
    padding:0;
    margin:0;
}
.pagenotfound ul li {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 22px;
    margin: 0px;
    padding-left: 1em;
    text-indent: -.7em;
    line-height: 30px;
}
.pagenotfound ul li:before {
    content: "• ";
    color: #2b85ba; /* or whatever color you prefer */
}
.magazine-line {
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.tagCloudLeft {
    border-top: 8px solid #F39200;
    margin-top: 40px;
    padding-top: 20px;
}
.tagCloudLeft h3 {
    color: #000000;
    display: block;
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 25px;
}
.tagsFullView h1 {
    text-transform: capitalize;
}
.tagCloudLeft .tagCloud {
    font-family: $f-title;
    font-weight: $f-title-normal;
    line-height: 30px;
    text-transform: lowercase;
}
.object-left {
    float: left;
    margin: 0px 15px 15px 0px;
}
.object-right {
    float: right;
    margin: 0px 0px 15px 15px;
}
.shopButton {
    float: right;
}
input[type=text].extraSmallInput {
    width: 55px!important;
    min-width: 55px!important;
    max-width: 55px!important;
    text-align: center;
}
.product-details-remove {
    padding-right: 5px;
}
.content-basket td {
    line-height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.product-name {
    padding-left: 10px;
}
.headerBasketTable {
    width: 500px;
}
.headerBasketWrapper {
    padding: 20px 0px;
}
.headerBasketTable tr td {
    font-size: 16px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.headerBasketName {
    padding-left: 4px;
}
.headerBasketWrapper .checkout {
    text-align: center;
    margin-top: 10px;
}
.textRight{
    text-align: right;
}
.leftBasketItem {
    border-bottom: 2px solid #c9c9c9;
}
.imageCaption {
    font-size: 16px;
}
.tagsView h2 {
    border-bottom: 4px solid #C2C2C2;
    padding-bottom: 4px;
    margin-bottom: 4px;
    font-size: 26px;
}
.tagsView ul  {
    margin: 0px 0px 0px 0px;
    padding: 0px;
    list-style: none;
}
.tagsView ul li {
    line-height: 16px;
    font-size: 16px;
    list-style: none;
    margin-top: 10px;
}
.shopOpt label {
    display: block;
    float: none;
}
.employee .text {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    line-height: 27px;
}
.employee .text strong {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-weight: normal;
}
.employee .employeeImage {
    padding-right: 0px!important;
}
.fullEmployees h2 {
    font-size: 35px;
    padding-bottom: 30px;
}
.fullEmployees h1 {
    font-size: 85px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    color: black;
}
.employeeDepartment {
    padding-bottom: 20px;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 20px;
}
.pressImages h2 {
    padding: 0px;
    margin: 0px;
}
.pressImages .articleIntro p {
    font-size: 17px;
}
.fullPartnerView h2,
.fullPartnerView h3 {
    padding: 0px 0px 4px 0px;
    margin: 0px;
    color: black;
}
.fullPartnerView {
    font-size: 17px;
    line-height: 17px;
}
.fullPartnerView h2 {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    line-height: 25px;
}
.fullPartnerView p {
    padding: 10px 0px 15px 0px;
}
.fullPartnerView h3 {
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    line-height: 35px;
}
.fullPartnerView .partnerLine {
    padding-top: 20px;
    border-top: 1px solid #c2c2c2;
    margin-top: 20px;
}

/* BASKET */
.basketSummary {
    width: 50%;
}
table.basket tr td {
    background: none repeat scroll 0 0 #eee;
    padding-top: 20px;
    padding-bottom: 20px;
    vertical-align: middle;
}
table.basket tr {
    border-bottom: 2px solid #fff;
}
table.basket tr:last-child {
    border-bottom: 0px;
}
table.basket tr td.product-details {
    padding-left: 10px;
    padding-right: 10px;
}
table.basket tr td.productCount {
    border-right: 1px solid #fff;
}
table.basket tr td.product-name {
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 22px;
}
table.basket tr td.product-name a {
    font-size: 25px;
    display: inline-block;
    margin-bottom: 5px;
}
table.basket tr td.product-name a .productOptionList {
    font-size: 22px;
}
table.basketSummary tr td {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
table.basketSummary .summaryDescription {
    border-right: 1px solid #c9c9c9;
}
table.basketSummary tr.basketTotalSummaryStart td {
    border-top: 1px solid #c9c9c9;
}
table.basketSummary tr.basketTotalSummary td {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
table.basketSummary tr.basketTotalSummary:last-child td {
    border-bottom: 2px solid #2b85ba;
}
.shopWrapper .buttonblock {
    padding-top: 30px;
}
input[type=submit].purpleButton {
    background-color: #00003f;
    border-color: #00003f;
}
table.basket .extraSmallInput {
    border-color: #b1b1b1;
    padding: 6px 10px;
    font-size: 22px;
}
input[type=submit].removeBasketItem {
    border: 0px!important;
    background-color: transparent!important;
    background-image: url('../images/delete.jpg');
    background-position: left center;
    background-repeat: no-repeat;
    color: #2b85ba;
    font-size:17px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    padding: 0px 14px;
    position: absolute;
    right: 14px;
    margin-top: 20px;
}
.shopUserRegister .block input,
.shopUserRegister .block select,
.shopUserRegister .block textarea {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.shopUserRegister .block label {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.leftBasketItem {
    font-size: 22px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.leftBasket strong {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.campaignBox-wimp {
    background-color: #fff;
}
.campaignBox-wimp img {
    margin-bottom: 20px;
}
.campaignBox-wimp h4 {
    font-size: 28px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.wimpLogo {
    display: inline-block;
}
.campaignBox-newsticker h4{
    font-size: 35px;

    font-family: $f-title;
    font-weight: $f-title-bold;
    color: #158aba;
    padding: 10px 15px;
    margin: 0px;
}
.campaignBox-newsticker {
    background-color: white;
    padding: 0px;
    border: 1px solid #aaaaaa;
}
.campaignBox-newsticker ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.campaignBox-newsticker ul li {
    list-style: none;
    padding: 10px 15px;
    margin: 0px;
    color: black;
    font-size: 22px;
    line-height: 22px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.campaignBox-newsticker ul li:nth-child(odd){
    background-color: #f1f1f1;
}
.campaignBox-newsticker ul li a {
    color: black;
}
.campaignBox-newsticker ul li span {
    font-family: $f-running-text;
    font-size: 17px;
    line-height: 17px;
}
.campaignBox-newsticker .newstickerheader {
    padding-top: 5px;
}
.campaignBox-newsticker .tickerHeading {
    display: block;
    font-family: $f-title;
    font-weight: $f-title-bold;
    font-size: 20px;
    line-height: 20px;
}
.shopOpt select {
    width: 100%;
}
.sponsorHolder img{
    max-width: 80px!important;
}
.articleLine img {
    max-width: 100%!important;
}

.userLoginFooter {
    background-color: #2b85ba;
    background-image: url('../images/user.png');
    background-repeat: no-repeat;
    background-position: 10px center;
    border: 1px solid #2b85ba;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: white;
    font-size: 24px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    padding: 4px 10px 4px 50px;
    line-height: 36px;
}
.userLoginFooter:hover {
    background-color: #086283;
    border-color: #086283;
    color: white;
    text-decoration: none;
}
.subjectTopic h1 {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.subjectTopic .date {
    color: #646464;
    font-size: 25px;
    font-size: 18px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}
.subjectTopicPanel {
    border: 0px!important;
    box-shadow: 0px 0px 0px!important;
}
.subjectTopicPanel:hover {
    background-color: #ddd;
}
.subjectTopicPanelActive:hover {
    background-color: transparent;
}
.subjectTopicPanel .panel-heading {
    border: 1px solid #cacaca;
    border-style: solid none none none;
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
}
.subjectTopicPanel .panel-heading .panel-title > a {
    font-size: 35px;
    color: black;
    font-family: $f-title;
    font-weight: $f-title-bold;
    text-transform: uppercase;
    display: block;
    outline: none;
}
.subjectTopicPanelActive .panel-heading .panel-title > a {
    color: #f39200;
}
.subjectTopicPanelActive .panel-heading{
    background-color: #f5f5f5;
    border-style: solid none solid none;
}
.subjectTopicPanel .panel-heading .panel-title > a:hover {
    text-decoration: none;
}
.subjectTopicPanelActive .panel-heading h4 {
    background-image: url('../images/arrow-up.png');
    background-repeat: no-repeat;
    background-position: right center;
}
.subjectTopicPanel .panel-body {
    border: 0px!important;
}
.subjectTopicPanel .panel-body p {
    font-size: 17px;
}
.subjectTopicPanel .panel-body h5 {
    font-size: 22px;
    padding: 0px;
    padding: 0 0 10px 0;
    margin: 0px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.subjectTopicPanel .panel-body .row {
    border-bottom: 1px solid #f8d9aa;
    padding-top: 30px;
}
.subjectTopicPanel .panel-body .row:last-child {
    border-bottom: 0px solid;
}
.subjectTopicPanel .panel-body .row:first-child {
    padding-top: 0px;
}
#subjectTopicCollapse {
    margin-top: 50px;
}

#subjectTopicCollapse ul li, #subjectTopicCollapse ul {
    margin-left: 0px; padding-left: 0px;
}

#subjectTopicCollapse ul li { margin-left: 15px; }

.subjectTopicPanel a:hover {
    text-decoration: none;
}
.articleSubjectTopicFullView h1,
.fullFolderSubjectTopic h1,
.subjectTopic h1 {
    padding-top: 0px;
    margin-top: 0px;
    line-height: 54px;
    font-size: 80px;
    font-size: 60px;
    padding-bottom: 14px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}
.articleSubjectTopicFullView .articleDate,
.fullFolderSubjectTopic .articleDate {
    background-color: transparent;
    color: #646464;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    padding: 0px;
}
.fullFolderSubjectTopic .articleDate  {
    padding-bottom: 20px;
}
.articleSubjectTopicFullView .articleIntro {
    color: #2b85ba;
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 25px;
}
.fullFolderSubjectTopic  .footerList li a  {
    color: #2b85ba;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    line-height: 30px;
}
.fullFolderSubjectTopic  .footerList {
    margin-top: 40px;
}
.alphabetLetter {
    font-size: 25px;
    color: #2b85ba;
    font-family: $f-title;
    font-weight: $f-title-normal;
    cursor: pointer;
    display:inline-block;
    padding-right: 8px;
}
.alphabetWord h3 {
    font-size: 35px;
    color: #2b85ba;
    font-family: $f-title;
    font-weight: $f-title-normal;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.alphabetWord p {
    font-size: 25px;
    color: #000;
    padding: 5px 0px 0px 0px;
    margin: 0px;
    font-family: $f-title;
    font-weight: $f-title-normal;

}

.alphabetWord ul {
    margin-top: 15px;
}

.alphabetWord ul li{
    line-height: 1.3em;
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-normal;
}

.alphabetWord {
    margin-bottom: 30px;
}
.subjectTopicFeedback h3 {
    font-size: 25px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    text-transform: uppercase;
}
.subjectTopicFeedback p {
    font-size: 17px;
}
.subjectTopicFeedback .block label {
    font-size: 22px;
    line-height: 10px;
    font-family: $f-title;
    font-weight: $f-title-normal;
    width: 100%;
}
.subjectTopicFeedback .block {
    margin-bottom: 0px;
}
.subjectTopicFeedback .feedback-collector-textarea {
    margin-top: 10px;
}
.subjectTopicFeedback .block input[type=text],
.subjectTopicFeedback .block textarea {
    width: 100%!important;
    min-width: 100%!important;
    background-color: white;
}
.subjectTopicFeedback .buttonblock {
    margin-top: 10px;
    text-align: right;
}
#kvmMenu .subMenuSubjectTopic li.activeSub {
    font-family: $f-title;
    font-weight: $f-title-bold;
}
#basketMenu .imageLink {
    background-image: url('../images/cart-icon.png');
    background-repeat: no-repeat;
    display: block;
    height: 49px;
    width: 45px;
    background-position: center center;
}
#basketMenu:hover .imageLink {
    background-image: url('../images/cart-icon-hover.png');
}
#userMenuDesktop {
    background-image: url('../images/user-icon.png');
    background-repeat: no-repeat;
    display: block;
    height: 49px;
    width: 65px;
    background-position: center center;
}
#userMenuLi:hover #userMenuDesktop {
    background-image: url('../images/user-icon-hover.png');
}
.campaignBox,
.campaignBox-transport {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}
.campaignBox-transport h2 {
    margin-top: 0px!important;
    padding-top: 10px!important;
}
.articleFullView h1 {
    padding-bottom: 0px;
}
.employee .text span {
    margin-bottom: 10px;
    display: block;
}
.publicationFullView .row {
    margin-top: 15px;
}
.feedbackcollector-form {
    margin-top: 30px;
}
.feedbackcollector-form input[type=submit] {
    margin-left: 135px;
}
table.basket strong {
    font-weight: normal;
}
.shopUserRegister .buttonblock {
    text-align: right;
}
.newsBoxImage {
    height: 200px; overflow: hidden;
}
.newsBoxBig .newsBoxImage {
    height: 420px;
}
.col-md-4 .newsBoxImage {
    /* XXXX - CHristian ønsket denne fjernet 23.12-13 */
    /*height: 150px!important;*/
}
.jumbotron-calendar .col-md-9 {
    margin-bottom: 0px;
}
.partnerLine .col-md-8 {
    margin-bottom: 0px;
}
.articleLine h2 {
    padding-top: 0px;
    margin-top: 0px;
}
.articleLine {
     border-top: 1px solid #C2C2C2;
    margin-top: 20px;
    padding-top: 20px;
}
.ezcca-edit-belongs_to {
    display: none;
}
.newsBox p {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.newsBox p a {
    color: black;
}
.articleReadMore {
    font-size: 17px;
    color: #428BCA!important;
}
b, strong {
    font-weight: normal;
}
div.tagssuggest ul li {
    padding: 0px 6px;
    line-height: 25px;
}
table.special table.list {
    width: 80%;
}
table.special table.list .box,
.ezcca-product_options {
    background-color: white!important;
    border: 1px solid #336EA9!important;
    border-radius: 3px!important;
}
.nav .open > a,
.navbar-nav li.active a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  color: #2b85ba;
}
.nav .dropdown:hover a {
    color: #2b85ba;
}
.nav .dropdown:hover ul li a {
    color: black;
}

.articleSubjectTopicFullView .articleBody h3,
.articleSubjectTopicFullView .articleBody h4,
.articleSubjectTopicFullView .articleBody h5 {
    color: #2b85ba;
    font-family: $f-title;
    font-weight: $f-title-normal;
    font-size: 25px;
}
.contactList li.extraSmall {
    font-size: 14px;
}
.panel-body ul li {
    font-size: 16px;
    padding: 0px 0px 10px 0px;
}
.content-view-embeddedmedia img {
    padding-bottom: 10px;
}
.content-view-embeddedmedia em {
    display: inline-block;
    width: 100%;
}
.block input[type="text"].ezcca-image_download_link,
.block input[type="text"].ezcca-image_download_link {
    border: 1px solid #336EA9;
    border-radius: 3px;
    color: #000000;
    font-size: 18px;
    background-color: #fff;
    padding: 0 10px;
}
.downloadImage {
        display: block;
    font-size: 14px;
    margin-top: -4px;
    text-align: right;
}

.body-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed; z-index: 999;
    display: none;
}
.body-overlay.visible { display: block; }

.button--secondary {
    color: #565656 !important;
    background-color: #E4E4E4 !important;
    border: 1px solid #E4E4E4 !important;
}
.button--secondary:hover {
    background-color: #C2C2C2 !important;
}
.button--alert {
    color: #FFFFFF !important;
    background-color: #CC5555 !important;
    border: 1px solid #CC5555 !important;
}
.button--alert:hover {
    background-color: #BB3333 !important;
}
th.left, td.left {
    text-align: left;
}
th.center, td.center {
    text-align: center;
}
th.right, td.right {
    text-align: right;
}
em.orders-tab {
    display: block;
    margin-bottom: 5px;
    font-size: 16px !important;
}
.datepicker.datepicker-dropdown {
    z-index: 99999 !important;
}

.form-con {
    margin: 20px 0;
}

.form-con.subjects label {
    display: block;
}

.form-con.subjects #subject {
    min-width: 50%;
}

.form-con.members h4{
    font-size: 24px;
    color: #2b85ba;
}

.status {
    background-color: #2b85ba;
    border: 1px solid #c2c2c2;
    padding: 25px;
    margin: 20px 0;
    color: #ffffff;
}

/* Meta */
.meta {
    margin-top: 20px;
}

.meta .meta-box {
    margin-top: 5px;
}

/* TIMESHEETS */
.timesheets-container {
    margin-bottom: 20px;
}

.export-error {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px 15px;
    font-size: 18px;
}

.timesheet-table-row.edit td {
    padding-top: 5px;
    padding-bottom: 5px;
}
.timesheet-table-row.edit td.small {
    width: 15%;
}
.rows th.first {
    padding-left: 0px;
}
.rows td.first {
    padding-left: 0px;
}
.rows td.last, .rows th.last {
    text-align: right;
}
.timesheet-table-row.edit td input.button {
    padding: 0px 18px;
    font-size: 18px;
}
.timesheet-table-row.edit td .cancel-button {
    background-color: #888;
    border: 1px solid #888;
}
.timesheet-table-row.edit td .cancel-button:hover,
.timesheet-table-row.edit td .cancel-button:focus {
    background-color: #666;
    border-color: #666;
}
.timesheet-row {
    display: block;
    padding: 20px 30px;
}
.timesheet-row:nth-child(even) {
    background-color: #f2f2f2;
}
.timesheet-row h2 {
    margin: 0 0 20px;
}
.timesheet-row .actions {
    padding-top: 20px;
}
.rows table td {
    background: #fff;
}
.timesheet-row:nth-child(even) .rows table td {
    background-color: #f2f2f2 !important;
}
.timesheets {
    padding: 0;
    border: 1px solid #ddd;
}
.actions {
    padding-top: 20px;
}
.align-right {
    text-align: right;
}
[data-activity-color="green"] {
  background: #23a455; }
  [data-activity-color="green"].active {
    box-shadow: 0 0 10px 5px #7de3a4; }

[data-activity-color="blue"] {
  background: #2d8dd6; }
  [data-activity-color="blue"].active {
    box-shadow: 0 0 10px 5px #add2ef; }

[data-activity-color="purple"] {
  background: #904fad; }
  [data-activity-color="purple"].active {
    box-shadow: 0 0 10px 5px #d2b7de; }

[data-activity-color="red"] {
  background: #e44235; }

[data-activity-color="grey"] {
  background: grey; }

.activity-settings {
    background: #fafafa;
    margin-top: 40px;
    padding: 20px;
}
    .activity-settings .form-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    .activity-settings .form-block:hover {
        background: #eee;
    }
    .activity-settings .form-actions {
        margin-top: 20px;
    }
    .activity-settings .form-titles {
        border-bottom: 1px solid #eee;
        display: flex;
        padding-bottom: 15px;
        justify-content: space-between;
    }
        .activity-settings .title {
            text-align: center;
            padding: 0 15px;
        }
        .activity-settings .sort-handle {
            cursor: move;
            padding: 0 10px;
        }
        .activity-settings .disabled .sort-handle {
            color: #ccc;
            cursor: default;
        }
        .activity-settings .disabled .text input {
            color: #ccc;
        }

.settings.button {
    float: right;
}

.timesheet_hover {
    background-color: #b94a48!important;

    td {
        background: none!important;
    }
}

//
// * -------------------------------------------------
// *  CSS from timesheet/overview.css
// * -------------------------------------------------
//



.ts-row.unlocked td {
    background-color: #eee;
    color: #999;
}
.ts-row.completed td {
    background-color: #cbff93;
}
.ts-row:hover td {
    background-color: #f9ffb7;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 20px;
    bottom: 20px;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    max-width: 100%;

    &.move-employee-box.in {
        h2 {
            margin-top: 0;
        }

        h3 {
            font-size: 18px;
            margin-top: 0;
            color: #f39200;
            a {
                color: #f39200;
                margin-left: 10px;
            }
        }

        div.attribute-short
        {
            margin-top: 20px;
        }

        // Need to override inline css by script.
        display: flex !important;
        align-items: center;
        .error, .error i, .error a  {
            color: #ff0000;
        }

        .error a
        {
            margin-left: 0;
            margin-right: 5px;
        }

        .no-access {
            margin-top: 20px;
        }

        label {
            text-indent: 0;
        }

        input[name="SearchCompany"] {
            width: calc(100% - 50px);
            min-width: initial;
        }

        .company-list {
            label {
                margin: 5px 0 5px 0;
                font-weight: 400;
            }

            td {
                margin: auto;
                vertical-align: middle;
            }

            a ~ i.fa {
                padding-left: 10px;
            }

            tr.selected {
                background-color: #f39200;
                color: #ffffff;
                label, a {
                    color: #ffffff;
                }
            }

            tr.disabled {
                color: #aeaeae;
                label, i {
                    color: #aeaeae;
                }
            }

            .loading.show {
                display: flex !important;
                justify-content: right;
                padding-right: 20px;
                font-size: 14px;
                color: #e67036;
                position: absolute;
                background-color: #ffffffcc;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            margin-top: 20px;
            position: relative;
            max-height: 35vh;
            min-height: 200px;
            overflow-y: scroll;
        }
    }
}



.inner-modal-wrapper {
    background-color: #fff;
    padding: 20px;
}
.inner-modal-wrapper h2 {
    margin-top: 0;
}

.timesheet-modal button.close {
    margin-bottom: 30px;
}
.timesheet-modal input[type="submit"] {
    margin-top: 20px;
}
.filter-options {
    background-color: #F1F1F1;
    padding: 20px;
}

.filter-options h2 {
    margin: 0 0 20px;
}

.filter-options .filter {
    display: flex;
    flex-flow: row nowrap;
    padding: 5px;
}

.filter-options .filter.filter--button {
    padding-top: 0;
}

.filter-buttons {
    margin-left: auto;
}

.timesheets-container table thead td.center,
.timesheets-container table tbody td.center {
    text-align: center;
}
.timesheets-container table thead th {
    font-size: 18px;
}
.timesheets-container table tbody td {
    font-size: 18px;
    font-family: $f-running-text;
}
.timesheets-container table tbody td .complete-timesheet {
    visibility: hidden;
}
.timesheets-container table tbody tr:hover td .complete-timesheet {
    visibility: visible;
}
.timesheets-container table tbody tr td .complete-timesheet.complete {
    visibility: visible;
}

.delete-row {
    cursor: pointer;
    position: relative;
    top: 5px;
    left: 5px;
}

//
// * -------------------------------------------------
// *  CSS from timesheet/edit.css
// * -------------------------------------------------
//
#timesheetTable-edit {}
    #timesheetTable-add td,
    #timesheetTable-edit td {
        position: relative;
        padding: 5px;
    }

/* Date box */
.date-box {
    display: inline-block;
}
    .date-box .date-trigger {
        position: relative;
        top: 6px;
    }
    .date-box input[type='date'] {
        top: 6px;
        margin-left: 28px;
        z-index: 1;
        transition: .3s;
    }

.timesheet-table-row {}
    .timesheet-table-row td {
        padding-left: 4px;
        padding-right: 4px;
        transition: .3s;
    }

.date-box.open {}
    .date-box.open .date-trigger {}
    .date-box.open input[type='date'] {
        width: 208px;
        opacity: 1;
    }

//
// * -------------------------------------------------
// *  CSS from timesheet/manage.css
// * -------------------------------------------------
//
.filter.filter--date {
    display: none;
}

.filter.filter--date.show {
    display: block;
}

.filter-options .title-wrapper {
    display: inline-block;
}

.filter-options .title-wrapper h2 {
    line-height: 1.4em;
}

.filter-options .show-button {
    display: inline-block;
    float: right;
}

.filter-options .show-button button {
    font-size: 22px;
    padding: 6px 18px;
    line-height: 1em;
}

.filter-options--collapsed h2 {
    margin: 0;
}

.filter-options--collapsed #timesheetsForm {
    display: none;
}

h3.employee-name {
    font-size: 32px !important;
}

.timesheets-not-found {
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    color: #ffffff;
    background-color: #404040;
}

.timesheet-manage-list-actions {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.timesheet-manage-list-actions input[type="submit"], .timesheet-manage-list-actions select {
    padding: 4px 10px;
    font-size: 18px;
    line-height: 1em;
}

.button.button--checkbox {
    padding: 4px 10px;
    font-size: 18px;
    line-height: 1em;
    color: #333;
    background-color: #f2f2f2;
    border: 1px solid #333;
}

.employee-all-checkbox-toggle input[type="checkbox"] {
    position: relative;
    top: -2px;
}

.timesheet-list-action-disclaimer {
    display: none;
    color: #404040;
    margin-top: 20px;
}

.timesheet-list-action-disclaimer p {
    font-style: italic;
    margin: 0;
}

.timesheet-list-action-disclaimer .material-icons {
    color: #2b85ba;
    vertical-align: sub;
}

//
// * -------------------------------------------------
// *  CSS from timesheet/icon-selector.css
// * -------------------------------------------------
//
.icon-selector .selected-icon {
  cursor: pointer;
  padding: 10px; }
.icon-selector .icon {
  font-size: 35px;
  line-height: 1; }
.icon-selector .icon-list {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: none;
  padding: 10px;
  list-style: none;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  overflow-x: auto; }
  .icon-selector .icon-list.visible {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .icon-selector .icon-list li:first-child {
    margin-top: 0; }
  .icon-selector .icon-list .icon {
    cursor: pointer; }
    .icon-selector .icon-list .icon:hover {
      transform: scale(1.5); }

//
// * -------------------------------------------------
// *  CSS from timesheet/color-selector.css
// * -------------------------------------------------
//
.color-selector .icon {
    border-radius: 100%;
    display: block;
    width: 20px;
    height: 20px;
}



//
// * -------------------------------------------------
// *  CSS from lars.css
// * -------------------------------------------------
//
.editline {
    background-color: #333333;
    padding: 5px;
    color: #fff;
}

.allnews {
    padding: 20px 0;
    font-size: 20px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}

.allnews img {
    position: relative;
    top: -1px;
}

.editline a {
    color: #fff;
    font-size: 0.8em;
}

.headerrow {
    margin-top: 20px;
    font-size: 0.85em;
}

.headerrow div span {
    font-weight: bold;
}

.row.dark {
    background-color: #ececec;
}

.row.dark, .row.light {
    font-size: 0.9em;
}

.row textarea {
    max-width: 100%;
    width: 100%;
}

.row input.box {
    max-width: 100%;
}

.row input.fillbox {
    width: 100%;
}

.row input.halfbox {
    width: 50%;
}

.phonebox {
    width: 100px;
    clear: both;
}

row input.twonumbers {
    width: 50px;
}

.infolabel {
    margin: 30px 0;
}

.label-info {
    padding: 5px;
    font-size: 0.9em;
    border: 1px solid #333333;
    margin: 20px 0 30px 0;

}

.topmargin {
    margin-top: 40px;
}

.botmargin {
    margin-bottom: 40px;
}

.smalltopmargin {
    margin-top: 10px;
}

.validation_red {
    color:red;
}

.hidden_zip {
    display: none;
}

.msgBox-error ul.error-list,
.msgBox-error ul.error-list a
{
    color: #ff0000;
}

.editwrapper {
    margin-top: 15px;
    background-color: #ececec;
    font-size: 15px;
    font-family: $f-running-text;
}

.editwrapper span.required{
    color: #ff0000;
    font-weight: 700;
    font-size: 20px;
}

.editwrapper .row {
    margin-bottom: 0px;
}

.editwrapper .row .col-md-12 strong {
    display: inline-block;
    text-indent: 6px;
}

#id_author table th {
    font-size: 14px;
}

#editform .attribute-header h3 {
    font-size: 25px;
}

#editform .attribute-header h3 em {
    font-style: italic;
    text-decoration: underline;
    display: inline-block;
}

body .altcaption {
    font-size: 16px;
    line-height: 16px;
    font-style: italic;
    display: block;
    clear: both;
    padding-bottom: 10px;
}
#editform .tagssuggest p {

}

#id_author  input[type="submit"], #id_author input[type="button"] {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 22px;
    padding: 4px 10px;
    margin-top: 5px;
}

.editwrapper .col-md-12 {
    margin-bottom: 0px;
}

.editselectors {
    margin-top: 20px;
    margin-bottom: 10px;
}

.editselectors a {
    text-transform: uppercase;
    color: #333333;
}

.editselectors .selected {
    font-weight: bold;
    text-decoration: underline;
}

.row label {
    text-indent: 5px;
}

.datatype-image {
    font-size: 0.85em;
}

span.car_reg_msg {
    color: red;
    fon-size: 0.85em;
}

.label-info {
    padding: 5px;
    line-height: 1.5;
}


.datatype-image input[type=submit] {
    background-color: #2b85ba;
    border: 1px solid #2b85ba;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-family: $f-running-text;
    padding: 4px;
    line-height: 20px;
    margin-bottom: 25px;
}

.row.certificates {
    display: none;
}


.content-view-line .class-nlf-article {
    margin: 20px 0;
}

.content-view-line .class-nlf-article h2 {
    margin-top: 0px;
}


h2.blue {
    color: #2b85ba;
}

.subjectbox a.blue {
    color: #2b85ba;
    font-size: 12px;
    margin-left: 15px;
    line-height: 1.5em;
    text-decoration: underline;
}

.subjectbox a.blue:hover {
    text-decoration: none;
}

h2.black a {
    color: #333333;
}

.extraSubMenu {
    margin-top: 30px;
}

#EmbedForm #tabs {
    display: none;
}


.subMenu li.nohover:hover {
    /*color: #333333;*/
}

.lineHeightNormal {
    line-height: normal;
}

.leftmenuwrapper {
    margin-top: 20px;
}

.selectiontype ul {
    /*list-style-type: lower-alpha;*/
}

.selectiontype span {
    display: block;
}

.selectiontype a {
    text-decoration: underline;
}

.membershow table {
    font-size: 0.8em;
}

.membershow {
    div.memberinfo a.button {
        color: #ffffff;
        margin: 10px 5px 0 5px;
    }

    a.external-link {
        margin-left: 0;
        font-size: 15px;
        vertical-align: top;
    }
}

.membershow a {
    color: #333333;
    margin-left: 20px;

    i.fa-external-link
    {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.membershow table a {
    margin-left: 0px;
}

table.list.smallerth th {
    font-size: 13px;
    font-family: 'Open Sans';
}

table.list.smallerth2 th {
    font-size: 14px;
    font-family: 'Open Sans';
}

table.list.smallertd td {
    font-size: 13px;
    font-family: 'Open Sans';
}

table.list.smallertd2 td {
    font-size: 14px;
    font-family: 'Open Sans';
}
table.list.smallertd3 td {
    font-size: 13px;
    font-family: 'Open Sans';
    padding-top: 3px;
    padding-bottom: 3px;
}

table.list.normal tr td {
    font-size: 20px;
}

table.list.smallertable td {

}

table.list.addpad td {
    padding: 5px !important;

}

.smaller {
    font-size: 0.7em;
}

table.list.border {
    border: 1px solid #ddd;
}

.tab-content {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.nav-tabs {
    margin-bottom: 0;

    &.membertabs {
        li.active > a, li.active > a:hover {
            border-bottom: 1px solid #ffffff;
        }
    }
}

.smallem {
    font-size: 0.8em;
}

.deviation_edit-icons {
    font-size: 24px;
}

#accordion-button {
    font-family: $f-running-text;
    cursor: pointer;
}

#caret_icon {
    transition: .35s;
}

.rotate {
    transform: rotate(180deg);
}

body ul.nolisttype,
body ul.nolisttype li {
    list-style-type: none;
}

.add_verv {
    font-size: 0.8em;
    font-weight: bold;
    font-family: $f-running-text;
    margin-left: 0px;
}

.add_verv img {
    position: relative;
    top: -2px;
    margin-right: 5px;
}

ul li.indented {
    padding-left: 20px;
    font-size: 0.93em;
}

ul li.givespace{
    padding: 5px;
}

.register_note {
    display: none;
    padding: 20px 10px;
}

.register_note .button {
    margin-top: 10px;
}

a.typicallink {
    color: #2b85ba;
    text-decoration: underline;
    font-size: 0.8em;
}

.calculus-box {
    background-color: #D2D2D2;
    padding: 10px;
    margin: 20px 0;
}

.calculus-box select {
    clear: both;
}

table.calculatefeelist tr {
    background-color: #D2D2D2;
}

table.whitetbl tr {
    background-color: #fff;
}

table.memberfee tr td {
    font-size: 0.85em;
}

.leftmenu-info {
    margin-top: 35px;
    font-size: 0.8em;
}

.membertabs {
    font-size: 0.9em;
}

.nav.membertabs li  a {
    padding: 10px 10px;
}

.choicebox {
    padding-bottom: 5px;
}

.ui-autocomplete {
    z-index: 100;
}

#findMember, #findDeviation {
    line-height: auto;
    min-width: calc(100% - 37px);
}

div#id_metadata {
    display: none;
}

.oe-window table tr td:first-child {
    padding-left: 0px;
}

.scaleup {
    width: 100%;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
    margin: 20px 0;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.sepline {
    height: 1px;
    font-size: 1px;
    line-height: 1px;
    background-color: #333333;
    margin: 15px 0 15px 0;
}

table tr.trblur td {
    text-decoration: line-through;
    background-color: #A0A0A0;
}

table tr.trblur, table tr.trblur:hover, table tr.trblur:hover td, table tr.trblur td:hover {
    background-color: #A0A0A0;
}

table.smallinvoicetbl, table.smallinvoicetbl th, table.smallinvoicetbl td {
    font-size: 12px;
}

.toomuch {
    font-weight: bold;
}

.reskontrotbl th, .reskontrotbl td {
    text-align: center;
}

.reskontrotbl .openinvoice td {
    color: red;
}

.reskontrotbl .openinvoice td a {
    color: red;
    text-decoration: underline;
}

.reskontrotbl .openinvoice td a:hover {
    text-decoration: none;
}

.member-address-info {
    margin-top: 30px;
}

.member-address-info div {
    margin-bottom: 5px;
    line-height: normal;
}

#company .col-md-1, #company .col-md-2, #company .col-md-3, #company .col-md-4, #company .col-md-5, #company .col-md-6, #company .col-md-7, #company .col-md-8, #company .col-md-9, #company .col-md-10, #company .col-md-11, #company .col-md-12,
#member .col-md-1, #member .col-md-2, #member .col-md-3, #member .col-md-4, #member .col-md-5, #member .col-md-6, #member .col-md-7, #member .col-md-8, #member .col-md-9, #member .col-md-10, #member .col-md-11, #member .col-md-12 {
    margin-bottom: 0px;
}

div.pull-up {
    margin-top: -15px;
    display: inline-block;
}

#ezwt-help {
    font-family: $f-running-text;
    min-width: 300px;
    text-align: right;
}

div#ezwt-help a {
    display: inline;
}

.nopadding {
    padding: 0;
}

.nobg {
    background: none;
}

b, strong {
    font-weight: bold;
}

.advanced_member, .notat_search, .normal_search { float: right; font-size: 15px; text-align: right; line-height: 1.3em; margin-top: 10px;  }

#wordlist_search { height: 40px; margin: 20px 0 20px 0; }

@media print {
    #mobilebottommenu, #fb-root, .hackedHeader, #toolbar, #header, .jumbotron-footer, ul.subMenu, ul.breadcrumb, input[type=button], input[type=submit], button { display: none !important; }
    body .container, body .container p { line-height: 1.2em; font-size: 0.8em; }
    h1,h2,h3,h4,h5,h6 { margin-top: 5px; }

    .hideprint { display: none; }

    body { padding-top: 0px !important; }

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .row,
    .container,
    body .jumbotron {
    margin: 0;
    padding: 0;
    }

    body {
        font-size: 0.8em;
        margin: 0px;
        padding: 0px;
    }

    a:link:after, a:visited:after {
        content: "";
    }

    .topmargin {
        margin-top: 15px;
    }

    .list.printlist td {
        padding: 5px 5px 5px 0px;
    }

    .list.printlist td img {
        max-width: 400px !important;
    }

}


#mobilemenu { display: none; }

#mobilemenu div.mobilemenusep { display: none; height: 1px; font-size: 1px; line-height: 1px; border-top: 3px solid #BEBEBE; background-color: #fff; padding: 0; margin-top: 2px; }

.mobile { display: none; }

.moreAboute { font-size: 19px; line-height: 1.6em; }
.moreAboute strong { font-size: 18px;  }

.newsBoxImage.articleline {
    height: 150px;
    margin-top: 30px;
}

.newsBox h2 { margin-bottom: 12px; margin-top: 6px; }

@media (max-width: 767px) {
    .col-md-12 { padding-left: 0px; padding-right: 0px; }
    .col-xs-12.col-md-12 { padding-left: 15px; padding-right: 15px; }
    .col-xs-12.col-md-8 { padding-left: 0px; padding-right: 0px; }
    .col-xs-12.col-md-4 { padding-left: 0px; padding-right: 0px; }
    .col-sm-12.col-md-6 { padding-left: 0px; padding-right: 0px; }

    .newsBoxBig h2 { font-size: 40px; line-height: 1.3em; }
    .newsBoxBig p { font-size: 20px; line-height: 1.3em; }
    .newsBox h2 { font-size: 30px; line-height: 35px; }
    .newsBox p { line-height: 1.3em; }

    .mobile { display: block; }

    #mobilebottommenu { padding-left: 0px; padding-right: 0px; }
    #mobilebottommenu .visible-xs { display: block; }
    #mobilebottommenu .subMenu { display: block; }
    #mobilebottommenu .subMenu li { padding-left: 5px; padding-right: 0px; }

    .articleFullView h1 { line-height: 1.2em !important; }

    .jumbotron h3 {
        color: #2b85ba;
        font-size: 30px;
    }

    .moreAboute {
        border-left: none;
        padding-left: 0px;
    }

    .moreaboute, .jumbotron { font-size: 17px; }

    .jumbotron-breadcrumbs { display: none; }

    .logoimgmob { width: 35px; margin: 5px 0px 5px 0px; }
}

@media (min-width: 770px) {
    #mobilemenu { display: none; }
}

.feedbackcollector-form input[type="submit"] {
    margin-left: 0px;
}

input#findMember,input#findMemberAdvanced, input#findNotat, input#findDeviation { height: 45px; }

@media (min-width: 768px) and (max-width: 1199px) {
    #nav-open-btn, #mobilemenu_link {
        margin-top: 35px;
    }

    .jumbotron h3 {
        color: #2b85ba;
        font-size: 40px;
    }

    .moreaboute, .jumbotron { font-size: 19px; }

}

.articleSubHeading.smallbotpadding {
    margin-bottom: 10px;
}

.articleAuthor { font-size: 0.85em; margin-bottom: 20px; }

.expand_cert { cursor: pointer; }

/*
- - - - - - - - - - - - - - - - - - - - - - -
Custom select as boostrap component for Trainor */

select.as_bootstrap {
    display: none;
}

div.boostrapselect {
    width: 100%;
}

div.boostrapselect .btn {
    width: 100%;
    background-color: #fff;
    color: #000;
    font-weight: 400;
    text-align: left;
}

div.boostrapselect .caret {
    position: absolute;
    right: 10px;
    top: 20px;
}

div.boostrapselect li {
    position: relative;
}

div.boostrapselect ul {
    width: 100%;
    background-color: #eaeff5;
}

div.boostrapselect input[type=checkbox] {
    margin-right: 10px;
    position: absolute;
    top: 5px;
    left: 8px;
}

div.boostrapselect.multipleselect li {
    padding: 0px;
}

div.boostrapselect.multipleselect  a {
    text-indent: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

div.boostrapselect a.selected  {
    background-color: #d6e0ea !important;
}

div.multipleselect a.closeselect {
    color: #c51f1f !important;
    font-size: 0.9em;
    padding-top: 10px !important;
    text-indent: 0px;
}

div.boostrapselect .btn {
    position: relative;
}

div.boostrapselect .btn:hover {
    color: #000;
}

div.boostrapselect .dropdown-menu {
    /*top: 0px;*/
}

.boostrapselect ul.dropdown-menu li a   {
    font-size: 18px !important;
    text-decoration: none;
}
.loginPage .loginOss {
    clear:none;
    float:right;
}
.loginPage .loginOss a {
    line-height:normal;
    clear:both;
    float:right;
}

.feedback-collector-text {
    font-style: italic;
    font-size: 0.7em;
    line-height: 1.2em;
}

.feedback-collector-text p{
    margin: 2px 0;
}

#feedbackcollector-id label {
    line-height: 1.2em;
    text-indent: 0px;
    margin-bottom: 0px;
    position: relative;
    top: -2px;
    font-size: 0.85em;
}
.fc-radiowrap {
    display: none;
}

#feedbackcollector-id input[type=text], #feedbackcollector-id textarea, #feedbackcollector-id select {

    background-color: #eeeeee !important;
    border: 0 !important;
}

#PrintOneEmployee {
    padding: 3px;
    font-size: 16px;
    line-height: 25px !important;
}

.smallnewsticker h4 {
    font-size: 30px !important;
}

.smallnewsticker .tickerHeading a {
    font-size: 16px !important;
}

.smallnewsticker .tickerSource, .smallnewsticker .tickerDate {
    font-size: 14px !important;
}

.smallnewsticker {
    overflow: hidden;
}

.lastebilmarquee {
  width: 100%;
  overflow: hidden;
}

.lastebilmarquee .dot {
    font-size: 25px !important;
    color: #2b85ba;
    font-family: $f-running-text !important;
}

.lastebilmarquee .item {
    display: inline-block;
    margin-right: 30px;
}

.lastebilmarquee .item a {
    color: #000 !important;
}

.marqueewrap {
  border-top: 1px solid #2b85ba;
  border-bottom: 1px solid #2b85ba;
  font-family: $f-title !important;
  font-weight: $f-title-bold;
}

.marqueewrap span {
    line-height: 1.3em !important;
    font-size: 19px;
}

.marqueewrap .headerx {
    color: #2b85ba; font-weight: bold;
    font-size: 19px;
    position: relative;
    top: 2px;
}

.marqueewrap .siderow {
    margin-bottom: 0px !important;
}


@media (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav > li > a {
        font-size: 17px;
        padding: 10px 14px;
    }
    ul.dropdown-menu li a { font-size: 18px; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-nav > li > a { font-size: 16px; padding: 15px 5px; }
    ul.dropdown-menu li a { font-size: 17px; }
    #userMenuDesktop { width: 50px; }
    .searchMenuLink { width: 40px; }
}

@media (max-width: 767px) {

}

.companyline {
    line-height: 1.3em;
    margin-bottom: 20px;
}

.companyline .name {
    font-weight: bold;
}

#id_redirect_to input[type="button"], #id_redirect_to input[type="submit"] {
    font-size: 14px;
    padding: 5px 8px;
    line-height: 1.1em;
}

.prioritybox {
    display: inline-block;
    min-width: 80px;
    text-align: center;
}

.button.smallbutton {
    font-size: 13px !important;
    padding: 3px 6px !important;
    line-height: 1.2em !important;
}

input[type="text"][disabled],
input[type="text"]:read-only {
    background-color: rgba(255,255,255, 0.3);
}
input[type="submit"][disabled] {
    opacity: 0.2;
}

table .behandles,
table .behandles:nth-child(even) {
    background: rgba(255,255,0, 0.2);;
}

table .lukket,
table .lukket:nth-child(even) {
    background: rgba(0,255,0, 0.2);;
}

@keyframes shake {
    10%, 90% { transform: translate3d(-1px, 0, 0); }
    20%, 80% { transform: translate3d(2px, 0, 0); }
    30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
    40%, 60% { transform: translate3d(4px, 0, 0); }
}

.invalid {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    border-color: #d00 !important;
}

:root.supermodal,
:root.supermodal body { max-height: 100vh; overflow: hidden; }

:root.supermodal body #car-attachment { max-height: 100vh; overflow: scroll; }

#car-attachment {

    #attachment-list {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;

        .list-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid gainsboro;

            .delete-attachment {
                margin: 1rem;
            }

            .list-item {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                * {
                    padding: 0.5rem;
                }

                .download-text {
                    flex: 1
                }
            }
        }
    }
}

#caredit-2, #car-attachment {
    background: rgba(245, 245, 245, 0.96);
    position: fixed; top: 0; left: 0; z-index: 999;
    width: 100vw;
    height: 100vh;
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}
    #caredit-2 form, #car-attachment form {
        background: rgba(255,255,255, 0.8);
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 40px 50px;
        position: relative;
    }
        #regcar-step-cancel, #car-attachment-step-cancel {
            position: absolute; top: 2rem; right: 2rem; z-index: 9999;
            color: #ccc;
            border-radius: 100%;
            border: 1px solid #ccc;
            width: 3rem;
            height: 3rem;
            text-align: center;
            line-height: 2.5rem;
        }
            #regcar-step-cancel:hover, #car-attachment-step-cancel:hover {
                background: #2b85ba;
                color: #fff;
                text-decoration: none;
            }
    #caredit-2 select, #car-attachment select {
        border: 1px solid #ccc;
        height: 2.5em;
        margin-bottom: 1.5em;
        font-size: 1em;
        width: 100%;
    }
    #caredit-2 .button, #car-attachment .button {
        font-size: 1.2em;
        padding: 0 0.9em;
        line-height: 2;
    }
    #caredit-2 .form-nav, #car-attachment .form-nav {
        text-align: center;
    }
        #caredit-2 .form-nav span, #car-attachment .form-nav span {
            line-height: 40px;
        }
    #caredit-2 .button:hover { background-color: #4695b3; text-decoration: none; }
    #caredit-2 .prev { float: left; }
    #caredit-2 .next,
    #caredit-2 .complete, #car-attachment .complete {
        float: right;
    }
    #caredit-2 .fieldset[data-step="1"] { display: block; }
    #caredit-2 .fieldset[data-step="3"] {
        max-width: 600px;
    }
    .flex {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        align-items: flex-end;
        justify-content: space-between;

        .form-container {
            width: 45%;
        }
    }
    #caredit-2 .fieldset[data-step="4"] {
        max-width: 900px;
    }
    #caredit-2 .form-container {
        margin-bottom: 1.875em;
    }
    #caredit-2 .form-container.invalid { color: #d00; }
    #caredit-2 .form-container p.error { margin-top: 10px; color: #d00; font-weight: bold; }
    #caredit-2 .form-container p.erroras { margin-top: 10px; color: #d00; font-weight: bold; }
    #caredit-2 .fieldset > p.invalid { color: #d00; }
    #caredit-2 .check-group {
        display: flex;
        flex-wrap: wrap;
    }
    #caredit-2 .check-group div {
        flex: 0 0 50%;
        min-width: 40px;
    }
    @media screen and (min-width: 1100px) {
        #caredit-2 .check-group div {
            flex: 0 0 33.3333%;
        }
    }
    @media screen and (min-width: 768px) {
        #caredit-2 .fieldset[data-step="4"] .check-group.last {
            margin-bottom: 3rem;
        }
    }
    @media screen and (max-width: 767px) {
        #caredit-2, #cat-attachment {
            background: rgba(245,245,245, 1);
            padding: 0;
            height: calc(100vh - 70px);
            top: 70px;
            align-items: inherit;
        }
        #caredit-2 form, #car-attachment form {
            border: none;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
        }
        #caredit-2 .fieldset, #car-attachment .fieldset  {
            position: relative;
            flex: 1;
            flex-direction: column;
            overflow-y: auto;
            padding: 40px 20px 80px;
        }
        #caredit-2 .form-nav, #car-attachment .form-nav {
            background: #404040;
            position: fixed; bottom: 0; left: 0;
            width: 100%;
            padding: 9.5px 2rem;
            color: #fff;
        }
        #caredit-2 label {
            font-weight: normal;
        }
        #caredit-2 p {
            font-weight: bold;
        }
        #caredit-2 p span {
            font-weight: normal;
        }
    }
    @media screen and (max-width: 640px) {
        #caredit-2, #car-attachment {
            height: calc(100vh - 59px);
            top: 59px;
        }
    }
    @media screen and (max-width: 566px) {
        #caredit-2 .check-group div {
            flex: 0 0 100%;
        }
    }

.nowrap {
    white-space: nowrap;
}
.textright {
    text-align: right;
}
table tr td.numeric {
    white-space: nowrap;
    text-align: right;
    padding: 0 6px;
}
table tr td.noleft {
    padding: 0 6px;
}

.body--coursemodule {
    .jumbotron-search {
        display: none;
    }
}

.course #navigation {
    margin-top: 11px;
}
.course .hackedHeader {
    height: 39px;
}
.course .navbar-right { display: none; }

.coursemodule iframe {
    border: none;
    height: calc(100vh - 96px);
}

.coursemodule-line {
    border-radius: 2px;
    background: #ededed;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    -webkit-transition: all 0.2s; /* Safari */
    transition: all 0.2s;
}

.coursemodule-line:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.coursemodule-line h3 {
    font-size: 2rem;
    margin: 0;
    padding: 20px 0;
}

.coursemodule-line a:hover, .coursemodule-line a:focus, .coursemodule-line a:active {
    text-decoration: none;
}

.coursemodule-image {
    display: block;
    position: relative;
    padding-bottom: 60%;
    background-color: rgba(0,0,0,0.2);

    background-position: center center;
    background-size: cover;
}

.coursemodule-indicator {
    display: block;
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background: #7f8c8d;
    bottom: -15px;
    right: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.coursemodule-indicator.completed {
    background: #27ae60;
}

.coursemodule-indicator.started {
    background: #f39c12;
}

.course-button {
    display: inline-block;
    text-align: center;
    position: relative;
    margin: 7px 0;
    margin-right: 15px;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px;
    font-family: $f-title;
    font-weight: $f-title-bold;
    background: #2b85ba;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    -webkit-transition: all 0.2s; /* Safari */
    transition: all 0.2s;
}

.course-button:hover {
    background: #086283;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.course-button:active, .course-button:focus {
    color: #fff;
}

.course-button.active {
    background: #08a81b;
}

.course-button.active:hover {
    cursor: default;
}

.course-nav  {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
}
.course-nav .course-button {
    float: right;
    margin-right: 0;
    margin-top: 5px;
}

/* Mathias */

#company-logo {
    margin-top: 32px;
}

#kvmMenu .subMenu > li {
    padding-left: 3px;
}

#kvmMenu .subMenu > ul {
    margin-bottom: 1rem;
}

#course_list {
    width: 100%;
    margin-bottom: 2rem;
}

#lrs_admin_content table button {
    width: 100%;
}

#lrs_admin_content form {
    margin: 0;
}

.live-stream-frontpage h2 {
    text-align: center;
    font-size: 35px;
    font-family: $f-title;
    font-weight: $f-title-bold;
}

.video-thumbnail {
    background: grey;
    padding-bottom: 56.25%;
}


.livestream iframe {
    position: absolute;
    height: 100%;
    width: 100%;
}

.video-live-icon {
    padding: 0.5rem 2rem;
    background: #2b85ba;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    font-family: $f-running-text;
    font-size: 2rem;
    position: absolute;
    top: 10px; left: 10px;
    z-index: 1000;
}

.video-wrap #description {
    font-size: 1.7rem;
    margin-bottom: 3rem;
}

.livestream-header {
    margin-top: -0.5rem;
    margin-bottom: 2rem;
}

li.mayHaveDropwdown:hover a {
    color: #2b85ba;
}

.text-align-center {
    text-align: center;
}
.sort-order {
    white-space: nowrap;
    position: relative;
    padding-right: 15px;
    .order-icons {
        position: absolute;
        right: -5px;
        top: 5px;
        font-size: 20px;
        &.hover-icon {
            display: none;
        }

    }

    &:hover {
        .order-icons.hover-icon {
            display: inline-block;
        }
        .order-icons.active-icon {
            display: none;
        }
    }
}

//
// * -------------------------------------------------
// *  CSS from lastebil_360.css
// * -------------------------------------------------
//
@media (max-width: 640px) {
    .newsBoxBig .newsBoxImage,
    .newsBox .newsBoxImage {
        height: auto!important;
    }
    #bigHeaderContent{
        display: none!important;
    }
    .loginPage .loginOss {
        margin-top:5px;
    }
    .loginPage .loginOss a {
        font-size:16px;
    }
}
@media (max-width: 360px) {
    .formButtonInfo {
        font-size: 14px;
        line-height: 14px;
        width: 50%;
    }
    .contactList {
        width: 100%;
        padding: 0px 0px 50px 0px;
    }
    .contactList li {
        line-height: 22px;
    }
    .jumbotron {
        padding-left: 10px;
        padding-right: 10px;
    }
    .basketSummary {
        width: 100%;
    }
    .shopbutton {
        width: 100%;
    }
    .buttonblock.pull-right {
        float: none!important;
    }
    .jumbotron h1 {
        font-size: 40px;
    }
    .shopUserRegister .block input[type="text"], .shopUserRegister .block textarea, .shopUserRegister .block input[type="password"], .shopUserRegister .block select {
        width: 100% !important;
    }
    .shopUserRegister .block label {
        display: block;
        float: none;
    }
    .sellerInfo {
        padding-left: 0px;
        margin-left: 0px;
    }
    .small-xs-logo {
        margin-left: 10px;
    }

    /* OVERRIDE FOR NEWS */
    .newsBox h2 {
        font-size: 20px;
    }
    .newsBoxBig h2 {
        font-size: 39px;
    }
    .newsBoxBig p {
        font-size: 17px;
    }
    .jumbotron-footer h5 {
        padding-bottom: 0px;
        font-size: 25px;
    }
    .contactList li {
        font-size: 15px;
    }
    .jumbotron {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .jumbotron-footer {
        background-size: 300px auto;
    }
    .jumbotron h1 {
        padding-bottom: 0px;
    }
    .breadcrumb {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .breadcrumb > li {
        font-size: 14px;
    }
    .newsBoxBig .newsBoxImage,
    .newsBox .newsBoxImage {
        height: auto!important;
    }
    .campaignBox  {
        width: 100%;
        height: auto!important;
    }
    .kmvBox {
        margin-bottom: 0px;
        height: 10px!important;
    }
    .kmvBox h2 {
        margin: 0;
        padding: 0;
        font-size: 20px;
    }
    .kmvBox h2 a {
        font-size: 20px;
    }
    .loginPage .buttonblock input {
        font-size: 20px;
    }
    .alert {
        font-size: 18px;
    }
}

//
// * -------------------------------------------------
// *  CSS from lastebil_767.css
// * -------------------------------------------------
//
@media (max-width: 769px) {
    /*#nav {
        left: 0;
    }
    #nav ul,
    #nav li {
        margin: 0px;
        padding: 0px;
        background: none!important;
        border: 0px!important;
    }
    #nav ul:hover,
    #nav ul:focus,
    #nav li a:hover {
        background: none!important;
    }*/
    .jumbotron-header {
        background-image: none!important;
    }
    .menuRow {
        margin: 0px;
        padding: 0px;
    }
    .menuRow .col-md-2,
    .menuRow .col-md-10 {
        padding: 0px;
        margin: 0px;
        position: inherit;
    }
    /*.dropdown-menu {
        position: inherit;
        margin-left: 20px!important;
        -webkit-box-shadow: none!important;
        box-shadow: none!important;
    }
    .dropdown.open,
    .dropdown.open a:hover,
    .dropdown.open a:focus,
    .dropdown-menu a:hover,
    .dropdown-menu a:focus,
    .nav .open > a,
    .nav .open > a:hover,
    .nav .open > a:focus {
        background-color: transparent!important;
    }
    .nav-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: white;
    }*/
    /*li.dropdown:hover .dropdownArrow {
        display: none;
    }*/
    #sidr-id-nav  ul.sidr-class-dropdown-menu {
        display: none;
    }
    #sidr-id-nav .sidr-class-activeOnMobile ul.sidr-class-dropdown-menu{
        display: block;
    }
    #sidr-id-nav li.sidr-class-active ul.sidr-class-dropdown-menu {
        display: block;
        margin: 0 0 0 20px !important;
        padding: 0 !important;
    }
    /*
    #nav li a {
        padding: 10px 5px 0px 10px;
    }
    #nav ul.dropdown-menu li a {
        border-bottom: 0px;
    }
    #bigHeaderContent{
        display: none!important;
    }*/
    .campaignBox h2 {
        padding: 0px;
    }
    .campaignBox p {
        padding: 0px;
    }
    .jumbotron-partners h3,
    .jumbotron-calendar h3 {
        font-size: 35px;
    }
    .jumbotron-partners h3 em,
    .jumbotron-calendar h3 em{
        font-size: 20px
    }
    .calendarText h4 {
        font-size: 30px;
    }
    .is-ipad-logo {
        display: block;
    }
    .not-ipad-logo {
        display: none;
    }
    .subMenu {
        li {
            padding: 0px 24px 8px 0px;
        }
    }

    .newsBox .newsBoxImage {
        height: auto!important;
    }
}
//
// * -------------------------------------------------
// *  CSS from lastebil_1024.css
// * -------------------------------------------------
//
@media (max-width: 1024px) {
    .jumbotron-partners h3,
    .jumbotron-calendar h3 {
        font-size: 50px;
    }
    .jumbotron-partners h3 em,
    .jumbotron-calendar h3 em{
        font-size: 24px
    }

    .newsBox .newsBoxImage {
        height: auto!important;
    }
}

.block .date .element label {
    float: none;
    font-size: 16px;
}
.block .date .element {
    float: left;
    width: 100px;
}
.block .date .element input[type=text] {
    background-color: white;
}
.yui-calcontainer {
    z-index: 9999;
}
.articleLine .datepublished {
    font-size: 16px; line-height: 17px; margin-top: 0px; display: block; margin-bottom: 10px;
}
.jumbotron .shareLine{
    line-height: 10px;
    font-size: 10px;
    margin-top: 30px;
}
.calendarList li {
    clear: both;
    margin-bottom: 40px!important;
}
.sponsorHolder {
    height: 96px;
}
.jumbotron-blue {
    padding-bottom: 30px;
}
.ezcca-edit-datatype-ezbinaryfile .block label {
    float: none;
}
.block input[type=number] {
    background-color: #EEEEEE;
    border: 0 none !important;
    border-radius: 0 !important;
    max-width: 100px;
    min-width: 100px;
    width: 100px;
}
.col-md-6 .col-md-6 .newsBoxImage {
    height: 150px;
}
.col-md-6 .newsBoxBig h2 {
    font-size: 35px;
}
.goToTop {
    text-align: right;
}
.goToTop span {
    color: black;
    font-size: 16px;
}
.col-md-6 .newsBoxBig .newsBoxImage {
    height: 360px;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring-small {
  display: inline-block;
  width: 24px;
}
.lds-dual-ring-small:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring-small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


//
// * -------------------------------------------------
// *  CSS from iefix.css
// * -------------------------------------------------
//
@media screen and (min-width:0\0) {

    #findMember, #findDeviation {
        height: 55px;
        color: #969696;
    }

    input[type=text], input[type=password], select {
        height: 45px;
    }

}




.change-to-app-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: #fff;
    z-index: 100000001;
    font-size: 18px;
    padding: 15px 0px;
    .btn {
        float: right;
        font-size: 18px;
    }
}

p.statistics-information {
    font-size: 1.8rem;
    padding: 2rem 1rem;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

p.vehicle-form-error {
    font-weight: 600;
    color: red;
}

#joinform {
    div.join-member-block.error {
        input, textarea, select
        {
            border: 1px solid #ff0000;
        }
    }
}

div.member-register-invoice-reports {
    div.report-input-parameters {
        display: flex;
        justify-content: left;
        align-items: flex-end;
        flex-direction: row;
        div {
            padding-left: 5px;
            input, select {
                min-width: auto;
                height: 35px;
                border: 1px solid #cccccc;
            }

        }
    }
}

div.company-log, div.update-log {
    .error {
        color: #de0000;
    }

    label span.normal,
    h4.modal-title span.normal
    {
        font-weight: 400;
    }

    h4.modal-title span.value
    {
        color: #e67036;
    }

    .modal.in
    {
        display: flex !important;
        align-items: center;
    }

    .modal.show-modal {
        top: 0;
        bottom: 0;
        width: auto;
        display: flex;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        align-items: center;
    }

    div.dropdown
    {
        ul.dropdown-menu
        {
            right: 0;
            left: auto;

            li:hover,
            li:active,
            li:focus
            {
                background-color: #cfcfcf;
                color: #00003f;
            }
        }


        .dropdown-toggle-button
        {
            background-color: transparent;
            border: none;
            color: inherit;
            font-size: 20px;
            font-weight: 400;
            font-family: 'Open Sans';
            line-height: 1.428571429;
            padding: 3px 20px;
            margin: 0;
        }
    }

    button.dropdown-toggle
    {
        margin: auto;
    }

    button.employeeConfirmUpdate:disabled,

    a.disabled, button.disabled
    {
        border: 1px solid #979797;
        background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
        color: #333333;
    }

    a.disabled.cancel
    {
        background: transparent;
        border: none;
    }

    div.employee-list {
        max-height: 35vh;
        overflow-y: scroll;
        border-left: 3px solid #ffffff;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    div.company-log-info-container
    {
        margin-bottom: 25px;
    }

    ul.company-log-employee-list {

        padding: 10px;

        li:nth-child(odd){
            background-color: #f1f1f1;
        }

        li {
            .isMultiUser {
                color: #e67036;
            }
            position: relative;
            list-style: none;
            padding: 0px;
            margin: 0px;
            label {
                font-weight: 400;
            }

            i.fa {
                margin-left: 5px;
            }

            input[type="radio"] {
                margin: 10px 5px 0 0;
            }

            &.selected {
                background-color: #e67036;
                color: #ffffff;
                a {
                    color: #ffffff;
                }
            }

            input[type="radio"]:checked ~ *, input[type="radio"]:checked ~ label {
                font-weight: 600;
            }

            > a {
                position: absolute;
                right: 5px;
                top: 7px;
            }
        }
    }

    table.table {
        tr.row-info {
            border-bottom: 2px solid #777;
        }

        td, th {
            font-size: 14px;

            input.button
            {
                margin: 0;
            }
        }
    }
}

div#employee-list .table-employee-list
{
    td, th
    {
        vertical-align: middle;
        line-height: 20px;

        &.nowidth
        {
            width: 1%;
            padding: 0 10px;
        }

        &.nowrap
        {
            white-space: nowrap;
        }
    }
}


div.edit-employee {
    div.attr-zip div.col-md-8 {
        div.col-md-4 {
            padding-right: 20px;
            padding-left: 0;
        }

        @media (max-width: 992px) {
            div.col-md-4 {
                padding-left: 0;
                width: 50%;
                float: left;
            }
            div.col-md-4:nth-child(2) {
                padding-right: 0;
            }
        }
    }

    div.attr-mobile div.col-md-8 {
        div.col-md-4 {
            padding-right: 20px;
        }

        @media (max-width: 992px) {
            div.col-md-4 {
                padding-left: 20px;
                width: 50%;
                float: left;
            }
        }
    }
}

div.customer-csv-import {
    h3 {
        font-size: 22px;
    }

    hr {
        border-top: 5px solid #777;
    }

    div.block.employee-info
    {
        display: flex;
        width: 100%;
        flex-flow: wrap;
    }

    div.error {
        color: #de0000;
        &h2, &a, &p, &ul > li {
            color: #de0000;
        }
    }

    div.noticeList {
        color: #e67036;
        &h2, &a, &p, &ul > li {
            color: #e67036;
        }
    }

    div.errorList {
        margin-top: 30px;
        h2, .error a, p, ul > li {
            color: #de0000;
        }
    }

    label {
        float: none;
        width: auto;
        font-weight: 600;
    }

    .nowrap
    {
        white-space: nowrap;
    }

    div.button-block
    {
        margin-top: 30px;
    }

    div.pagenavigator
    {
        margin-top: 20px;
    }

    div.employee-list
    {
        overflow: auto;

        &.admin{
            margin-top: 60px;
        }
    }

    div.modal.modal-move-employee {
        table.list td:first-child {
            width: 30%;
        }

        div.modal-footer {
            text-align: left;
        }
    }

    table.list {
        margin-top: 0;
        overflow: hidden;
        tr {
            border-bottom: 1px solid #dedede;
            &.no-border {
                border-bottom: none;
            }

            &.error {
                background-color: rgba(255, 0, 0, 0.1);
            }

            &.selected td {
                background-color: rgba(0, 0, 255, 0.2);
            }
        }

        td.error {
            background-color: rgba(255, 0, 0, 0.5);
            color: #ffffff;
            a
            {
                color: #ffffff;
            }
        }

        td {
            padding-left: 5px;
            padding-right: 5px;

            &.error {
                font-weight: 600;
                font-size: 13px;
            }
        }

        th {
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 600;
            font-size: 13px;
        }
    }
}


//
// * -------------------------------------------------
// * CSS from leftmenu.css
// *
// * This code were not in use, but I felt that deleting
// * 500 lines of code without checking it would be a
// * bit foolhardy.
// * -------------------------------------------------
//

// .highlight {
//   background: #fff699;
//   padding: 0 0.5em;
// }

// .prose ul, .prose ol {
//   list-style: outside disc;
//   font-size: 1em;
//   line-height: 1.5em;
//   margin-bottom: 1.5em;
// }
// .prose p {
//   font-size: 1em;
//   line-height: 1.5em;
//   margin-bottom: 1.5em;
// }
// .prose p.small {
//   color: #666;
//   font-size: 0.8125em;
//   line-height: 1.84615em;
//   margin-bottom: 1.84615em;
// }
// .prose p.medium {
//   color: #666;
//   font-size: 0.875em;
//   line-height: 1.71429em;
//   margin-bottom: 1.71429em;
// }
// .prose p:last-child {
//   margin-bottom: 0;
// }
// .prose h1 {
//   font-size: 1.875em;
//   line-height: 1.2em;
//   margin-bottom: 0.4em;
// }
// .prose h2 {
//   font-size: 1.5em;
//   line-height: 1.25em;
//   margin-bottom: 0.5em;
// }
// .prose a {
//   color: #6d7173;
//   border-bottom: 1px solid #a1cfe5;
//   text-decoration: none;
//   outline: none;
// }
// .prose a:focus, .prose a:hover {
//   color: #4ab336;
//   border-bottom-color: #77b36b;
// }


// .nav-btn {
//   display: block;
//  height: 41px;
//     max-width: 34px;
//   padding: 0;
//   border: 0;
//   outline: none;
//   background-image: url("../images/nav-icon.png");
//   background-repeat: no-repeat;
//   background-position: right;
//   overflow: hidden;
//   white-space: nowrap;
//   text-indent: 100%;
//   filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//   opacity: 0.7;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }
// .no.png .nav-btn {
//   background-image: url("../images/nav-icon.png");
// }
// .nav-btn:hover, .nav-btn:focus {
//   filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//   opacity: 1;
// }

// #outer-wrap {
//   position: relative;
//   overflow: hidden;
//   width: 100%;
// }

// #inner-wrap {
//   position: relative;
//   width: 100%;
// }

// #nav {
//   z-index: 200;
//   position: relative;
//   /*overflow: hidden;*/
//   color: #fff;
// }
// #nav .close-btn {
//   display: none;
//   position: absolute;
//  color: #FFFFFF;
//     font-size: 50px;
//     margin-top: -20px;
//     opacity: 0.9;
//     text-decoration: none;
//     text-shadow: none;
//  font-weight: 700;
// }


// #top {
//   z-index: 100;
//   position: relative;
// }
// #top .block-title {
//   margin: 0;
//   font-size: 1.875em;
//   line-height: 1.2em;
//   text-align: center;
//   white-space: nowrap;
// }
// #top .nav-btn {
//   position: absolute;
//   top: 25px;
//   right: 10px;

// }
// #nav li ul.submenu {
//  display: none;
// }
// @media screen and (min-width: 961px) {

//   #top .nav-btn {
//     border: 0;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   }
//   #nav .block-title {
//     border: 0;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   }
//   #nav ul {
//     text-align: center;
//     white-space: nowrap;
//   }
// }
// @media screen and (max-width: 960px) {
// #logo {
//  margin-bottom: 20px;
// }
// .block {
//   position: relative;
//   margin: 0 auto;
//   padding: 0px;
//   max-width: 60em;
// }
// #nav li.is-active ul.submenu {
//  display: block;
// }
// .nav > li > a:hover {background-color: #333333; color: #ccc; }
// #nav .block-title {
//   border: 0;
//   clip: rect(0 0 0 0);
//   height: 1px;
//   margin: -1px;
//   overflow: hidden;
//   padding: 0;
//   position: absolute;
//   width: 1px;
// }
// #nav .block {
//   z-index: 2;
//   position: relative;
//   padding: 0.75em 1.25em;
//   background: #333333;
// }
// #nav ul {
//   *zoom: 1;
//   display: block;
// }
// #nav ul:before, #nav ul:after {
//   content: "";
//   display: table;
// }
// #nav ul:after {
//   clear: both;
// }
// #nav li {
//   display: block;
// }
// #nav li a {
//   display: block;
//   color: #ccc;
//   line-height: 1.28571em;
//   font-weight: bold;
//   outline: none;
// }
// #nav .block-title {
//   border: 0;
//   clip: rect(0 0 0 0);
//   height: 1px;
//   margin: -1px;
//   overflow: hidden;
//   padding: 0;
//   position: absolute;
//   width: 1px;
// }
// #nav .block {
//   z-index: 2;
//   position: relative;
//   padding: 0.75em 1.25em;
//   background: #333333;
// }
// #nav ul {
//   *zoom: 1;
//   display: block;
// }
// #nav ul:before, #nav ul:after {
//   content: "";
//   display: table;
// }
// #nav ul:after {
//   clear: both;
// }
// #nav li a {
//   display: block;
//   font-size: 18px;
// }
// #nav li ul li a {
//  font-size: 15px;
// }
// #nav li a {
//   display: block;
//   color: #fff;
//   line-height: 1.28571em;
//   font-weight: bold;
//   outline: none;
// }
//  body {
//      padding-left: 0;
//      padding-right: 0px;
//  }
//   #nav {
//     position: absolute;
//     top: 0;
//     padding-top: 2em;
//   }
//   #nav:not(:target) {
//     z-index: 1;
//     height: 0;
//   }
//   #nav:target .close-btn {
//     display: block;
//   }
//   #nav .close-btn {
//     position: absolute;
//     top: 0px;
//     right: 20px;
//  display: block;
//   }
//   #nav .block {
//     position: relative;
//     padding: 0;
//   }
//   #nav li {
//     position: relative;
//     border-top: 1px solid rgba(255, 255, 255, 0.1);
//  margin: 0px;
//  padding: 0px;
//  clear: both;
//  color: white;
//   }
//   .pull-right {
//  float: none;
//   }
//   #nav li:last-child {
//     border-bottom: 1px solid rgba(255, 255, 255, 0.1);
//   }
//   #nav li.is-active:after {
//     z-index: 50;
//     display: block;
//     content: "";
//     position: absolute;
//     top: 18px;
//     right: -0.03125em;
//     margin-top: -0.625em;
//    /* border-top: 0.625em transparent solid;
//     border-bottom: 0.625em transparent solid;
//     border-right: 0.625em white solid;*/
//  border: 0px;
//   }
//   #nav li a {
//     padding: 10px 5px 10px 10px;
//  width: 100%;
//  display: block;
//   }
//   .nav-pills .active a:link,
//  ul .active  {
//  background: none;
//   }

//  /*
//   .js-ready #nav {
//     height: 100%;
//     width: 70%;
//     background: #333333;
//     -webkit-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
//     -moz-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
//     box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
//   }
//   .js-ready #nav .block {
//     background: transparent;
//   }

//   .js-ready #nav {
//     right: 0%;
//   }
//   .js-ready #inner-wrap {
//     left: 0;
//   }

//   .js-nav #inner-wrap {
//     left: 70%;
//   }

//   .csstransforms3d.csstransitions.js-ready #nav {

//     -webkit-transform: translate3d(100%, 0, 0);
//     -moz-transform: translate3d(100%, 0, 0);
//     -ms-transform: translate3d(100%, 0, 0);
//     -o-transform: translate3d(100%, 0, 0);
//     transform: translate3d(100%, 0, 0);
//     -webkit-backface-visibility: hidden;
//     -moz-backface-visibility: hidden;
//     -ms-backface-visibility: hidden;
//     -o-backface-visibility: hidden;
//     backface-visibility: hidden;
//   }
//   .csstransforms3d.csstransitions.js-ready #inner-wrap {
//     left: 0 !important;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: translate3d(0, 0, 0);
//     -ms-transform: translate3d(0, 0, 0);
//     -o-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//     -webkit-transition: -webkit-transform 500ms ease;
//     -moz-transition: -moz-transform 500ms ease;
//     -o-transition: -o-transform 500ms ease;
//     transition: transform 500ms ease;
//     -webkit-backface-visibility: hidden;
//     -moz-backface-visibility: hidden;
//     -ms-backface-visibility: hidden;
//     -o-backface-visibility: hidden;
//     backface-visibility: hidden;
//   }

//   .csstransforms3d.csstransitions.js-nav #inner-wrap {
//     -webkit-transform: translate3d(-70%, 0, 0) scale3d(1, 1, 1);
//     -moz-transform: translate3d(-70%, 0, 0) scale3d(1, 1, 1);
//     -ms-transform: translate3d(-70%, 0, 0) scale3d(1, 1, 1);
//     -o-transform: translate3d(-70%, 0, 0) scale3d(1, 1, 1);
//     transform: translate3d(-70%, 0, 0) scale3d(1, 1, 1);
//   }

//   .csstransforms3d.csstransitions.js-ready #nav .block {
//     filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//     opacity: 0.7;
//     -webkit-transition: opacity 300ms 100ms, -webkit-transform 500ms ease;
//     -webkit-transition-delay: ease, 0s;
//     -moz-transition: opacity 300ms 100ms ease, -moz-transform 500ms ease;
//     -o-transition: opacity 300ms 100ms ease, -o-transform 500ms ease;
//     transition: opacity 300ms 100ms ease, transform 500ms ease;
//     -webkit-transform: translate3d(0, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -moz-transform: translate3d(0, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -ms-transform: translate3d(0, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -o-transform: translate3d(0, 0, 0) scale3d(0.9, 0.9, 0.9);
//     transform: translate3d(0, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -webkit-transform-origin: 50% 0%;
//     -moz-transform-origin: 50% 0%;
//     -ms-transform-origin: 50% 0%;
//     -o-transform-origin: 50% 0%;
//     transform-origin: 50% 0%;
//   }

//   .csstransforms3d.csstransitions.js-nav #nav .block {
//     filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//     opacity: 1;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: translate3d(0, 0, 0);
//     -ms-transform: translate3d(0, 0, 0);
//     -o-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//   }*/
//   .js-ready #nav {
//     height: 100%;
//     width: 70%;
//     background: #333333;
//     -webkit-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
//     -moz-box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
//     box-shadow: inset -1.5em 0 1.5em -0.75em rgba(0, 0, 0, 0.25);
//   }
//   .js-ready #nav .block {
//     background: transparent;
//   }
//   .js-ready #nav .close-btn {
//     display: block;
//     filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//     opacity: 0.7;
//   }
//   .js-ready #nav .close-btn:focus, .js-ready #nav .close-btn:hover {
//     filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//     opacity: 1;
//   }

//   .js-ready #nav {
//     left: -70%;
//   }
//   .js-ready #inner-wrap {
//     left: 0;
//   }

//   .js-nav #inner-wrap {
//     left: 70%;
//   }

//   .csstransforms3d.csstransitions.js-ready #nav {
//     left: 0;
//     -webkit-transform: translate3d(-100%, 0, 0);
//     -moz-transform: translate3d(-100%, 0, 0);
//     -ms-transform: translate3d(-100%, 0, 0);
//     -o-transform: translate3d(-100%, 0, 0);
//     transform: translate3d(-100%, 0, 0);
//     -webkit-backface-visibility: hidden;
//     -moz-backface-visibility: hidden;
//     -ms-backface-visibility: hidden;
//     -o-backface-visibility: hidden;
//     backface-visibility: hidden;
//   }
//   .csstransforms3d.csstransitions.js-ready #inner-wrap {
//     left: 0 !important;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: translate3d(0, 0, 0);
//     -ms-transform: translate3d(0, 0, 0);
//     -o-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//     -webkit-transition: -webkit-transform 500ms ease;
//     -moz-transition: -moz-transform 500ms ease;
//     -o-transition: -o-transform 500ms ease;
//     transition: transform 500ms ease;
//     -webkit-backface-visibility: hidden;
//     -moz-backface-visibility: hidden;
//     -ms-backface-visibility: hidden;
//     -o-backface-visibility: hidden;
//     backface-visibility: hidden;
//   }

//   .csstransforms3d.csstransitions.js-nav #inner-wrap {
//     -webkit-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//     -moz-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//     -ms-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//     -o-transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//     transform: translate3d(70%, 0, 0) scale3d(1, 1, 1);
//   }

//   .csstransforms3d.csstransitions.js-ready #nav .block {
//     filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
//     opacity: 0.7;
//     -webkit-transition: opacity 300ms 100ms, -webkit-transform 500ms ease;
//     -webkit-transition-delay: ease, 0s;
//     -moz-transition: opacity 300ms 100ms ease, -moz-transform 500ms ease;
//     -o-transition: opacity 300ms 100ms ease, -o-transform 500ms ease;
//     transition: opacity 300ms 100ms ease, transform 500ms ease;
//     -webkit-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -moz-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -ms-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -o-transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//     transform: translate3d(70%, 0, 0) scale3d(0.9, 0.9, 0.9);
//     -webkit-transform-origin: 50% 0%;
//     -moz-transform-origin: 50% 0%;
//     -ms-transform-origin: 50% 0%;
//     -o-transform-origin: 50% 0%;
//     transform-origin: 50% 0%;
//   }

//   .csstransforms3d.csstransitions.js-nav #nav .block {
//     filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
//     opacity: 1;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: translate3d(0, 0, 0);
//     -ms-transform: translate3d(0, 0, 0);
//     -o-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//   }
// }
