.adIndicator {
	width: 6px;
	height: 52px;
	background-image: url('../images/ad.png');
	background-repeat: no-repeat;
	position: absolute;
	right: 0;
}
.ifAddThenRight {
	margin-bottom: 30px;
}
.ifAddThenRight .adBox {
	float: right;
}