.img-crop {
    position: relative;
    overflow: hidden;
    width: 100%;

    &.aspect-3-2 {
        padding-top: 66.66%; // 3:2-format
    }

    &.aspect-16-9 {
        padding-top: 56.25%; // 16:9-format
    }

    img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
    }
}
