div.overlayed-info {
    & > h2 {
        font-size: 2.2rem !important;
        margin-bottom: 1.5rem;
    }

    @media (min-width: $breakpoint-desktop) {
        div.col-md-12 + div.col-md-12 {
            margin-top: 15px;
        }
    }

    @media (max-width: $breakpoint-tablet) {
        div.col-xs-12 + div.col-xs-12 {
            margin-top: 10px;
        }
    }

    .attributes {
        p:not(:last-child) {
            margin-bottom: 1rem;
        }
        .attribute-name {
            font-weight: $font-weight-title-bold;
            margin-right: 0.75rem;
        }

        .attribute-value {
            display: inline-block;
            vertical-align: top;
            margin-left: 1rem;
        }
    }
}
