.nlfcard {
  position: relative;

  &-image {
    & img {
      width: 100%;
      max-width: 100%;
    }
  }

  &-details {
    color: #fff;
    text-align: center;
    font-family: $f-running-text;
    position: absolute;
    bottom: 15%;
    left: 0;
    width: 100%;

    &-customername {
      font-size: 1.3em;
    }
  }

  &-downloadlink {
    margin-top: 1em;
  }
}
