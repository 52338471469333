@media (min-width: $breakpoint-desktop) {
    div.image-overlayed {
        position: relative;
        margin-bottom: 36px;

        & > .overlay-container {
            position: absolute;
            bottom: -36px;
            width: 100%;
        }
    }
}

@media (max-width: $breakpoint-desktop) {
    div.row.overlay-container {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 15px;
    }
}

.overlay-container-child {
    background-color: #ffffff;
}