.fixed-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    background-color: #fff;

    #header.jumbotron {
        background-color: #344759;
        border-bottom: 1px #fff;
        margin-bottom: 4px;

        .header-top {
            .row {
                display: flex;
            }
            &-right {
                &-wrapper {
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    #search {
                        float: right;
                        margin-top: 40px;
                        input {
                            float: left;
                            min-width: auto;
                            font-size: 22px;
                            height: 45px;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            border-right: none;
                            width: 300px;
                        }
                        button {
                            float: left;
                            height: 45px;
                            margin: 0;
                            background-color: #e67036;
                            font-size: 22px;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-color: #333;
                            border-width: 0.5px;
                        }
                    }
                }
            }
        }
    }

    #sub-header {
        background-color: #344759;

        .mobile-header {
            padding: 0 30px;
        }

        #navigation {
            width: 100%;

            .nav {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .dropdownForHeaderBasket {
                    right: 0;
                    left: auto;
                }

            }
        }
    }
}

.hackedHeader {
    height: 197px;

    @media (max-width: 767px) {
        height: 59px;
    }
}

#mobilemenu {
    .search-div {
        padding: 10px;
        padding-left: 30px;
        font-size: 18px;
        position: relative;

        input {
            padding-right: 135px;
            height: 40px;
        }

        .search-button {
            position: absolute;
            right: 0;
            top: 0;
            line-height: 38px;
            padding: 0 25px;
            font-size: 18px;
            background-color: #e67036;

            i {
                vertical-align: -3px;
            }
        }
    }
    a {
        display: block;
        width: 100%;
        padding: 10px;
        padding-left: 30px;
        background-color:#F4F4F4;
        margin-bottom: 3px;
        font-size: 18px;
        cursor: pointer;
    }
}

#searchMenuDesktop {
    cursor: pointer;
}
input[type="submit"] {
    display: inline;
}
.searchMenuLink.toggled {
    background-image: url('../images/close-white.png');
    background-size: 24px;
}
#searchMenuDesktop:hover .searchMenuLink {
    background-image: url('../images/search-icon-hover.png');
}
#searchMenuDesktop:hover .searchMenuLink.toggled {
    background-image: url('../images/close-blue.png');
}

#course-header {
    display: block;
    background-color: rgb(125, 125, 125);

    .container {
        .topLogoHolder {
            padding: 5px;

            img {
                height: 68px;
                margin-left: -9px;
            }
        }
    }
}
