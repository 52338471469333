footer {
    background-color: $color-header;
    color: $color-text-light;

    h1, h2, h3, h4, h5, h6 {
        color: $color-text-light;
    }

    h2 {
        font-size: 1.6rem !important;
        font-weight: $font-weight-title-bold;
    }

    a {
        color: $color-text-light;
        font-size: 1.4rem;
        text-decoration: underline;
    }

    div.social-media {
        a {
            img {
                height: 2rem;
                // filter: invert(1);
                // max-width: 40px;
            }
        }
    }

    div.footer-right {
        text-align: right;
    }

    @media (max-width: $breakpoint-desktop) {
        div.footer-left > div.row > div[class^="col"]:last-child {
            text-align: right;
        }

        div.footer-right {
            margin-top: 20px;
            text-align: center;
        }
    }
}