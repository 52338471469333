// Styles for the customer page
// This is the page logged in members get served.
// The styles in this file is only applied to the main
// page of the customer.

.customer {
	@include customer-page;

	&__image {
		h1 {
			position: absolute;
			bottom: 100px;
			left: 30px;
		    line-height: 48px;
			font-size: 48px;
			color: #ffffff;
			max-width: 650px;
			padding-bottom: 0;
		    margin-top: 0;
		    z-index: 10;
		}
	}

	&__content {
		h2 {}

		&__contact-info {
			border-left: 1px solid #ddd;
			padding: 0 0 40px 40px;
		}

		#company-logo {
			margin: 0 0 20px;
			max-width: 250px;
			height: auto;
		}
	}

	.pencil {
		color: white;
		padding: 5px;
		margin: 5px;
		background-color: red;
		border-radius: 7px;
		cursor: pointer;
		transition: all 0.5s;
		animation: jump 0.5s 3;
		position: absolute;
		right: 5px;
		top: 5px;
		z-index: 1;

		&:hover {
			background: white;
			color: red;
		}

		i {
			top: 3px;
		}
	}
}
	.has-updated-container {
		z-index: 100000001;
		opacity: 0.95;
		color: #fff;
		position: fixed;
		background-color: #333;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		padding: 35px 0px;
	}

@keyframes jump {
  0%   { top: 3px; }
  50% { top: -20px; }
  100% { top: 3px; }
}

