select.chosen + div.chosen-container {
    color: $color-text;
    max-width: 100%;
}

div.chosen-container-single a.chosen-single {
    // background: url('../images/selectarrow.png') no-repeat 98% center $color-campaign-box;;
    background: $color-white;
    color: $color-text;
    height: 36px;
    line-height: 36px;
    border-radius: 0;
    box-shadow: none;
    border: none;

    span {
        // color: white;
        font-size: 18px;
        font-family: $font-family-title;
        font-weight: $font-weight-title-normal;

        &::after {
            content: "\f0d7";
            color: $color-text;
            font-family: $font-family-font-awesome;
            font-weight: bold; /* Solid icon */
            position: absolute;
            right: 15px;
        }
    }
}

.chosen-drop {
    border: none;
    border-radius: 0;
}

// div.chosen-container {
//     ::after {
//         content: "\f0d7";
//         color: $color-text;
//         font-family: $font-family-font-awesome;
//     }
// }

.chosen-container .chosen-results li.highlighted {
    background-image: none;
    // background: $color-blue-saturated;
    background: $color-blue-light-saturated;
}