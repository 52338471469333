// @import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

footer {
    font-family: 'Roboto', sans-serif;
    background-color: #334759;
    color: #fff;

    h2 {
        font-family: 'Roboto', sans-serif;
        color: #fff;
        font-size: 1.6rem !important;
        font-weight: 500;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    p, a, span {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.4;
    }

    div.social-media {
        a {
            img {
                height: 2rem;
                // filter: invert(1);
                // max-width: 40px;
            }
        }
    }

    div.footer-right {
        text-align: right;
    }

    div.footer-left span {
        font-size: 14px;
    }

    @media (max-width: 992px) {
        div.footer-left > div.row > div[class^="col"]:last-child {
            text-align: right;
        }

        div.footer-right {
            margin-top: 20px;
            text-align: center;
        }
    }
}

.sm--text-right {
    @media (min-width: 768px) {
        text-align: right;
    }
}