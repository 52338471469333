.modulesettings {
    .panel {
        div.attribute-title h2 {
            margin-top: 5px;
            margin-bottom: 5px;

            i {
                width: 35px;
                text-align: center;
            }
        }

        .module-body {
            font-size: 16px;
        }

        b {
            font-weight: 500;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;

        .toggle-module {
            display: flex;
            align-items: center;
        }
    }
}
