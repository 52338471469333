b, strong {
    font-weight: 500;
}

.breadcrumb {
    padding: 15px 0 0 0;
    margin-bottom: 0;
    list-style: none;
    background-color: initial;
    /* border-radius: 4px; */

    // &::before {
    //     content: "\f105";
    //     font-family: $font-family-font-awesome;
    // }
}

.jumbotron {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: initial;
    font-size: initial;
    font-weight: inherit;

    p {
        font-weight: 300;
        font-size: 1.6rem;
        margin-bottom: 0;
        line-height: 1.2;
    }
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: initial;
    border-color: transparent;
}

.nav-pills {
    & > li {
        & > a {
            border-radius: 15px;
            font-size: 1.4rem;
            font-weight: 300;
            background-color: #fff;
            padding: 4px 12px 2px 12px;
            line-height: 1.2;
            color: #1a1a1a;
        }
    }

    & > li + li {
        margin-left: 1rem;
    }

    & > li:not(.active) {
        & > a {
            & > i.fal {
                color: #1F3B72;
            }
        }
    }

    & > li.active {
        & > a {
            background-color: #1F3B72;
            color: #fff;
        }
    }

    & > li.label {
        font-size: 1.4rem;
        font-weight: 300;
        padding: 4px 0 2px 0;
        line-height: 1.2;

        & > span {
            color: #1a1a1a;
        }
    }
}

.panel {
    & > .panel-heading.arrow {
        position: relative;
        padding-right: 30px;

        &::after {
            content: "\f107";
            font-family: $font-family-font-awesome;
            font-size: 16px;
            font-weight: 300;
            transition: all ease .15s;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }

        &:not(.collapsed) {
            &::after {
                transform: translateY(-60%) rotate(180deg);
            }
        }
    }
}

// Manually adding classes from Bootstrap 4, until we can upgrade Bootstrap globally

// @for $i from 1 through 12 {
// 	.col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i} {
// 		margin-bottom: 10px;
// 	}
// }

// Poor man's spacer, based on Bootstrap 4 Spacer
.m-0 {margin: 0 !important;}
.m-1 {margin: ($spacer * .25) !important;}
.m-2 {margin: ($spacer * .5) !important;}
.m-3 {margin: $spacer !important;}
.m-4 {margin: ($spacer * 1.5) !important;}
.m-5 {margin: ($spacer * 3) !important;}

.mt-0 {margin-top: 0 !important;}
.mt-1 {margin-top: ($spacer * .25) !important;}
.mt-2 {margin-top: ($spacer * .5) !important;}
.mt-3 {margin-top: $spacer !important;}
.mt-4 {margin-top: ($spacer * 1.5) !important;}
.mt-5 {margin-top: ($spacer * 3) !important;}

.mr-0 {margin-right: 0 !important;}
.mr-1 {margin-right: ($spacer * .25) !important;}
.mr-2 {margin-right: ($spacer * .5) !important;}
.mr-3 {margin-right: $spacer !important;}
.mr-4 {margin-right: ($spacer * 1.5) !important;}
.mr-5 {margin-right: ($spacer * 3) !important;}

.mb-0 {margin-bottom: 0 !important;}
.mb-1 {margin-bottom: ($spacer * .25) !important;}
.mb-2 {margin-bottom: ($spacer * .5) !important;}
.mb-3 {margin-bottom: $spacer !important;}
.mb-4 {margin-bottom: ($spacer * 1.5) !important;}
.mb-5 {margin-bottom: ($spacer * 3) !important;}

.ml-0 {margin-left: 0 !important;}
.ml-1 {margin-left: ($spacer * .25) !important;}
.ml-2 {margin-left: ($spacer * .5) !important;}
.ml-3 {margin-left: $spacer !important;}
.ml-4 {margin-left: ($spacer * 1.5) !important;}
.ml-5 {margin-left: ($spacer * 3) !important;}

.p-0 {padding: 0 !important;}
.p-1 {padding: ($spacer * .25) !important;}
.p-2 {padding: ($spacer * .5) !important;}
.p-3 {padding: $spacer !important;}
.p-4 {padding: ($spacer * 1.5) !important;}
.p-5 {padding: ($spacer * 3) !important;}

.pt-0 {padding-top: 0 !important;}
.pt-1 {padding-top: ($spacer * .25) !important;}
.pt-2 {padding-top: ($spacer * .5) !important;}
.pt-3 {padding-top: $spacer !important;}
.pt-4 {padding-top: ($spacer * 1.5) !important;}
.pt-5 {padding-top: ($spacer * 3) !important;}

.pr-0 {padding-right: 0 !important;}
.pr-1 {padding-right: ($spacer * .25) !important;}
.pr-2 {padding-right: ($spacer * .5) !important;}
.pr-3 {padding-right: $spacer !important;}
.pr-4 {padding-right: ($spacer * 1.5) !important;}
.pr-5 {padding-right: ($spacer * 3) !important;}

.pb-0 {padding-bottom: 0 !important;}
.pb-1 {padding-bottom: ($spacer * .25) !important;}
.pb-2 {padding-bottom: ($spacer * .5) !important;}
.pb-3 {padding-bottom: $spacer !important;}
.pb-4 {padding-bottom: ($spacer * 1.5) !important;}
.pb-5 {padding-bottom: ($spacer * 3) !important;}

.pl-0 {padding-left: 0 !important;}
.pl-1 {padding-left: ($spacer * .25) !important;}
.pl-2 {padding-left: ($spacer * .5) !important;}
.pl-3 {padding-left: $spacer !important;}
.pl-4 {padding-left: ($spacer * 1.5) !important;}
.pl-5 {padding-left: ($spacer * 3) !important;}

.d-flex {display: flex;}
.flex-row {flex-direction: row;}
.flex-column {flex-direction: column;}
.justify-content-between {justify-content: space-between;}
.justify-content-start {justify-content: flex-start;}
.align-items-center {align-items: center;}
.flex-wrap {flex-wrap:wrap;}
.mx-auto {margin-left: auto; margin-right: auto;}
.d-block {display:block;}


.text-center {
    text-align: center;
}

.sm--text-right {
    @media (min-width: 768px) {text-align: right;
    }
}


@media (min-width: 576px) {
    .flex-sm-column {
        flex-direction: column !important;
    }
}

@media (min-width: 768px) {
    .flex-md-column {flex-direction: column !important;}
    .justify-content-md-center {justify-content: center;}
    .p-md-0 {padding: 0 !important;}
    .p-md-1 {padding: 0.25rem !important;}
    .p-md-2 {padding: 0.5rem !important;}
    .p-md-3 {padding: 1rem !important;}
    .p-md-4 {padding: 1.5rem !important;}
    .p-md-5 {padding: 3rem !important;}
    .px-md-0 {padding-right: 0 !important;padding-left: 0 !important;}
    .px-md-1 {padding-right: 0.25rem !important;padding-left: 0.25rem !important;}
    .px-md-2 {padding-right: 0.5rem !important;padding-left: 0.5rem !important;}
    .px-md-3 {padding-right: 1rem !important;padding-left: 1rem !important;}
    .px-md-4 {padding-right: 1.5rem !important;padding-left: 1.5rem !important;}
    .px-md-5 {padding-right: 3rem !important;padding-left: 3rem !important;}
    .py-md-0 {padding-top: 0 !important;padding-bottom: 0 !important;}
    .py-md-1 {padding-top: 0.25rem !important;padding-bottom: 0.25rem !important;}
    .py-md-2 {padding-top: 0.5rem !important;padding-bottom: 0.5rem !important;}
    .py-md-3 {padding-top: 1rem !important;padding-bottom: 1rem !important;}
    .py-md-4 {padding-top: 1.5rem !important;padding-bottom: 1.5rem !important;}
    .py-md-5 {padding-top: 3rem !important;padding-bottom: 3rem !important;}
    .pt-md-0 {padding-top: 0 !important;}
    .pt-md-1 {padding-top: 0.25rem !important;}
    .pt-md-2 {padding-top: 0.5rem !important;}
    .pt-md-3 {padding-top: 1rem !important;}
    .pt-md-4 {padding-top: 1.5rem !important;}
    .pt-md-5 {padding-top: 3rem !important;}
    .pe-md-0 {padding-right: 0 !important;}
    .pe-md-1 {padding-right: 0.25rem !important;}
    .pe-md-2 {padding-right: 0.5rem !important;}
    .pe-md-3 {padding-right: 1rem !important;}
    .pe-md-4 {padding-right: 1.5rem !important;}
    .pe-md-5 {padding-right: 3rem !important;}
    .pb-md-0 {padding-bottom: 0 !important;}
    .pb-md-1 {padding-bottom: 0.25rem !important;}
    .pb-md-2 {padding-bottom: 0.5rem !important;}
    .pb-md-3 {padding-bottom: 1rem !important;}
    .pb-md-4 {padding-bottom: 1.5rem !important;}
    .pb-md-5 {padding-bottom: 3rem !important;}
    .ps-md-0 {padding-left: 0 !important;}
    .ps-md-1 {padding-left: 0.25rem !important;}
    .ps-md-2 {padding-left: 0.5rem !important;}
    .ps-md-3 {padding-left: 1rem !important;}
    .ps-md-4 {padding-left: 1.5rem !important;}
    .ps-md-5 {padding-left: 3rem !important;}
    .my-md-3 {margin-top: 1rem !important; margin-bottom: 1rem !important;}
    .flex-md-row {flex-direction: row !important;}
}

@media (min-width: 992px) {
    .flex-lg-row {flex-direction: row !important;}
    .justify-content-lg-between {justify-content: space-between;}
    .p-lg-0 {padding: 0 !important;}
    .p-lg-1 {padding: 0.25rem !important;}
    .p-lg-2 {padding: 0.5rem !important;}
    .p-lg-3 {padding: 1rem !important;}
    .p-lg-4 {padding: 1.5rem !important;}
    .p-lg-5 {padding: 3rem !important;}
    .px-lg-0 {padding-right: 0 !important;padding-left: 0 !important;}
    .px-lg-1 {padding-right: 0.25rem !important;padding-left: 0.25rem !important;}
    .px-lg-2 {padding-right: 0.5rem !important;padding-left: 0.5rem !important;}
    .px-lg-3 {padding-right: 1rem !important;padding-left: 1rem !important;}
    .px-lg-4 {padding-right: 1.5rem !important;padding-left: 1.5rem !important;}
    .px-lg-5 {padding-right: 3rem !important;padding-left: 3rem !important;}
    .py-lg-0 {padding-top: 0 !important;padding-bottom: 0 !important;}
    .py-lg-1 {padding-top: 0.25rem !important;padding-bottom: 0.25rem !important;}
    .py-lg-2 {padding-top: 0.5rem !important;padding-bottom: 0.5rem !important;}
    .py-lg-3 {padding-top: 1rem !important;padding-bottom: 1rem !important;}
    .py-lg-4 {padding-top: 1.5rem !important;padding-bottom: 1.5rem !important;}
    .py-lg-5 {padding-top: 3rem !important;padding-bottom: 3rem !important;}
    .pt-lg-0 {padding-top: 0 !important;}
    .pt-lg-1 {padding-top: 0.25rem !important;}
    .pt-lg-2 {padding-top: 0.5rem !important;}
    .pt-lg-3 {padding-top: 1rem !important;}
    .pt-lg-4 {padding-top: 1.5rem !important;}
    .pt-lg-5 {padding-top: 3rem !important;}
    .pe-lg-0 {padding-right: 0 !important;}
    .pe-lg-1 {padding-right: 0.25rem !important;}
    .pe-lg-2 {padding-right: 0.5rem !important;}
    .pe-lg-3 {padding-right: 1rem !important;}
    .pe-lg-4 {padding-right: 1.5rem !important;}
    .pe-lg-5 {padding-right: 3rem !important;}
    .pb-lg-0 {padding-bottom: 0 !important;}
    .pb-lg-1 {padding-bottom: 0.25rem !important;}
    .pb-lg-2 {padding-bottom: 0.5rem !important;}
    .pb-lg-3 {padding-bottom: 1rem !important;}
    .pb-lg-4 {padding-bottom: 1.5rem !important;}
    .pb-lg-5 {padding-bottom: 3rem !important;}
    .ps-lg-0 {padding-left: 0 !important;}
    .ps-lg-1 {padding-left: 0.25rem !important;}
    .ps-lg-2 {padding-left: 0.5rem !important;}
    .ps-lg-3 {padding-left: 1rem !important;}
    .ps-lg-4 {padding-left: 1.5rem !important;}
    .ps-lg-5 {padding-left: 3rem !important;}
    .mx-lg-0 {margin-left: 0 !important; margin-right: 0 !important;}

}