div#left-sidebar {
	margin-top: 20px; /* Matching header margin */

	h2, strong.menu__item__title {
		color: $color-text;
		font-size: 1.8rem !important;
		font-weight: 400;
	}

	div.menu {

		a {
			color: $color-text;
			font-size: 1.8rem;
			display: inline-block;
			font-weight: 300;
		}

		&:not(.menu--sub) > div.menu__item > a {
			font-weight: 400;
		}

		// div.menu__item__title > a {
		// 	font-weight: $font-weight-title-bold;
		// }

		// a.menu__item__title {
		// 	font-weight: $font-weight-title-bold;
		// }

		div.menu__item > a,
		ul.subMenu > li > a {
			position: relative;

			&::after {
				content: "\f061";
				color: transparent;
				// color: $color-link;
				font-family: $font-family-font-awesome;
				padding-left: 5px;
				font-size: 16px;
				font-weight: $font-weight-title-bold;
				transition: all ease .15s;
				display:inline-block; // don't underline
				position: absolute;
			}

			&:hover::after {
				color: $color-link;
				padding-left: 10px;
			}
		}

		div.menu__item--active > a:last-child {
			font-weight: 500;

			&::after {
				content: "\f061";
				padding-left: 10px;
				color: $color-link;
				// font-family: $font-family-font-awesome;
				// font-size: 16px;
				// font-weight: $font-weight-title-bold;
				// transition: all ease .15s;
				// display:inline-block; // don't underline
			}

			// &:hover::after {
			// 	padding-left: 15px;
			// }
		}
	}

	// div.menu:hover div.menu__item--active > a:last-child:not(:hover) {
	// 	&::after {
	// 		padding-left: 6px;
	// 		color: transparent;
	// 	}
	// }

	div.menu--sub {
		padding-left: 40px;
	}

	& > div.sidebar-graphic > img {
		max-width: 100%;
	}

	@media (max-width: $breakpoint-desktop) {
		div.sidebar-graphic {
			display: none;
		}
	}

	/* Admin menu */
	div.admin-menu ul {
		list-style-type: none;
		padding-left: 30px;

		& > li > a {
			& {
				color: #333;
				font-size: 1.8rem;
			}

			&::after {
				content: "\f061";
				color: transparent;
				// color: $color-link;
				font-family: $font-family-font-awesome;
				padding-left: 5px;
				font-size: 16px;
				font-weight: $font-weight-title-bold;
				transition: all ease .15s;
				display:inline-block; // don't underline
			}

			&:hover::after {
				color: $color-link;
				padding-left: 10px;
			}
		}

		& > li.active > a:last-child {
			font-weight: $font-weight-title-bold;

			&::after {
				content: "\f061";
				padding-left: 10px;
				color: $color-link;
				// font-family: $font-family-font-awesome;
				// font-size: 16px;
				// font-weight: $font-weight-title-bold;
				// transition: all ease .15s;
				// display:inline-block; // don't underline
			}

			// &:hover::after {
			// 	padding-left: 15px;
			// }
		}
	}
}