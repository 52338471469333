.campaign-box {
	padding: 10px;
	margin-bottom: 30px;

	& > h2 {
		font-size: $font-size-h2-infobox !important;
		color: $color-text-title-light !important;
		margin: 0 0 25px 0;
	}

	p {
		font-size: 20px;
		padding: 0px 10px;
	}

	a {
		color: #ffffff;
	}

	&--black {
		background-color: #000000;
	}

	&--wimp {
		background-color: #fff;

		img {
			margin-bottom: 20px;
		}

		h4 {
			font-size: 28px;
			font-family: $font-family-title;
			font-weight: $font-weight-title-normal;
		}
	}

	&--transport {
		background-color: $color-campaign-box;
		padding: 25px 25px;
		margin-right: 75px;

		select {
			width: 100%;
		}

		.form__block {
			&--button {
				display: inline-block;

				.search-button {
					margin: 0;
					// background-color: #e67036;
					background-color: $color-call-to-action;
					color: $color-button-text-light;
					font-size: 16px;
					line-height: 26px;
					padding: 4px 20px 4px 15px;
				}
			}

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			&:last-child.form__block--button {
				margin-top: 20px;
				width: 100%;

				button {
					width: 50%;
				}
			}
		}

		.chosen-single {
			div {
				display: none !important;
			}
		}
	}

	&--grey {
		background-color: #ececec;
		border: 8px solid #c5c5c5;
		color: black;

		a {
			color: #0089bc;

			h2 & {
				color: #000000;
			}
		}

		p {
			font-size: 16px;
			color: #000000;
		}
	}

	&--newsticker {
		padding: 0;

		&-title {
			background-color: #286da8;
			text-align: center;
			padding: 10px;
			margin-bottom: 3px;

			h4 {
				margin: 0;
			}
		}

		&-content {
			height: 320px;
			padding: 10px;
			background-color: #2b85ba;
			overflow: hidden;
		}
	}

	.newsTickerList {
		font-size: 18px;
	}
}
