.filter-icon {
    color: $color-blue-dark;
}

h2 .filter-icon {
    font-size: 1.6rem;
    vertical-align: middle;

    // Normalize different icon widths
    display: inline-block;
    width: 2.2rem;
    text-align: center;
}
