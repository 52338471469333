.body {
	&--customer, &--company, &--nlf_article, &--nlf_folder {
		background-color: #ECECEC;

		.jumbotron {
			&-header {
				background-color: #404040;
			}

			&-breadcrumbs {
				background-color: #FFFFFF;
			}

			&-content {
				border-top: 1px solid #eee;
				padding-top: 40px;
				background-color: #F5F5F5;
				padding-bottom: 60px;

				@media screen and (max-width: 992px) {
					.container, .customer {
						 
					}
				}
			}

			&-footer {
				border-top: 1px solid #ddd;
			}
		}
    }
    
    .vehicle-list-table {
        .action-column {
            min-width: 70px;
            display: flex;
            flex: 1;
            flex-wrap: wrap;

            a {
                width: 50%;
                display: flex;
                padding: 0.75rem 0;

                &.recover_car_button {
                    width: 100%;
                }
            }
        }

        .tight {
            min-width: 1%;
        }
        .center-column {
            text-align: center;
        }
    }

    input[type="text"]:read-only {
        background-color: rgb(212, 207, 207);
    }

    table.company-edit {
        input[type="text"]:read-only {
            color: #717171 !important;
        }   
    }

    p.deviation-warning {
        font-weight: 600;
        margin-bottom: 2rem;
    }
}

