#mobilemenu div.mobilemenusep {
    display: none;
    height: 1px;
    font-size: 1px;
    line-height: 1px;
    border-top: 3px solid #BEBEBE;
    background-color: #fff;
    padding: 0;
    margin-top: 2px;
}

@media (min-width: $breakpoint-tablet) {
    div.mobile {
        display: none;
    }
}

@media (max-width: $breakpoint-tablet) {
	div.mobile {
        display: block;
    }

    div#mobilemenu {
        display: none;
    }
}

div#mobilemenu {
    div.search-div > form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        font-size: 1.8rem;
        padding: 20px;

        input {
            flex-grow: 2;

            height: 40px;
            padding: 0 10px;
        }

        button {
            display: inline-block;
            flex-grow: 1;

            height: 40px;
            padding: 10px 25px;
            background-color: $color-orange-dark;
            color: $color-link-light;
        }
    }

    a {
        display: block;
        width: 100%;
        padding: 10px;
        padding-left: 30px;
        background-color:#F4F4F4;
        margin-bottom: 3px;
        font-size: 18px;
        cursor: pointer;
    }
}
