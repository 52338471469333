@media (max-width:767px) {
  .agenda {
    margin-left: 15px;
  }
}
.agenda-header {
  font-size: 52px;
}
.agenda-list {
  list-style-type: none;
  padding-left: 0;
}
.agenda-list-item {
  font-size: 26px;

  & .material-icons {
    vertical-align: middle;
  }
}
.agenda-link {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 500;
}
