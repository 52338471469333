// -- Fonts
// Font variables are prefixed with `font-` followed by e.g. the name of the font.
// This is done to make it simpler for new developers to remember the font variable name.

$font-family-title: 'Roboto', sans-serif;
$font-weight-title-normal: 300;
$font-weight-title-bold: 500;

$font-family-running-text: 'Roboto', sans-serif;

$font-family-font-awesome: 'Font Awesome 5 Pro';

$font-weight-link: 400;
$font-weight-link-title: 350;

// Font sizes uses a trick to set base font size relative to browser settings.
// The standard size for most browsers are 16px root font size.
$root-font-size: 62.5%; /* (62.5/100) * 16px = 10px */

// Using this method we can refer to the sizes in pixels, and still preserve
// the native/user defined font size.
$font-size-h1: 4.5rem; /* 45px */
$font-size-h2: 3.0rem; /* 30px */
$font-size-h3: 2.2rem; /* 22px */
$font-size-h4: 2.0rem; /* 20px */
$font-size-h5: 1.6rem; /* 16px */
$font-size-h6: 1.4rem; /* 14px */

$font-size-h2-infobox: 2.2rem; /* 22px */

$font-size-text: 2.0rem;
$font-size-body-text: 1.6rem;

$font-size-table: 1.6rem; /* 16px */

// -- Colors
// Color variables are prefixed with `color-` followed by e.g. the name of the color.
// You may also name the color by its usage e.g. `$color-table-separator`.
// A good practice is to define some base colors, and then define the use case
// specific variables by those, e.g. `$color-table-separator: $color-whitesmoke`.
// The base colors defined is named according to color names supported by modern browsers.
// @see https://www.w3schools.com/colors/colors_names.asp
//
// To get proper base names for colors you can use the tool Name that Color,
// which will tell you the proper name of your color.
// @see http://chir.ag/projects/name-that-color

$color-logo-blue: #203b70;
$color-primary: $color-logo-blue;

$color-blue-gray: #334759;
$color-header: $color-blue-gray;
$color-header-menu: $color-blue-gray;
$color-campaign-box: $color-blue-gray;

$color-blue: #286da8;
$color-infobox: $color-blue;

$color-blue-light: #448ccb;
$color-hover: $color-blue-light;

$color-blue-light-saturated: #2a85bb;
// $color-link: $color-blue-light-saturated;

$color-blue-dark: #1f3b72;

$color-orange-dark: #e67036;
$color-call-to-action: $color-orange-dark;

$color-orange-lighter: #e0bead;
$color-call-to-action-disabled: $color-orange-lighter;

$color-orange-light: #f39207;
$color-active: $color-orange-light;

$color-black: #1a1a1a;
$color-text-title: $color-black;

$color-white: #ffffff;
$color-text-light: $color-white;
$color-link-light: $color-white;
$color-text-title-light: $color-white;
$color-button-text-light: $color-white;

$color-black-light: #333333;
$color-text: $color-black-light;

$color-gray-light: #f5f5f5;
$color-background: $color-gray-light;

/* Colors from the previous design manual */
$color-blue-saturated: rgb(0, 77, 147);
// $color-link: $color-blue-saturated;

$color-blue-white: rgb(129, 155, 176);
// $color-blue-white2: rgb(61, 102, 142);
$color-orange: rgb(227, 90, 16);
$color-purple: rgb(112, 31, 85);
$color-red: rgb(192, 13, 13);
$color-yellow: rgb(253, 196, 31);
$color-green: rgb(110, 171, 78);
$color-gray-brown: rgb(154, 143, 126);

// $color-link: $color-blue;
$color-link: $color-blue-dark;
// -- Media breakpoints
// $breakpoint-smaller: 640px;
// $breakpoint-small: 767px;
// $breakpoint-medium: 1024px;
// $breakpoint-large: 1200px;

$color-button-bg: $color-blue;
$color-button-fg: $color-white;


// Bootstrap breakpoints
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;

$breakpoint-mobile: $breakpoint-small;
$breakpoint-tablet: $breakpoint-medium;
$breakpoint-desktop: $breakpoint-large;

// -- Z-indexes
$z-index-header: 1000;

// Spacing
$spacer: 2rem; // See reset-bootstrap.scss
