ul.tree-menu {
    padding: 15px 20px;
    padding-right: 10px;

    list-style-type: none;
    margin-bottom: 0;
    // padding: 0;

    // padding-right: 30px;

    & > li > a,
    & > li > span {
        // Uppermost items always semibold
        font-weight: 400 !important;

        // Uppermost items never indented
        margin-left: 0 !important;

        font-family: 'Oswald', sans-serif;
        font-size: 20px !important;
    }

    li {
        & > a.expand-button {
            position: relative;
            text-decoration: none;

            &::before {
                font-family: 'Oswald', sans-serif;

                font-family: $font-family-font-awesome;
                position: absolute;
                width: 10px;
                text-align: center;
                transition: all ease .25s;
                content: "\f105";

                // content: "\f067";
                // content: "\f055";
            }

            &:not(.collapsed)::before {
                transform: rotate(90deg);

                // content: "\f068";
                // content: "\f056";
            }
        }

        & > a:not(.expand-button),
        & > span {
            color: #333;
            font-family: 'Oswald', sans-serif;
            font-size: 1.8rem;
            font-weight: 300;

            margin-left: 20px;
            display: block;
            // width: 100%;
            padding-right: 20px;
            position: relative;
        }

        & > a:not(.expand-button) {
            &::after {
                content: "\f061";
                font-family: $font-family-font-awesome;
                position: absolute;
                right: 0;
                color: transparent;
                padding-left: 5px;
                font-size: 16px;
                font-weight: 500;
                transition: all ease .15s;
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &.active::after {
                color: $color-blue-dark;
                // color: #fff;
            }

            &:hover::after {
                color: $color-blue-dark;
                // color: #fff;
                padding-left: 10px;
            }

            @media (max-width: 992px) {
                &::after {
                    color: transparent !important;
                }
            }
        }

        & > a.parent,
        & > span.parent {
            font-weight: 500;
        }

        & > a.active,
        & > span.active {
            // font-weight: 600 !important;
            font-weight: 500 !important;
            color: #f39200;
        }

        & > ul {
            list-style-type: none;
            padding-left: 20px;
        }
    }

    &.blue {
        background-color: #286da8;

        li {
            & > a.expand-button {

                &::before {
                    color: #fff;
                }
            }

            & > a:not(.expand-button),
            & > span {
                color: #fff;
            }

            & > a:not(.expand-button) {
                &.active::after {
                    color: #fff;
                }

                &:hover::after {
                    color: #fff;
                }
            }
        }
    }
}