.customer_page {
	&__article {
		padding: 12px 0;

		.customer_page__title {
			font-size: 38px;
			a {
				font-weight: 700;
				color: #800080;
			}
		}

		&--image {
			width: 100%;
			padding-bottom: 50%;
			background-size: cover;
			background-position: center;
		}
	}
}