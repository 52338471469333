// Scoped all previous styles to this class, to simplify the redesign process.
html:not(.redesign) {
    // This is the main SASS file of the Lastebil styles.
    //
    // The file uses the 7-1 pattern, and is a very good way
    // to write your SASS code, and is encouraged by
    // the Sass Guidelines Project.
    //
    // You can copy the structure of the `lastebil/` directory if
    // you want to create a new part of the system that requires
    // a lot of SASS code. Then you can copy this file and swap
    // the imports for your new directory, and a new CSS file
    // will be generated with your chosen name e.g. `minside.min.css`
    //
    // @see https://sass-guidelin.es/#the-7-1-pattern
    @import 'lastebil/main';

    // This is the legacy Lastebil CSS without any optimization.
    // Whenever you do work on parts of the project where the
    // styles are found in the legacy code, you should remove it
    // from the legacy file and imlement it in SASS.
    // When everything is optimized the _legacy.scss file may
    // be removed and all new code is implemented the SASS way.
    @import 'lastebil/legacy';
}

// When redesign is used on the entire site, perform these steps to follow
// the SASS 7-1 pattern.
// 1. Remove the class and rule
// 2. Rename the file to main.scss
// 3. Move contents of `lastebil` one level up
//
// Most code here is copied from previous main.scss, and some of it is commented
// for clean sheets during the redesign process.
html.redesign {
    // This is the main SASS file of the Lastebil styles.
    //
    // The file uses the 7-1 pattern, and is a very good way
    // to write your SASS code, and is encouraged by
    // the Sass Guidelines Project.
    //
    // You can copy the structure of the `lastebil/` directory if
    // you want to create a new part of the system that requires
    // a lot of SASS code. Then you can copy this file and swap
    // the imports for your new directory, and a new CSS file
    // will be generated with your chosen name e.g. `minside.min.css`
    //
    // @see https://sass-guidelin.es/#the-7-1-pattern

    // -- Abstracts
    // The abstracts/ folder gathers all Sass tools and helpers used across the project.
    // Every global variable, function, mixin and placeholder should be put in here.
    // The rule of thumb for this folder is that it should not output a single line
    // of CSS when compiled on its own. These are nothing but Sass helpers.
    @import 'lastebil_redesign/abstracts/variables';
    @import 'lastebil/abstracts/functions';
    @import 'lastebil/abstracts/mixins';
    @import 'lastebil/abstracts/placeholders';

    // -- Vendors
    // The vendors/ folder is used a litte bit differently in this project than
    // what the Sass Guidelines Project suggests. Instead of holding the vendors
    // files themselves, this folder contains vendor variable overrides.
    // In other words, if you use a SASS vendor library that contains overrideable
    // variables, do those here.
    //@import 'vendors/bootstrap';

    // -- Base
    // The base/ folder holds what we might call the boilerplate code for the project.
    // Here you can place the reset file, some typographic rules, and define some
    // standard styles for commonly used elements.
    // NB: These make up `core.css` in traditional eZ
    @import 'lastebil_redesign/base/reset';
    @import 'lastebil_redesign/base/reset-bootstrap';
    @import 'lastebil_redesign/base/ez-base';
    @import 'lastebil_redesign/base/typography';

    // -- Layout
    // The layout/ folder contains everything that takes part in laying out the
    // site or application. This folder could have stylesheets for the main
    // parts of the site (header, footer, navigation, sidebar…), the grid system
    // or even CSS styles for all the forms.
    // NB: These makes up `pagelayout.css` in traditional eZ.
    // @import 'lastebil/layout/forms';
    // @import 'lastebil/layout/grid';
    @import 'lastebil_redesign/layout/header';
    @import 'lastebil_redesign/layout/navbar';
    @import 'lastebil_redesign/layout/mobile-menu';
    @import 'lastebil_redesign/layout/sidebar-left';
    @import 'lastebil_redesign/layout/footer';
    // @import 'lastebil/layout/navigation';
    // @import 'lastebil/layout/menu';
    // @import 'lastebil/layout/table';

    // -- Components
    // For smaller components, there is the components/ folder.
    // While layout/ is macro (defining the global wireframe), components/ is
    // more focused on widgets. It contains all kind of specific modules like a
    // slider, a loader, a widget, and basically anything along those lines.
    // There are usually a lot of files in components/ since the whole site
    // should be mostly composed of tiny modules.
    // NB: Partly makes up `content.css` in traditional eZ.
    @import 'lastebil_redesign/components/tree-menu';
    @import 'lastebil_redesign/components/nlf-link';
    @import 'lastebil_redesign/components/nlf-button';
    @import 'lastebil_redesign/components/button-rounded';
    @import 'lastebil_redesign/components/form';
    @import 'lastebil_redesign/components/table';
    @import 'lastebil_redesign/components/campaign-box';
    @import 'lastebil_redesign/components/campaign-page';
    @import 'lastebil_redesign/components/chosen-select';
    @import 'lastebil_redesign/components/link-list';
    @import 'lastebil_redesign/components/ez-website-toolbar';
    @import 'lastebil_redesign/components/table/program';
    @import 'lastebil_redesign/components/article/embeded';
    @import 'lastebil_redesign/components/overlayed-image';
    @import 'lastebil_redesign/components/overlayed-info';
    @import 'lastebil_redesign/components/nlf-employee';
    @import 'lastebil_redesign/components/nlf_ad';
    @import 'lastebil_redesign/components/block-link';
    @import 'lastebil_redesign/components/img-crop';
    @import 'lastebil_redesign/components/text-ellipsis';
    @import 'lastebil_redesign/components/filter-icon';

    // -- Pages
    // If you have page-specific styles, it is better to put them in a pages/
    // folder, in a file named after the page.
    // For instance, it’s not uncommon to have very specific styles for
    // the home page hence the need for a _home.scss file in pages/.
    // Note: eZ being a CMS, prioritize putting most of these styles in components.
    // Full-views are good candidates for pages, as these may override component
    // styles depending on context.
    // NB: Partly makes up `content.css` in traditional eZ.

    // @import 'pages/frontpage';
    // @import 'pages/customer';
    // @import 'pages/customer_page';
    // @import 'pages/blog';
    // @import 'pages/election';
    // @import 'pages/intranet';
    // @import 'pages/subcompany_form';
    // @import 'pages/nlf_card';
    // @import 'pages/member_email';

    // @import 'pages/news_folder';
    // @import 'pages/livestream-full';
    // @import 'pages/customer-certifications';
    // @import 'pages/statistics';
    // @import 'pages/caredit';

    // -- Themes
    // On large sites and applications, it is not unusual to have different themes.
    // There are certainly different ways of dealing with themes but I personally
    // like having them all in a themes/ folder.
    // @import 'themes/lastebil';
    @import 'lastebil_redesign/themes/print';
}
