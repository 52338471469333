@mixin customer-page {
	&__wrapper {
		position: relative;
		background-color: #ffffff;
		border-radius: 10px;
		min-height: 600px;
		box-shadow: 0px 0px 8px 1px rgba(153,153,153,0.25);

	}

	&__image {
		position: relative;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		min-height: 400px;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% auto;

		&--placeholder {
			background: rgba(31,58,86,1);
			background-image:
			linear-gradient(
				45deg, 
				rgba(31,58,86,1) 0%, 
				rgba(68,92,116,1) 50%, 
				rgba(31,58,86,1) 50%, 
				rgba(68,92,116,1) 100%
			);
		}

		&--shadow, &--angle {
			overflow: hidden;
			z-index: 0;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0px;
				width: 100%;
				height: 0px;
				display: block;
				background: transparent;
			}
		}

		&--angle {
			&:after {
				background: #ffffff;
				transform: skewY(-5deg);
				transform-origin: 100%;
				z-index: 1;
				height: 80px;
			}
		}

		&--shadow {
			&:after {
				box-shadow: 0px -20px 120px 60px rgba(0,0,0,0.5);
			}
		}
	}

	&__caption {
		font-size: 14px;
		margin-top: 10px;

		.material-icons {
			font-size: 20px;
			vertical-align: text-top;
		}
	}

	&__title {
		font-family: $f-running-text;
		margin-top: 30px !important;
		padding-bottom: 0px !important;
		margin-bottom: 20px;

		&--separator {
			border-bottom: 1px solid #dddddd;
			padding-bottom: 30px !important;
			margin-bottom: 30px;
		}

		&--link {
			display: block;
			color: #0089BC;

			> a {
				font-family: $f-running-text;
				display: block;
				color: #0089BC;
			}
		}

		&--no-link {
			color: #333333 !important;

		}

		&--small {
			font-weight: 600;
			margin-top: 12px !important;
			margin-bottom: 12px !important;
		}

		&--less-margin {
			margin-bottom: 10px;
		}
	}

	&__published {
		margin-bottom: 12px;

		.time {
			font-family: $f-running-text;
			font-size: 16px;
			font-weight: 600;
		}

		.material-icons {
			font-size: 20px;
			vertical-align: text-top;
		}
	}

	&__download {
		margin-bottom: 20px;

		.file {
			font-size: 16px;
		}

		.material-icons {
			font-size: 20px;
			vertical-align: text-top;
		}
	}

	&__intro {
		font-family: $f-running-text;
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	&__body {
		font-family: $f-running-text;
		font-size: 18px;
	}

	&__content {
		display: block;
		padding: 0 30px 40px;
		margin: 0 0 30px;
		font-size: 18px;
		
		&__text-box {
			padding: 40px 40px 40px 0;
		}
	}

	&__articles {
		margin-top: 20px;

	}
	
	&__article {

		&--image {
			float: left;
		    display: block;

		    img {
		    	width: 85%;
		    }
		}
		&--container {
			display:block;
		}

	}

}