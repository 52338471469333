// Switch-style checkbox
// input[type=checkbox][id]

.checkbox {
    display: none;
    $switch-border-radius: 20px;

    & + label.switchbox {
        background-color: #e0e0e0;
        transition: all .25s ease;
        border: 1px solid #ccc;
        border-radius: $switch-border-radius;
        cursor: pointer;
        display: inline-block;
        height: 30px;
        overflow: hidden;
        position: relative;
        width: 60px;
        &::after {
            content: '';
            background-color: #fff;
            border-radius: $switch-border-radius;
            display: block;
            height: 28px;
            position: absolute;
            right: 50%;
            transition: all .25s ease;
            width: 50%;
        }
    }

    &:checked {
        & + .switchbox {
            background-color: #2b85ba;
            &::after {
                right: 0%;
            }
        }

        &:disabled + .switchbox {
            background-color: #e0e0e0;
        }
    }

    &:disabled + .switchbox:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.15);
        border-radius: $switch-border-radius;
        z-index: 1;
        cursor: not-allowed;
    }
}