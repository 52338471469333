.checklist {
    padding-top: 2rem;

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: #333 !important;
        font-weight: 400;
    }

    h3 {
        font-size: 24px !important;
    }

    #create-checklist-form {
        .row label {
            text-indent: 0;
        }

        .button-list {
            display: flex;

            .filler {
                flex-grow: 1;
            }
        }
    }

    .checklist-template-item-image {
        max-width: 300px;
    }

    .checklist-template-item,
    .card {
        margin: 2rem 0;
        padding: 2rem;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0 !important;
        }

        &:nth-of-type(odd) {
            background: #e2ebf8;
        }

        &:nth-of-type(even) {
            background: #fff;
        }

        // Nested cards are always the same background
        .card {
            background: #fff !important;
        }

        &.card-blue {
            background: #e2ebf8 !important;
        }
    }

    .checklist-template-container {
        .card {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: space-between;

            .text-column {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                flex: 1;
            }
        }
    }

    .checklist-processing {
        background: #e2ebf8;
    }

    .image {
        max-width: 100%;
    }

    .data-table {
        tbody {
            tr[data-url] {
                &:hover {
                    background: #e2ebf8;
                    cursor: pointer;
                }
            }
        }

        .function-buttons {
            a {
                margin: 1rem;
                font-size: 2rem;
            }
        }
    }

    input, textarea {
        min-width: auto !important;
    }

    .image-icon {
        float: right;
        font-size: 3.5rem;
    }
}
