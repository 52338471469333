.button-collapse-row {
    display: block;
    height: 27px;
    text-decoration: none;
    color: #333;
    text-align: center;

    &::before {
        content: '\f102'; // fa-angle-double-down
        font-family: $font-family-font-awesome;
    }

    &.collapsed::before {
        content: '\f103'; // fa-angle-double-up
    }
}