.form {
	&--transport {

	}

	&__block {

		&--button &__info {
			display: inline-block;
		    font-size: 17px;
		    line-height: 23px;
		    width: 70%;
		}
	}
}