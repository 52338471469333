div.content-view-block.employee {
    div.attribute-image {
        padding-top: 66.66%; // 3:2-format
        overflow: hidden;
        position: relative;
        // height: 100%; // portrait
        // width: 100%; // landscape

        width: 85px;
        height: 112px;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
        }
    }

    div.attribute-image img {
        border: 1px solid #707070;
    }

    div.attribute-name h3 {
        margin-top: 5px;
        font-weight: 400;
    }

    div[class^='attribute-'] {
        & > h3,
        & > p {
            font-size: 1.4rem !important;
            margin-bottom: 10px;
        }

        &:last-child p {
            margin-bottom: 5px;
        }
    }
}

div.content-view-block.employee + div.content-view-block.employee {
    margin-top: 1.5rem;
}

div.content-view-line.employee {
    background: #fff;

    .attribute-title h3 {
        font-weight: 400;
        font-size: 16px;
    }

    .attribute-verv {
        color: #1F3B72;
        font-weight: 300;
    }

    .attribute-phone a, .attribute-email a {
        text-decoration: underline;
        color: #000;
        font-weight: 300;
    }
}
