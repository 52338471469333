@media print {
    @page {
        size: A4;
        margin: 0mm;
    }

    html, body {
        width: 1024px;
        margin: 46mm;
    }

    body {
        padding-top: 0;
        margin: 0 auto;
        // zoom: 75%;
    }

    a[href]:after {
        content: none !important;
    }

    .jumbotron {
        padding: 0;
        margin: 0;
    }

    footer {
        margin-top: 50px;
        border-top: 1px solid #ddd;
        // position: fixed;
        // bottom: 0;
    }

    ul.breadcrumb {
        margin-bottom: 15px;
    }

    div#debug, div#ezwt {
        display: none;
    }

    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-11 {
        width: 91.66666667%;
    }
    .col-md-10 {
        width: 83.33333333%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-8 {
        width: 66.66666667%;
    }
    .col-md-7 {
        width: 58.33333333%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-5 {
        width: 41.66666667%;
    }
    .col-md-4 {
        width: 33.33333333%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-2 {
        width: 16.66666667%;
    }
    .col-md-1 {
        width: 8.33333333%;
    }

    // .content-view-full {
    //     width: 100% !important;
    // }
}