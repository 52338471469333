a.nlf-link {
    &::after {
        content: "\f061";
        font-family: $font-family-font-awesome;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 300;
        transition: all ease .15s;
        display:inline-block; // don't underline
    }

    &:hover::after {
        padding-left: 15px;
    }
}

h4 > a.nlf-link::after {
    padding-left: 0;
}

h4 > a.nlf-link:hover::after {
    padding-left: 5px;
}
