.campaign-page {
    $font-lato: lato, sans-serif;
    $font-helvetica: Helvetica, sans-serif;
    font-family: $font-lato;
    background: url("../images/campaign_bg_2.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    max-width: 100%;

    .campaign-content-shared {
        flex: 0 0 50%;
    }

    .campaign-logo {
        width: 100%;
        max-width: 250px;
    }

    .logoer {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .logoer .logo {
        padding-top: 40px;
    }

    .logoer .logo img {
        max-width: 80%;
        width: 130px;
    }
    .logoer .logo.logo--statensvegvesen {
        padding-top: 20px;
    }

    .logoer .logo.logo--nlf img {
        max-width: 60%;
    }

    span.underline {
        text-decoration: underline;
    }

    h1 {
        font-weight: 700;
        font-style: italic;
        font-size: 77pt;
    }

    h2 {
        font-weight: 700;
        font-style: italic;
        font-size: 36pt;
        color: #40658e;
    }

    .campaign-lead {
        font-weight: 900;
        font-style: italic;
        font-size: 21pt;
    }

    .campaign-content {
        max-width: 100%;
        @media (min-width: 992px) {
            max-width: 33%;
        }

        background: #ffffffe6;
        border-left: 15px solid white;
        border-right: 15px solid white;
        background: #ffffffe6;
        padding-top: 3rem;
        overflow-wrap: anywhere;

        p {
            font-family: $font-lato;
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
        }

        .call-to-action {
            font-weight: 900;
            color: #c90c0f;
            text-decoration: underline;
        }

        .campaign-button {
            margin: 0;
            background-color: #fcb814;
            color: black;
            font-family: $font-helvetica;
            font-weight: 900;
            font-size: 25pt;
            line-height: 26px;
            padding: 1rem 1rem;
            border-radius: 1rem;
            width: 100%;
            border: 1px solid black;

            &:disabled {
                background-color: #d1d1d1;
                color: white;
                cursor: not-allowed;
            }

            span {
                width: 100%;

                &.lead {
                    font-weight: 900;
                    font-size: 1.5rem;
                }
            }
        }
    }

    .single__share {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-top: 15px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        &--icon {
            display: inline-block;
            width: 50px;
            height: 50px;
            margin-right: 5px;
            margin-left: 5px;
            border: 1px solid #ddd;
            border-radius: 200px;
            -webkit-transition: all 200ms ease;
            transition: all 200ms ease;
            font-family: "Material design iconic font", sans-serif;
            color: #333;
            font-size: 20px;
            line-height: 50px;
            text-decoration: none;
        }
        &--facebook {
            border-color: #3b5998;
            background-color: #3b5998;
            color: #fff;
        }
        &--google {
            border-color: #dd4b39;
            background-color: #dd4b39;
            color: #fff;
        }
        &--twitter {
            border-color: #55acee;
            background-color: #55acee;
            color: #fff;
        }
        &--linkedin {
            border-color: #007bb5;
            background-color: #007bb5;
            color: #fff;
        }
        &--mail {
            border-color: #333;
            background-color: #333;
            color: #fff;
        }
    }
}

@media (max-width: 480px) {
    .campaign-page h1 {
        font-size: 37px !important;
    }
}