:root {
	font-size: $root-font-size;

	/* https://css-tricks.com/accessible-font-sizing-explained/#method-1-the-62-5-rule */
}

body, html {
	margin: 0px;
	padding: 0px;
	font-family: $font-family-running-text;
	font-weight: $font-weight-title-normal;
	background-color: $color-header;
	color: $color-text;
}

h1 {font-size: $font-size-h1 !important;}
h2 {font-size: $font-size-h2 !important;}
h3 {font-size: $font-size-h3 !important;}
h4 {font-size: $font-size-h4 !important;}
h5 {font-size: $font-size-h5 !important;}
h6 {font-size: $font-size-h6 !important;}
// Using important to override bootstrap styles

h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	font-family: $font-family-title;
	font-weight: $font-weight-title-normal;
	color: $color-text-title;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	font-weight: $font-weight-link-title;
	color: $color-link;
}

// h1, h2, h3 {
// 	margin-top: 20px;
// 	margin-bottom: 10px;
// }

ul li {
	line-height: 1.4em;
}

a {
	color: $color-link;
	font-weight: $font-weight-link;

	&:hover {
		color: $color-hover;
	}

	&:active {
		color: $color-active;
	}
}

table {
	width: 100%;

	th {
		// font-family: 'Open Sans';
		// color: #000;
		font-size: 23px;
		text-align: left;
	}

	tr {
		td {
			// font-family: 'Open Sans';
			// color: #000000;
			font-size: 20px;
			vertical-align: top;

			&:first-child {
				padding-left: 20px;
			}
		}

		&::nth-child(even) {
			background: #e2ebf8;
		}
	}
}

button {
	border: none;
	border-radius: 0;
}

input[type="text"] {
	border: none;
	border-radius: 0;
}

// ::placeholder {
// 	color: $color-blue-gray;
// 	opacity: 1;
// }

div#inner-wrap {
	background-color: $color-background;
}

// Imported from _legacy.scss
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-nav > li > a {font-size: 1.7rem;}
	ul.dropdown-menu li a {font-size: 1.8rem;}
}
@media (min-width: 768px) and (max-width: 991px) {
	.navbar-nav > li > a {font-size: 1.6rem; padding: 15px 5px;}
	ul.dropdown-menu li a {font-size: 1.7rem;}
	// #userMenuDesktop { width: 50px; }
	// .searchMenuLink { width: 40px; }
}

@media (min-width: $breakpoint-large) {
	/* Replace this with col-md-4 when redesign is used on the entire site */
	body > div#outer-wrap > div#inner-wrap > div.jumbotron.jumbotron-content > div.container > div.row > div.col-md-3 {
		width: 33.33333333333333%;
	}
	/* Replace this with col-md-8 when redesign is used on the entire site */
	body > div#outer-wrap > div#inner-wrap > div.jumbotron.jumbotron-content > div.container > div.row > div.col-md-9 {
		width: 66.66666666666666%;
	}
}

.hide-in-redesign {
	display: none !important;
}

.no-print {
	@media print {
		display: none;
	}
}

.only-print {
	display: none;

	@media print {
		display: initial;
	}
}

.show-in-print {
	@media print {
		display: initial;
	}
}

.print-avoid-page-break {
	page-break-inside: avoid;
}

.print-w100 {
	@media print {
		width: 100% !important;
	}
}

// Fixes header using too much space on small screen sizes
@media (max-width: 767px) {
    .hackedHeader {
        height: 17px !important;
    }
}

body > #debug {
	background-color: #fff0f5;

	table {
		background-color: #fff0f5;
	}
}