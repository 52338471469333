a.nlf-button {
    background-color: #286da8;
    color: #ffffff;
    font-size: 1.4rem;
    padding: 9px 27px 8px 15px;
    transition: all ease .15s;
    position: relative;
    display: inline-block;

    &::after {
        content: "\f105";
        font-family: $font-family-font-awesome;
        font-size: 16px;
        font-weight: 300;
        transition: all ease .15s;
        display: inline-block; // don't underline
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
    }

    &:hover {
        background-color: #4684B8;
        text-decoration: none;

        &::after {
            right: 10px;
        }
    }
}