#sidr-right {
	display: none;
}

.menu {
	font-family: $f-title;
	font-weight: $f-title-normal;

	&__item {
		font-family: inherit;
		font-size: 20px;

		&__title {
			i[class^='fa'] {
				width: 26px;
				text-align: center;
			}

			display: block;
			font-family: inherit;
			color: #f1f1f1;
			padding: 15px 20px;
			border-left: 4px solid transparent;
			background-color: #286da8;

			&:hover {
				color: #000;
			}

			&:focus {
				color: #000;
			}

			.material-icons {
				position: relative;
				top: 4px;
			}

			&--link {
				cursor: pointer;
				&::before {
					margin-left:15px;
				}
			}
		}
		&__subtitle {
			font-family: inherit;
			color: #f1f1f1;
			margin-left:35px;
			border-left: 4px solid transparent;

			.material-icons {
				position: relative;
				top: 4px;
			}
		}
		&__caret {
			color: white;
			position: relative;
			left: 30px;
			top: 1px;
			cursor: pointer;
			font-family: 'Material-Design-Iconic-Font';
			font-size: 15pt;
			display: inline-block;
			width: 6px;
			transition: transform 0.2s;

			&.active {
				transform: rotate(90deg);
			}

			&::before {
				content: '';
				font-size: 36px;
				vertical-align: middle;
			}


		}

		.menu--sub .menu__item {
			font-size: 16px;

			&__title {
				margin-left:35px;
				padding: 0px 20px;
				background-color: #2b85ba;
			}

			&--active > a {
				color: #000;
				border-color: transparent;
			}

		}

		&--active > &__title {
			border-color: #2b85ba;
		}

		&--active > .menu--sub {
			display: block;
		}

		&--folder {
			display: none;

			.menu__item__title {
				padding: 15px 20px 15px 30px;
			}
		}
	}

	&--company {
		> .menu__item {
			display: none;

			&--main {
				display: block;
			}
		}
	}

	&--expanded {
		display: block;

		.menu__item {
			display: block;
		}
	}

	&--sub {
		background-color: #2b85ba;
		margin: 3px 0;
		padding: 10px 0;
		display: none;
	}

	.menu--sub .menu__item {
		font-size: 16px;

		.kmvMenu {
			list-style: none;
			padding-left: 20px;
		}
	}

	.menu__item__caret {

		left: 23px;

		& + a {
			margin-left: 26px;
		}

		& + a + ul {
			overflow: hidden;
			transition: max-height 1s;
			max-height: 0px;
		}

		&.active + a + ul {
			max-height: 500px;

		}
	}

}

.menu__item--active>.menu__item__title {
    border-color: #2b85ba;
}