.button-rounded {
    background-color: #1F3B72;
    color: #ffffff;
    font-size: 1.4rem;
    padding: 4px 16px 2px 16px;
    transition: all ease .15s;
    position: relative;
    display: inline-block;
    border-radius: 15px;

    // &:hover {
    //     background-color: #4684B8;
    //     text-decoration: none;

    //     &::after {
    //         right: 10px;
    //     }
    // }
}