.region-wrapper {

	h2 {
		font-size: 38px;
	}

	.region-office {
		font-size: 26px;
		font-weight: 700;
		line-height: 38px;

		&:after {
		  content: "";
		  clear: both;
		  display: table;

		}

		.float-left {
			float: left;
		}

		.img-responsive {
			display: inline-block;
			padding: 10px;
			padding-left: 0px;
		}

	}

	.county-verv-table {
		.row {
			background-color: #fff;
			background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
			background-position: bottom;
			background-size: 10px 2px;
			background-repeat: repeat-x;

			div {
				padding: 15px 0px;

				.second-info {
					display: none;
					padding: 0;
				}
				
				img {
					float: left;
					padding-right: 10px;
					padding-bottom: 10px;
				}
			}

			@media (max-width: 767px) {
				.second {
					display: none;
				}
				.first {
					.second-info {
						display: block;
					}
				}
			}
		}
	}
}