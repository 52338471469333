.partners {
  align-content: space-between;
  align-items: center;
  display: flex;
  justify-content: flex-start;

  padding: 25px 0px;
}

.partners-inner {
  display: flex;
  margin: auto;
  min-height: 20px;

  img {
    align-self: center;
    max-height: 35px;
    max-width: 100%;
    width: auto;
  }

  & + .partners-inner {
    padding-left: 1%;
  }
}
