body {
	font-family: $f-running-text;
    color: #333;
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    background-color: #eee;
}
.election-page {
	.row {
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display:         flex;
	  margin-left:0;
	  margin-right:0;
	  padding-bottom:50px;
	  border-bottom:1px solid #eee;
	  @media screen and (max-width: 767px) {
	  	display:block;
	  	padding-bottom: 0;
	  	&:after {
	  		display: inline;
	  	}
	  	border-bottom:0px;
	  }
	  &:last-child {
	  	border-bottom: 0px;
	  }
	}
	.row > [class*='col-'] {
		 display: flex;
		 flex-direction: column;
		  @media screen and (max-width: 767px) {
		  	margin-bottom: 15px;
		  	padding: 0 15px;
		  	padding-bottom: 15px;
		  }
	}
	.content {
		min-height: 1000px;
		min-height: 1000px;
    	padding-bottom: 30px;
	   .container {
	   	    margin-top: 70px;
	   		margin-bottom: 101px;
	   		.question {
	   			overflow: hidden;
			    margin-bottom: 20px;
			    border: 1px none #ddd;
			    border-radius: 5px;
			    background-color: #fff;
			    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .1);

	   			&__container {
	   				padding: 40px 50px 130px 50px;
	   				border-bottom: 1px solid #ddd;
	   			}
	   			&:first-child {
	   				margin-top:-120px;
	   			}
	   			&__number {
				    margin-right: 30px;
				    width: 50px;
				    height: 50px;
				    border: 1px none #0159a2;
				    border-radius: 200px;
				    background-color: #0159a2;
				    color: #fff;
				    line-height: 50px;
				    text-align: center;
	   			}
	   			@media screen and (max-width: 767px) { 
	   				.spacer {
	   					display:none;
	   				}
	   			}

	   			&__answers {
	   				display:none;
	   				margin:0;
	   				margin-bottom:50px;
					position:relative;
					overflow:scroll;
	   				@media screen and (max-width: 767px) {
		   				&--inner {
		   					overflow:scroll;
		   				}
		   				&--scroll-row {
		   					display: block;


	    					
	    				}	     				
	    				.arrow {
						    position: absolute;
						    top: 0px;
						    right: 0px;
						    display: -webkit-box;
						    display: -webkit-flex;
						    display: -ms-flexbox;
						    display: flex;
						    height: 100%;
						    padding-right: 14px;
						    padding-left: 58px;
						    -webkit-box-align: center;
						    -webkit-align-items: center;
						    -ms-flex-align: center;
						    align-items: center;
						    background-image: -webkit-linear-gradient(0deg, transparent, #fff);
						    background-image: -moz-linear-gradient(0deg, transparent, #fff);
						    background-image: -o-linear-gradient(90deg, transparent, #fff);
						    background-image: linear-gradient(90deg, transparent, #fff);
						    font-family: 'Material design iconic font', sans-serif;
						    color: transparent;
						    }
	    			}
	    			@media screen and (min-width: 768px) {
		    			&--scroll-row {
		    			/*	width:auto !important; */
		    			}
		    		}

	   				.spacer {
	   					width:100%;
	   					padding-bottom: 20px;
	   					border-bottom:1px solid #eee;
	   					margin: 0 ;
	   					padding-left:0;
	   					padding-right:0;
	   					&:last-child {
	   						border: 0;
	   					}
	   				}	
	   			}
	   		}

	   		.answer {
	   			@media screen and (max-width: 767px) {
	   				width: 200px;
	   				float:left;
	   				padding-left:15px;
	   				padding-right:15px;
	   				border-right: 1px solid #e4e4e4;

	
	   			}
	   			


	   			min-height:300px;
	   			margin-bottom:0px;
	   			margin-top:30px;
	   			&__logo {
	   				img {
	   				margin: 0 auto;
	   				display:block;
	   				}
	   			}

	   			&__short {
	   				padding-top:20px;
	   				font-size:14px;
	   			}

   				&__read--more {
				    margin-top:25px;
				    left: 0px;
				    right: 0px;
				    bottom: 0px;
				    display: block;
				    margin-right: auto;
				    margin-left: auto;
				    font-size: 14px;
				    text-align: center;
				    text-decoration: none;
				    text-transform: uppercase;
				    span {
				    	display: inline-block;
					    width: 30px;
					    height: 30px;
					    margin-right: 10px;
					    border: 1px solid #ccc;
					    border-radius: 200px;
					    text-align: center;
					    font-size:14px;
					    padding-top: 7px;
				    }
				}
	   			&:nth-child(2n+2) {
	   		
	   				border-left: 1px solid #eee;
	   				border-right: 1px solid #eee;
	   			}
	   			&__short {
	   				text-align:center;
	   			}
	   			&__button {	
	   				cursor: pointer;
				    width: 100%;
				    padding-top: 10px;
				    padding-bottom: 10px;
				    border-bottom: 2px solid transparent;
				    background-color: #fafafa;
				    color: #0159a2;
				    font-size: 15px;
				    text-align: center;
				    letter-spacing: 1px;
				    text-transform: uppercase;
	   			}
	   		}
	   }
	}
   .cover {
  	  color: white;
	  &__image {
	  	width: 100%;
	  	min-height: 350px;
	  	background-size: cover;
	  	background-repeat: no-repeat;
	  	padding-top:120px;
	  	@media screen and (max-width: 767px) {
	  		background-position:50%;
	  	}
	  	padding-bottom: 160px;
	  }
	  &__top {
 
	    color: #fff;
	    font-size: 35px;
	    line-height: 55px;
	    font-weight: 300;
	    text-transform: uppercase;
	  }
	  &__title {
		letter-spacing: 1px;
		text-transform: uppercase;
		font-size: 56px;
		line-height: 72px;
		margin-bottom: 10px;
	  }
	  &__ingress {
	  	font-size: 18px;
	  	line-height: 32px;
	  }
	  &__divider {
	    display: block;
	    width: 40px;
	    height: 2px;
	    margin: 26px auto;
	    background-color: #fff;
	  }
  }
}
.modal-backdrop
{
    opacity:0.5 !important;
}
