// The abstracts/ folder gathers all Sass tools and helpers used across the project.
// Every global variable, function, mixin and placeholder should be put in here.
// The rule of thumb for this folder is that it should not output a single line
// of CSS when compiled on its own. These are nothing but Sass helpers.
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/placeholders';

// The vendors/ folder is used a litte bit differently in this project than
// what the Sass Guidelines Project suggests. Instead of holding the vendors
// files themselves, this folder contains vendor variable overrides.
// In other words, if you use a SASS vendor library that contains overrideable
// variables, do those here.
//@import 'vendors/bootstrap';

// The base/ folder holds what we might call the boilerplate code for the project.
// Here you can place the reset file, some typographic rules, and define some
// standard styles for commonly used elements.
@import 'base/reset';
@import 'base/typography';

// For smaller components, there is the components/ folder.
// While layout/ is macro (defining the global wireframe), components/ is more focused on widgets.
// It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines.
// There are usually a lot of files in components/ since the whole site should be mostly composed of tiny modules.
@import 'components/campaign-box';
@import 'components/article-list';
@import 'components/kmv-edit';
@import 'components/partners';
@import 'components/nlf-article';
@import 'components/livestream-box';
@import 'components/agenda';
@import 'components/breadcrumb';
@import 'components/calendar-line';
@import 'components/tree-menu';
@import 'components/switchbox';
@import 'components/button-collapse-row';

// The layout/ folder contains everything that takes part in laying out the site or application.
// This folder could have stylesheets for the main parts of the site (header, footer, navigation, sidebar…),
// the grid system or even CSS styles for all the forms.
@import 'layout/footer';
@import 'layout/forms';
@import 'layout/grid';
@import 'layout/header';
@import 'layout/navigation';
@import 'layout/sidebar';
@import 'layout/menu';
@import 'layout/table';

// If you have page-specific styles, it is better to put them in
// a pages/ folder, in a file named after the page.
// For instance, it’s not uncommon to have very specific styles for
// the home page hence the need for a _home.scss file in pages/.
@import 'pages/frontpage';
@import 'pages/customer';
@import 'pages/customer_page';
@import 'pages/blog';
@import 'pages/election';
@import 'pages/intranet';
@import 'pages/subcompany_form';
@import 'pages/nlf_card';
@import 'pages/member_email';
@import 'pages/region';
@import 'pages/news_folder';
@import 'pages/livestream-full';
@import 'pages/customer-certifications';
@import 'pages/statistics';
@import 'pages/caredit';
@import 'pages/checklist';
@import 'pages/modulesettings';
@import 'pages/employee_edit';

// On large sites and applications, it is not unusual to have different themes.
// There are certainly different ways of dealing with themes but I personally
// like having them all in a themes/ folder.
@import 'themes/lastebil';
@import 'themes/customer';
